import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate, Routes, Route, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import ProtectedRoute from '@routing/ProtectedRoute'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import CreateAccountNameAddress from './createAccountNameAddress/CreateAccountNameAddress'
import CreateAccountReviewConfirm from './createAccountReviewConfirm/CreateAccountReviewConfirm'
import CreateAccountFinalDetails from './createAccountFinalDetails/CreateAccountFinalDetails'
import CreateAccountInReview from './createAccountInReview/CreateAccountInReview'
import CreateAccountProcessing from './CreateAccountProcessing'
import CreateAccountWrapper from '@common/components/createAccountWrapper/CreateAccountWrapper'

import { gql, useQuery } from '@services/serviceUtils'
import { staticRoutes } from '@routing/routes'
import { setOnboardingFlowTypeAction } from '@redux/application/applicationActions'
import { useApplication, getNextIncompleteCheckpointRoute } from '@common/utils'
import useScript from '@shared/utils/useScript'
import { APPLICATION_STATUSES } from '@shared/constants'

import styling from './createAccount.module.scss'

const accountsQuery = gql`
  query Accounts {
    accounts {
      accounts {
        accountType
      }
    }
  }
`

export const CreateAccountContext = createContext(null)

/**
 * Authenticated sign-up workflow
 */
const CreateAccount = () => {
  const [scriptLoaded, scriptError] = useScript('https://js.dvnfo.com/devicer.min.js')

  const [paragraphText, setParagraphText] = useState('')
  const dispatch = useDispatch()

  const { state, pathname } = useLocation()

  const { getApplication, ...application } = useApplication()

  const { loading: accountsLoading, data: accountsData } = useQuery(accountsQuery, {
    fetchPolicy: 'no-cache',
  })

  const accountTypes = useMemo(
    () => accountsData?.accounts?.accounts?.map(account => account.accountType),
    [accountsData?.accounts]
  )

  // Fetch the application
  useEffect(() => {
    getApplication()
  }, [getApplication])

  const onboardingFlowType = useMemo(() => application?.data?.application?.onboardingFlowType, [
    application?.data?.application,
  ])

  const customerApplicationStatus = useMemo(() => (
    application?.data?.application?.customerApplicationStatus
  ), [application?.data?.application?.customerApplicationStatus])

  useEffect(() => {
    if (onboardingFlowType) {
      dispatch(setOnboardingFlowTypeAction(onboardingFlowType))
    }
  }, [onboardingFlowType, dispatch])

  const nextIncompleteCheckpointRoute = getNextIncompleteCheckpointRoute(
    application?.data?.checkpoints
  )

  const getUpdatedRoute = useCallback(() => {
    if (!state?.isEditing) {
      return <Navigate to={nextIncompleteCheckpointRoute} />
    }
  }, [state, nextIncompleteCheckpointRoute])

  const createAccountPageDetails = useMemo(() => {
    let header
    let subtext
    let className
    let greenwoodFact

    switch (pathname) {
      case staticRoutes.createAccountNameAddress.pathname:
        greenwoodFact = 'By the early 1900s, Greenwood had over 600 Black-owned businesses, including theaters, grocery stores, banks, and hotels, making it one of the most prosperous Black communities in the United States.'
        header = 'Confirm Your Address'
        subtext = 'To ensure your security, we need to verify your identity. This is also where we will ship your debit card.'
        className = styling['name-address']
        break
      case staticRoutes.createAccountFinalDetails.pathname:
        greenwoodFact = 'Greenwood was home to multiple educational institutions, including Booker T. Washington High School, which became a center of learning and pride for the community, producing graduates who went on to become influential leaders.'
        header = 'Final Details to Finish Your Account'
        subtext = 'These final details help us confirm your identity and keep your account safe.'
        className = styling['birthday-ssn']
        break
      case staticRoutes.createAccountReviewConfirm.pathname:
        greenwoodFact = "Greenwood's success was so notable that it attracted Black entrepreneurs and professionals from across the country, seeking opportunities in a community where Black businesses could flourish independently."
        header = 'Review & Confirm'
        subtext = 'Please take a moment to review your details.'
        className = styling['review-confirm']
        break
      case staticRoutes.createAccountInReview.pathname:
        greenwoodFact = 'In Greenwood, each dollar circulated within the community up to 36 times before leaving. Imagine what that could mean today for Black wealth and opportunity.'
        header = 'Welcome to Greenwood'
        subtext = "Thank you for your Greenwood Bank Account application! We're reviewing your information to get you fully set up."
        className = styling['in-review']
        break
      case staticRoutes.createAccountProcessing.pathname:
        greenwoodFact = 'In Greenwood, each dollar circulated within the community up to 36 times before leaving. Imagine what that could mean today for Black wealth and opportunity.'
        header = 'Sit tight!'
        subtext = "We're processing your application."
        className = styling['in-review']
        break
      default:
        break
    }

    setParagraphText(subtext)

    return {
      header,
      className,
      greenwoodFact,
    }
  }, [pathname])

  return (
    <CreateAccountWrapper {...createAccountPageDetails} paragraphText={paragraphText}>
      <CreateAccountContext.Provider value={{ ...application, setParagraphText }}>
        <LoadingContainer
          loading={application.loading || !scriptLoaded || accountsLoading}
          error={application.error || scriptError}
        >
          {accountTypes?.length ? (
            <Navigate to={staticRoutes.dashboard.pathname} />
          ) : (
            <>
              {nextIncompleteCheckpointRoute && customerApplicationStatus === APPLICATION_STATUSES.INITIAL ? getUpdatedRoute() : null}
              <Routes>
                <Route
                  path={staticRoutes.createAccountNameAddress.relativePathname}
                  element={<ProtectedRoute component={CreateAccountNameAddress} />}
                />
                <Route
                  path={staticRoutes.createAccountFinalDetails.relativePathname}
                  element={<ProtectedRoute component={CreateAccountFinalDetails} />}
                />
                <Route
                  path={staticRoutes.createAccountReviewConfirm.relativePathname}
                  element={<ProtectedRoute component={CreateAccountReviewConfirm} />}
                />
                <Route
                  path={staticRoutes.createAccountProcessing.relativePathname}
                  element={<ProtectedRoute component={CreateAccountProcessing} />}
                />
                <Route
                  path={staticRoutes.createAccountInReview.relativePathname}
                  element={<ProtectedRoute component={CreateAccountInReview} />}
                />
              </Routes>
            </>
          )}
        </LoadingContainer>
      </CreateAccountContext.Provider>
    </CreateAccountWrapper>
  )
}

export default CreateAccount
