import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = ({
  to,
  children,
  openInCurrentTab = false,
  className,
  clickListener,
  ...rest
}) => {
  return (
    <a
      href={to}
      rel='noreferrer noopener'
      target={openInCurrentTab ? undefined : '_blank'}
      className={className}
      {...rest}
      onClick={clickListener}
    >
      {children}
    </a>
  )
}

ExternalLink.propTypes = {
  /**
   * External link URL
   */
  to: PropTypes.string.isRequired,

  /**
   * Children to render as a link. Either children or text prop should be used to display link contents
   */
  children: PropTypes.node,

  /**
   * Additional classes to pass to link element
   */
  className: PropTypes.string,

  /**
   * Opens the link in the current tab. Default is false, so the link will open in a new tab
   */
  openInCurrentTab: PropTypes.bool,
}

export default ExternalLink
