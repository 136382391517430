import React, { createContext, useEffect } from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import ProtectedRoute from '@routing/ProtectedRoute'
import AddressResubmissionRequired from './AddressResubmissionRequired'
import AddressResubmissionEntry from './AddressResubmissionEntry'
import CreateAccountReviewConfirm from 'src/views/createAccount/createAccountReviewConfirm/CreateAccountReviewConfirm'
import CreateAccountWrapper from '@common/components/createAccountWrapper/CreateAccountWrapper'

import { useApplication } from '@common/utils'
import { staticRoutes } from '@routing/routes'

import styling from './addressResubmission.module.scss'

export const AddressResubmissionContext = createContext(null)

const AddressResubmission = () => {
  const { getApplication, loading, fetchError, ...application } = useApplication()

  // Fetch the application
  useEffect(() => {
    getApplication()
  }, [getApplication])

  return (
    <AddressResubmissionContext.Provider value={{ ...application }}>
      <Routes>
        <Route path='/*' element={<ProtectedRoute component={AddressResubmissionRequired} />} />
        <Route
          path={staticRoutes.addressResubmissionEntry.relativePathname}
          element={<ProtectedRoute component={AddressResubmissionEntry} />}
        />
        <Route
          path={staticRoutes.addressResubmissionReview.relativePathname}
          element={
            <CreateAccountWrapper
              greenwoodFact="Greenwood's success was so notable that it attracted Black entrepreneurs and professionals from across the country, seeking opportunities in a community where Black businesses could flourish independently."
              header='Review & Confirm'
              paragraphText="Please take a moment to review your details. By confirming, you're joining a community focused on financial empowerment and shared success."
              className={styling['review-confirm']}
            >
              <ProtectedRoute component={CreateAccountReviewConfirm} componentProps={{ isResubmitting: true }} />
            </CreateAccountWrapper>
          }
        />
      </Routes>
      <LoadingContainer
        loading={loading}
        error={fetchError}
        errorMessage='Error loading application data.'
      >
        <Outlet />
      </LoadingContainer>
    </AddressResubmissionContext.Provider>
  )
}

export default AddressResubmission
