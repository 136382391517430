import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import Button from '@shared/components/button/Button'
import FormCheckbox from '@shared/components/formCheckbox/FormCheckbox'
import ExternalLink from '@common/components/link/ExternalLink'

import { PRODUCT_TYPES, COASTAL_BANKING_SERVICES } from '@common/constants'
import {
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  trackEvent,
  trackPage,
} from '@common/utils'

import styling from './agreementsForm.module.scss'

const AgreementsForm = ({ updating, submitting, products, handleClick, updateApplication }) => {
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.AGREEMENTS })
    trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_CONFIRM })
  }, [])

  const { product, userAgreements, otherAgreements } = useMemo(() => {
    const available = products?.filter(
      product => ![PRODUCT_TYPES.savings, PRODUCT_TYPES.premium, PRODUCT_TYPES.elevateCard].includes(product?.productType)
    ) || []

    const userAgreements = []
    const otherAgreements = []

    available?.forEach(p => {
      p?.agreements?.forEach(agreement => {
        const title = agreement?.title?.toLowerCase()
        if (title.includes('account') || title.includes('privacy')) {
          userAgreements.push(agreement)
        } else {
          otherAgreements.push(agreement)
        }
      })
    })

    return {
      product: products?.find(_product => _product.productType === PRODUCT_TYPES.basic) || null,
      userAgreements,
      otherAgreements,
    }
  }, [products])

  const disabled = useMemo(() => updating || submitting, [updating, submitting])

  const getAgreementsList = list => {
    return list.map((item, index) => {
      const isLast = index === list.length - 1

      return (
          <span key={`${item.title}-${index}`}>
            {isLast ? ' and ' : null}
            <ExternalLink className='bold' to={item.url}>
              {item.title}
            </ExternalLink>
            {!isLast && list.length > 2 ? ', ' : ''}
          </span>
      )
    })
  }

  const handleSubmit = async () => {
    trackEvent({ event: SEGMENT_EVENTS.NEW_CONFIRM_TERMS_BUTTON_CLICK })

    const agreements = product?.agreements?.map(agreement => agreement.id) || []

    await updateApplication({
      agreements,
    })

    if (handleClick) {
      handleClick()
    }
  }

  return (
    <Formik
      initialValues={{
        userAgreements: false,
        otherAgreements: false,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form data-cy='create-account-review-confirm-form' className={styling['agreements-form']}>
          <div className={styling['checkbox-container']}>
            <FormCheckbox
              name='userAgreements'
              id='userAgreements'
              checked={values.userAgreements}
              onChange={e => {
                handleChange(e)
                setFieldValue('userAgreements', !values.userAgreements)
              }}
              disabled={disabled}
            >
              I have read and agree to the{' '}
              {getAgreementsList(userAgreements)}
            </FormCheckbox>
            <FormCheckbox
              name='otherAgreements'
              id='otherAgreements'
              checked={values.otherAgreements}
              onChange={e => {
                handleChange(e)
                setFieldValue('otherAgreements', !values.otherAgreements)
              }}
              disabled={disabled}
            >
              I have read and agree to the{' '}
              {getAgreementsList(otherAgreements)}
              {' '}which apply to all Greenwood products.
            </FormCheckbox>
          </div>
          <div className='button-container'>
            <p className='disclaimer-text'>{COASTAL_BANKING_SERVICES}</p>
            <Button
              type='submit'
              data-cy='create-account-review-agree-button'
              isLoading={disabled}
              disabled={!values.userAgreements || !values.otherAgreements}
            >
              Confirm
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

AgreementsForm.propTypes = {
  submitting: PropTypes.bool,
  updating: PropTypes.bool,
  products: PropTypes.any,
  handleClick: PropTypes.func,
  updateApplication: PropTypes.func,
}

export default AgreementsForm
