import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ReactComponent as BankIcon } from '@common/images/icons/v2/bank.svg'
import { ReactComponent as CardAddIcon } from '@common/images/icons/v2/card-add.svg'
import { ReactComponent as ActivateCardIcon } from '@common/images/icons/v2/activate-card.svg'
import { ReactComponent as DollarCircleIcon } from '@common/images/icons/v2/dollar-circle.svg'
import { ReactComponent as ReceiptIcon } from '@common/images/icons/v2/receipt.svg'
import { ReactComponent as WireTransferIcon } from '@common/images/icons/v2/wire-transfer.svg'

import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'
import Button from '@shared/components/button/Button'

import {
  trackEvent,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'
import { ADD_DEBIT_CARD_FLOW_TYPES, FUNDING_FLOWS, CARD_AVAILABLE_OPERATIONS } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { ALERT_TYPES, MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { DEFAULT_ALERT_DISMISS_DELAY } from '@shared/constants'
import { gql, useMutation } from '@services/serviceUtils'

import styling from './quickActionModal.module.scss'

const activateCardMutation = gql`
  mutation ActivateCard {
    activateCard {
      availableOperations
    }
  }
`

const QuickActionModal = ({
  isOpen = false,
  toggleModal,
  closeModal,
  quickActionType,
  activateCardCallback,
}) => {
  const [headerText, setHeaderText] = useState('')
  const [subText, setSubText] = useState('')
  const [actionsList, setActionsList] = useState(null)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const dispatch = useDispatch()

  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const { webDebitCardTabapay } = useFlags()

  const [activateCard, { loading }] = useMutation(activateCardMutation)

  const addActions = useMemo(() => {
    const actions = [
      {
        heading: 'Add Money from Other Banks',
        icon: <BankIcon />,
        dataCy: 'quick-action-add-money-from-other-banks',
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(
              SEGMENT_SOURCE_DETAILS.ADD_MONEY_FROM_OTHER_BANKS
            ),
          })

          navigate(staticRoutes.transfer.pathname, {
            state: {
              fundingFlow: FUNDING_FLOWS.TRANSFER,
              transferFlowStartPath: pathname,
            },
          })
        },
      },
    ]

    if (webDebitCardTabapay) {
      actions.unshift({
        heading: 'Instant Card Deposit',
        icon: <CardAddIcon />,
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(
              SEGMENT_SOURCE_DETAILS.INSTANT_CARD_DEPOSITS
            ),
          })

          navigate(staticRoutes.debitCardDeposit.pathname, {
            state: {
              fundingFlow: FUNDING_FLOWS.BASIC,
              addDebitCardFlow: ADD_DEBIT_CARD_FLOW_TYPES.BEFORE_DEPOSIT,
              isDashboardQuickAction: true,
            },
          })
        },
        dataCy: 'quick-action-instant-card-deposit',
      })
    }

    return actions
  }, [navigate, pathname, webDebitCardTabapay])

  const manageActions = useMemo(() => {
    const actions = [
      {
        heading: 'Direct Deposit',
        subtext: 'Available in 1-3 pay cycles',
        icon: <DollarCircleIcon />,
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.DIRECT_DEPOSIT),
          })

          navigate(staticRoutes.directDepositSelection.pathname, {
            state: {
              fundingFlow: FUNDING_FLOWS.BASIC,
            },
          })
        },
        dataCy: 'quick-action-direct-deposit',
      },
      {
        heading: 'Wire Transfer',
        subtext: 'Available in 4 hours or less',
        icon: <WireTransferIcon />,
        dataCy: 'quick-action-wire-transfer',
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.WIRE_TRANSFER),
          })

          navigate(staticRoutes.wireTransfer.pathname)
        },
      },
      {
        heading: 'Pay Bills',
        subtext: 'Use your Greenwood card or account details to pay bills',
        icon: <ReceiptIcon />,
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.PAY_BILLS),
          })

          navigate(staticRoutes.payBills.pathname)
        },
        dataCy: 'quick-action-pay-bills',
      },
      {
        heading: 'Transfer Money between Accounts',
        subtext: 'Available in 5-7 business days',
        icon: <BankIcon />,
        dataCy: 'quick-action-transfer-between-accounts',
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(
              SEGMENT_SOURCE_DETAILS.ADD_MONEY_FROM_OTHER_BANKS
            ),
          })

          navigate(staticRoutes.transfer.pathname, {
            state: {
              fundingFlow: FUNDING_FLOWS.TRANSFER,
              transferFlowStartPath: pathname,
            },
          })
        },
      },
    ]

    return actions
  }, [navigate, pathname])

  useEffect(() => {
    switch (quickActionType) {
      case 'manage': {
        setHeaderText('Manage your Money')
        setSubText('Transfer, pay bills, and more')
        setActionsList(manageActions)
        break
      }
      case 'activate': {
        setHeaderText('Activate Card')
        setSubText('You can also activate your card in the Greenwood Mobile App.')
        setActionsList(null)
        break
      }
      case 'add':
      default: {
        setHeaderText('Add Money To Greenwood')
        setSubText('Boost your balance')
        setActionsList(addActions)
        break
      }
    }
  }, [quickActionType, addActions, manageActions])

  const getActions = useCallback(() => {
    const actions = actionsList?.map((action, index) => {
      const { icon, heading, subtext, onClick, dataCy, renderContent } = action

      return (
        <li
          role='button'
          key={`quick-action-${index}`}
          data-cy={dataCy}
          onClick={onClick}
        >
          {icon}
          {renderContent ? (
            <>{renderContent({})}</>
          ) : (
            <div>
              <p>{heading}</p>
              <p className={styling.subtext}>{subtext}</p>
            </div>
          )}
        </li>
      )
    })

    const listClasses = classNames(
      styling['quick-action-list'],
      { [styling['horizontal-list']]: isTablet && actionsList?.length % 2 === 0 }
    )

    return (
      <ul className={listClasses}>
        {actions}
      </ul>
    )
  }, [isTablet, actionsList])

  const isActivate = useMemo(() => quickActionType === 'activate', [quickActionType])

  const handleActivateClick = async () => {
    const response = await activateCard()
    const canActivateCard = response?.data?.activateCard?.availableOperations.includes(
      CARD_AVAILABLE_OPERATIONS.ACTIVATE_CARD_TOGGLE
    )

    /* If successfully activated (make sure response indicates card can no longer be activated),
       close the modal and show a success alert */
    if (!response?.errors && !canActivateCard) {
      closeModal()

      dispatch(
        addAlertAction({
          autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
          type: ALERT_TYPES.SUCCESS,
          text: 'Your Card Is Activated',
          subText: 'Your debit card is now ready for use.',
        })
      )

      if (activateCardCallback) {
        activateCardCallback()
      }
    }
  }

  return (
    <Modal
      centered
      size='md'
      open={isOpen}
      toggleModal={toggleModal}
      modalClassName={classNames(
        'v2-modal',
        styling['quick-action-modal'],
        { 'info-modal': isActivate }
      )}
      modalContentClassName='v2-modal-content'
      className='v2-modal-dialog'
    >
      {isActivate ? (
        <div className='icon-container'><ActivateCardIcon /></div>
      ) : null}
      <ModalHeader
        withCloseButton
        closeModal={closeModal}
        className='v2-modal-header'
      >
        <h3>{headerText}</h3>
        <p>{subText}</p>
      </ModalHeader>
      <ModalBody className='v2-modal-body'>
        {!isActivate ? getActions() : null}
        {isActivate ? (
          <Button onClick={handleActivateClick} isLoading={loading}>Activate</Button>
        ) : null}
      </ModalBody>
    </Modal>
  )
}

QuickActionModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  closeModal: PropTypes.func,
  quickActionType: PropTypes.string,
  activateCardCallback: PropTypes.func,
}

export default QuickActionModal
