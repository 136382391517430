import { useEffect, useMemo, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { gql, useLazyQuery, useMutation } from '@services/serviceUtils'
import { setApplicationAction } from '@redux/application/applicationActions'
import { runDeviceRisk } from '@services/riskManager'
import { toShortMonthDayYearFormat } from '@shared/utils'
import useTryWithoutCatch from '@shared/utils/useTryWithoutCatch'
import { SEGMENT_EVENTS, trackEvent } from '@common/utils'
import { PRODUCT_TYPES } from '@common/constants'
import { store } from '@redux/store'
import { removeAlertsAction } from '@redux/alerts/alertsActions'

const updateableKeys = [
  'antiMoneyLaundering',
  'buildingNumber',
  'deliveryAddress',
  'firstName',
  'lastName',
  'locality',
  'postalCode',
  'requestedProducts',
  'subdivisions',
  'birthDate',
  'taxNumber',
]

// Only include updateable properties and types
const updateInclusionFilter = ([key, value]) => {
  return (
    value !== null &&
    value !== undefined &&
    updateableKeys.includes(key) &&
    // Is a string, or is an object with a 'value' key
    (typeof value === 'string' || 'value' in value || Array.isArray(value))
  )
}

export const fetchApplicationQuery = gql`
  query {
    application {
      userId
      antiMoneyLaundering {
        incomeSource
        monthlyAch
      }
      attributesRequiringResubmission {
        errorMessages
        key
      }
      buildingNumber
      deliveryAddress
      firstName
      lastName
      locality
      postalCode
      requestedProducts
      customerApplicationStatus
      subdivisions
      submittedAt
      birthDate {
        supplied
      }
      emailAddress {
        supplied
      }
      phoneNumber {
        supplied
      }
      taxNumber {
        supplied
      }
      onboardingFlowType
    }
    checkpoints {
      values
    }
  }
`

export const productsQuery = gql`
  query ProductAgreements($legacy: Boolean) {
    products(legacy: $legacy) {
      productType
      agreements {
        id
      }
    }
  }
`

export const updateApplicationMutation = gql`
  mutation UpdateApplication($application: CustomerApplicationRequestInput!) {
    updateApplication(customerApplicationRequestInput: $application) {
      applicationId
    }
  }
`

export const submitApplicationMutation = gql`
  mutation SubmitApplication($submitApplicationRequestInput: SubmitApplicationRequestInput!) {
    submitApplication(submitApplicationRequestInput: $submitApplicationRequestInput) {
      applicationId
      applicationStatus
      userId
      referenceId
      attributesRequiringResubmission {
        errorMessages
        key
      }
    }
  }
`

/**
 * Common hook to query and update the user's application status on page load
 */
const useApplication = () => {
  const [submitting, setSubmitting] = useState(false)
  const [data, setData] = useState()
  const [ssn, setSsn] = useState('XXX-XX-XXXX')
  const [birthDate, setBirthDate] = useState('XX/XX/XXXX')
  const [sessionAgreementsAccepted, setSessionAgreementsAccepted] = useState(null)

  const { state } = useLocation()

  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const [
    getApplication,
    { loading: fetching, error: fetchError, data: fetchData, refetch },
  ] = useLazyQuery(fetchApplicationQuery)

  const [updateApplication, { loading: updating, error: updateError }] = useMutation(
    updateApplicationMutation,
    {
      refetchQueries: [{ query: fetchApplicationQuery }],
      awaitRefetchQueries: true,
    }
  )

  const [submitApplication, { data: submitApplicationData, error: submitError }] = useMutation(
    submitApplicationMutation,
    {
      refetchQueries: [{ query: fetchApplicationQuery }],
      awaitRefetchQueries: true,
    }
  )

  const [getProducts, { data: productsData }] = useLazyQuery(productsQuery, {
    variables: { legacy: false },
  })

  const product = useMemo(
    () =>
      productsData?.products?.find(_product => _product.productType === PRODUCT_TYPES.basic) ||
      null,
    [productsData]
  )

  useEffect(() => {
    if (state?.isResubmitting) {
      getProducts()
    }
  }, [state?.isResubmitting, getProducts])

  useEffect(() => {
    if (product) {
      const agreements = product?.agreements?.map(agreement => agreement.id) || []
      setSessionAgreementsAccepted(agreements)
    }
  }, [product])

  useEffect(() => {
    if (submitApplicationData?.submitApplication) {
      if (submitApplicationData.submitApplication.applicationStatus !== 'PASS') {
        trackEvent({
          event: SEGMENT_EVENTS.BANK_APPLICATION_STATUS_TRACK({
            status: submitApplicationData.submitApplication.applicationStatus,
          }),
        })
      }

      if (submitApplicationData?.submitApplication.referenceId) {
        const { referenceId } = submitApplicationData.submitApplication
        store.dispatch(setApplicationAction({ referenceId }))
      }
    }
  }, [submitApplicationData])

  useEffect(() => {
    setData(fetchData)

    // Also store application status in redux
    if (fetchData) {
      dispatch(
        setApplicationAction({
          submittedAt: fetchData.application?.submittedAt,
          customerApplicationStatus: fetchData.application?.customerApplicationStatus,
        })
      )
    }
  }, [dispatch, fetchData])

  // Only update loading indicator once data has been fetched
  useEffect(() => {
    data && setLoading(fetching)
  }, [fetching, data])

  const update = useCallback(
    newValues => {
      if (newValues.birthDate?.value) {
        setBirthDate(toShortMonthDayYearFormat(`${newValues.birthDate.value}T00:00:00.000`))
      }

      if (newValues.taxNumber?.value) {
        setSsn(
          `XXX-XX-${newValues.taxNumber.value.substring(newValues.taxNumber.value.length - 4)}`
        )
      }

      if (newValues.agreements) {
        // No 'agreements' to update on the 'application', so just update the
        // internally persisted agreement ID array
        setSessionAgreementsAccepted(newValues.agreements)
      }

      // Strips out any data not appropriate for the update
      const applicationToUpdate = Object.fromEntries(
        Object.entries({ ...data.application, ...newValues }).filter(updateInclusionFilter)
      )

      return updateApplication({ variables: { application: applicationToUpdate } })
    },
    [data?.application, updateApplication]
  )

  const appendDeviceSessionId = useTryWithoutCatch(async submitApplicationRequestInput => {
    const response = await runDeviceRisk()

    if (response.result === 'Captured' && response.sessionId) {
      submitApplicationRequestInput.deviceSessionId = response.sessionId
    }
  }, [])

  const submit = useCallback(
    async ({ documentUUID }) => {
      dispatch(removeAlertsAction())
      setSubmitting(true)

      const submitApplicationRequestInput = {}
      if (documentUUID) {
        submitApplicationRequestInput.documentUUID = documentUUID
      } else {
        submitApplicationRequestInput.agreements = sessionAgreementsAccepted
      }

      // Generate a deviceSessionId then submit the application
      await appendDeviceSessionId(submitApplicationRequestInput)

      const response = await submitApplication({
        variables: {
          submitApplicationRequestInput,
        },
      })

      setSubmitting(false)

      return response
    },
    [appendDeviceSessionId, submitApplication, sessionAgreementsAccepted, dispatch]
  )

  return {
    loading,
    updating,
    submitting,
    fetchError,
    updateError,
    submitError,
    data: {
      ...data,
      review: {
        birthDate,
        ssn,
        sessionAgreementsAccepted,
      },
    },
    initialData: fetchData,
    getApplication,
    updateApplication: update,
    submitApplication: submit,
    refetchApplication: refetch,
  }
}

export default useApplication
