import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import { ReactComponent as DollarCircleIcon } from '@common/images/icons/v2/dollar-circle.svg'
import { ReactComponent as BalanceIcon } from '@common/images/icons/v2/balance.svg'
import { ReactComponent as PercentageShieldIcon } from '@common/images/icons/v2/percentage-shield.svg'
import { ReactComponent as InfoCircleIcon } from '@common/images/icons/v2/info-circle.svg'
import { ReactComponent as ReceiptIcon } from '@common/images/icons/v2/receipt.svg'
import { ReactComponent as DotsIcon } from '@common/images/icons/v2/dots.svg'

import TipCard from './components/tipCard/TipCard'
import AccountDetailsTable from './components/accountDetailsTable/AccountDetailsTable'
import TransactionDetails from './components/transactionDetails/TransactionDetails'
import DetailContainer from './components/detailContainer/DetailContainer'
import TipModal from './components/modals/tipModal/TipModal'
import MobileTransactionDetailsModal from './components/modals/mobileTransactionDetailsModal/MobileTransactionDetailsModal'
import StatementsTable from '../settings/statements/components/statementsTable/StatementsTable'
import RecentTransactions from '@common/components/v2/recentTransactions/RecentTransactions'
import Button from '@shared/components/button/Button'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'

import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import { staticRoutes } from '@routing/routes'
import { trackPage, trackEvent, SEGMENT_PAGE_NAMES, SEGMENT_EVENTS } from '@common/utils'
import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
// import usePaginatedQuery from '@common/utils/usePaginatedQuery'
import { gql, useQuery } from '@services/serviceUtils'
import { getBalance } from '@common/utils/appUtils'

import styling from './myAccount.module.scss'

const myAccountQuery = gql`
  query MyAccountQuery($accountId: ID!) {
    account(accountId: $accountId) {
      accountNumber
      accountType
      apy
      balance {
        amount
        currency
      }
      id
      lastStatementDate
      routingNumber
      title
    }
    accountBalance(accountId: $accountId) {
      availableBalance {
        amount
      }
      currentBalance {
        amount
      }
    }
  }
`

const transactionsQuery = gql`
  query TransactionsQuery($accountId: ID!, $page: Int, $txnsPerPage: Int) {
    account(accountId: $accountId, page: $page, txnsPerPage: $txnsPerPage) {
      totalTransactionCount
      transactions {
        amount {
          amount
        }
        description
        details {
          value
          label
        }
        transactionTime
        transactionType
        isSettled
      }
    }
  }
`

const tips = [
  {
    title: 'Useful Tips: Current Balance',
    innerLabel: 'Current Balance',
    color: '#e99254',
    contentText:
    'The total amount of money recorded in your account, including funds not yet available for you to use. This includes pending transactions, authorization holds that are not yet posted or deposits that have not yet been made available.',
    icon: <BalanceIcon />,
  }, {
    title: 'Useful Tips: Current APY',
    innerLabel: 'Current APY',
    color: '#c942a3',
    contentText:
    'The total amount of money recorded in your account, including funds not yet available for you to use. This includes pending transactions, authorization holds that are not yet posted or deposits that have not yet been made available.',
    icon: <PercentageShieldIcon />,
  }, {
    title: 'Useful Tips: Available Balance',
    innerLabel: 'Available Balance',
    color: '#1f9081',
    contentText: 'The amount of money in your account that you can use right now.',
    icon: <DollarCircleIcon />,
  },
]

const MyAccount = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const [transactionDetails, setTransactionDetails] = useState(null)
  const [showTipModal, toggleTipModal] = useState(false)
  const [selectedTip, setSelectedTip] = useState(null)
  const [cardContentView, toggleCardContentView] = useState('')
  const [showMobileTransactionDetailsModal, toggleShowMobileTransactionDetailsModal] = useState(false)

  const accountId = useMemo(() => state?.id, [state?.id])

  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const { setWhiteCardContent, setPageDetails, theme } = useContext(BankingContainerContext)

  const { data: transactionsData, loading: transactionsLoading, error: transactionsError } = useQuery(transactionsQuery, {
    fetchPolicy: 'no-cache',
    variables: { accountId },
  })

  // const {
  //   initialPageLoading,
  //   // pageLoading,
  //   error: transactionsError,
  //   entities: transactions,
  //   // pagination,
  //   // handlePageClick,
  // } = usePaginatedQuery({
  //   query: transactionsQuery,
  //   queryVariables: { accountId },
  //   queryConfig: {
  //     fetchPolicy: 'no-cache',
  //   },
  //   dataEntitiesPath: 'account.transactions',
  //   dataTotalEntitiesPath: 'account.totalTransactionCount',
  // })

  const { loading: myAccountLoading, error: myAccountError, data: myAccountData } = useQuery(
    myAccountQuery,
    { variables: { accountId } }
  )

  const accountBalance = useMemo(() => (
    myAccountData?.accountBalance?.availableBalance?.amount || 0
  ), [myAccountData?.accountBalance?.availableBalance?.amount])

  const segmentSource = useMemo(() => {
    if (myAccountData?.account?.accountType) {
      if (myAccountData.account.accountType === 'SAVINGS') {
        return SEGMENT_PAGE_NAMES.TRANSACTION_HISTORY_SAVINGS
      } else {
        return SEGMENT_PAGE_NAMES.TRANSACTION_HISTORY
      }
    }
  }, [myAccountData?.account?.accountType])

  const pageTitle = useMemo(() => (
    myAccountData?.account?.title || ''
  ), [myAccountData?.account?.title])

  const tipCards = useMemo(() => {
    return tips.map((cardDetails, index) => {
      const {
        innerLabel,
        title,
        color,
        contentText,
        icon,
      } = cardDetails

      return (
        <TipCard
          title={title}
          innerLabel={innerLabel}
          color={color}
          contentText={contentText}
          icon={icon}
          key={`${title}-${index}`}
        />
      )
    })
  }, [])

  useEffect(() => {
    if (!isTablet) {
      toggleShowMobileTransactionDetailsModal(false)
    }
  }, [isTablet])

  useEffect(() => {
    switch (cardContentView) {
      case 'statement':
        setWhiteCardContent(
          <DetailContainer
            label='Statement'
            handleCloseDetails={() => toggleCardContentView('')}
          >
            <StatementsTable rowClassName={styling['statements-table-row']} useTheme={false} />
          </DetailContainer>
        )
        break
      case 'transaction':
        setWhiteCardContent(
          <DetailContainer
            label='Transaction Details'
            handleCloseDetails={() => toggleCardContentView('')}
          >
            <TransactionDetails transactionDetails={transactionDetails} />
          </DetailContainer>
        )
        break
      default:
        setWhiteCardContent(
          [
            <AccountDetailsTable
              key='account-details'
              accountDetails={myAccountData?.account}
              currentBalance={myAccountData?.accountBalance?.currentBalance?.amount}
            />,
            ...tipCards,
          ]
        )
        break
    }
  }, [
    cardContentView,
    myAccountData,
    tipCards,
    setWhiteCardContent,
    isTablet,
    transactionDetails,
  ])

  useEffect(() => {
    if (segmentSource) {
      trackPage({ name: segmentSource })
    }
  }, [segmentSource])

  useEffect(() => {
    if (pageTitle) {
      setPageDetails({
        title: isTablet ? pageTitle : '',
        showBackArrow: true,
        showWhiteCard: true,
      })
    }

    return () => {
      setPageDetails(null)
      setWhiteCardContent(null)
    }
  }, [
    setPageDetails,
    pageTitle,
    isTablet,
    setWhiteCardContent,
  ])

  const handleStatementButtonClick = useCallback(() => {
    if (isTablet) {
      navigate(staticRoutes.settingsDocuments.pathname, {
        state: {
          title: `${pageTitle} Statements`,
        },
      })
    } else {
      toggleCardContentView('statement')
    }
  }, [isTablet, navigate, pageTitle])

  const handleTipButtonClick = labelHint => {
    const tip = tips.find(tip => tip?.innerLabel?.toLowerCase()?.includes(labelHint?.toLowerCase()))
    setSelectedTip(tip)
    toggleTipModal(true)
  }

  const handleTransactionClick = useCallback(transaction => {
    if (segmentSource) {
      trackEvent({
        event: SEGMENT_EVENTS.TRANSACTION_DETAILS_CLICK(segmentSource),
      })
    }

    if (transaction.isSettled) {
      setTransactionDetails(transaction)

      if (isTablet) {
        toggleShowMobileTransactionDetailsModal(true)
      } else {
        toggleCardContentView('transaction')
      }
    } else {
      setSelectedTip({
        innerLabel: 'Pending Transaction',
        contentText: 'A pending transaction, whether withdrawn or added to your account, means it has been submitted but is not complete and is being processed.',
        icon: <DotsIcon />,
      })
      toggleTipModal(true)
    }
  }, [segmentSource, isTablet])

  return (
    <>
      {!accountId && <Navigate to={staticRoutes.dashboard.pathname} />}
      <div className={classNames('v2-page-content-container', styling['my-account'], styling[theme])}>
        <LoadingContainer
          error={myAccountError || transactionsError}
          errorMessage='Error loading account.'
          loading={transactionsLoading || myAccountLoading}
        >
          <Button outline className={styling['statements-button']} onClick={handleStatementButtonClick}>
            <ReceiptIcon />
            {!isTablet && <span>Statement</span>}
          </Button>
          {!isTablet && (
            <div className='title-container'>
              <h2>{pageTitle} Details</h2>
              <p>Review the specifics and features of your {pageTitle?.toLowerCase()}.</p>
            </div>
          )}
          <div className={styling['balance-container']}>
            <div className={classNames('v2-total-balance', styling['available-balance'])}>
              <p>Available Balance {isTablet && (
                <InfoCircleIcon onClick={() => handleTipButtonClick('available')} />
              )}</p>
              {getBalance(accountBalance)}
            </div>
          </div>
          <div className={styling['account-info-container']}>
            {isTablet && (
              <AccountDetailsTable
                className={styling['mobile-details']}
                accountDetails={myAccountData?.account}
                currentBalance={myAccountData?.accountBalance?.currentBalance?.amount}
                onTipButtonClick={tipName => handleTipButtonClick(tipName)}
              />
            )}
            <RecentTransactions
              transactions={transactionsData?.account?.transactions}
              className={styling.transactions}
              handleTransactionClick={transaction => handleTransactionClick(transaction)}
            />
          </div>
        </LoadingContainer>
      </div>
      <TipModal
        isOpen={showTipModal}
        toggleModal={() => toggleTipModal(!showTipModal)}
        closeModal={() => toggleTipModal(false)}
        selectedTip={selectedTip}
      />
      <MobileTransactionDetailsModal
        isOpen={showMobileTransactionDetailsModal}
        toggleModal={() => toggleShowMobileTransactionDetailsModal(!showMobileTransactionDetailsModal)}
        transaction={transactionDetails}
      />
    </>
  )
}

export default MyAccount
