import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import StatementsTable from './components/statementsTable/StatementsTable'

import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import styling from './statements.module.scss'

const Statements = () => {
  const { setPageDetails } = useContext(BankingContainerContext)

  const { state } = useLocation()

  const { title, subtext } = state || {}

  useEffect(() => {
    const pageDetails = {
      showBackArrow: true,
    }

    pageDetails.title = title || 'Banking Statements'

    if (subtext) {
      pageDetails.subtext = subtext
    }

    setPageDetails(pageDetails)

    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails, title, subtext])

  return (
    <div className={styling['statements-container']} data-cy='statements-view'>
      <div className='title-container'>
        <p className='subtext'>Complete Financial Statements and Detailed Transaction Records.</p>
      </div>
      <StatementsTable rowClassName={styling['statements-page-row']} />
    </div>
  )
}

export default Statements
