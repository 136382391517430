import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as TimerIcon } from '@common/images/icons/v2/timer.svg'
import { ReactComponent as DotsIcon } from '@common/images/icons/v2/dots.svg'
import { ReactComponent as AddMoneyIcon } from '@common/images/icons/v2/add-money.svg'

import {
  trackEvent,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'
import { staticRoutes } from '@routing/routes'
import { getBalance, getFormattedDate, getFormattedTime } from '@common/utils/appUtils'

import styling from './recentTransactions.module.scss'

const todayText = 'Today'
const yesterdayText = 'Yesterday'

const RecentTransactions = ({
  accountId,
  title,
  transactions,
  isDashboardView,
  className,
  handleTransactionClick,
}) => {
  const getDateDetails = dateTime => {
    const transactionDate = getFormattedDate(new Date(dateTime))
    const today = getFormattedDate(new Date())
    const yesterday = getFormattedDate(new Date(new Date().setDate(new Date().getDate() - 1)))

    const transactionTime = getFormattedTime(new Date(dateTime))

    return {
      isToday: today === transactionDate,
      transactionDate,
      isYesterday: yesterday === transactionDate,
      transactionTime,
    }
  }

  const getTransactionList = transactions => (
    transactions?.map((transaction, index) => {
      const { isToday, transactionDate, isYesterday, transactionTime } = getDateDetails(transaction?.transactionTime)

      const transactionAmount = transaction?.amount?.amount

      const dateText = isToday ? todayText : isYesterday ? yesterdayText : `${transactionDate}`
      const isPending = !transaction?.isSettled
      const isWithdrawal = transactionAmount?.toString()?.includes('-')
      const icon = isPending ? <DotsIcon /> : <AddMoneyIcon />

      const listItemClasses = classNames(
        styling['list-item'],
        {
          [styling['is-pending']]: isPending,
          [styling['is-withdrawal']]: isWithdrawal,
        }
      )

      return (
        <div
          className={listItemClasses}
          key={`transaction-${index}`}
          role={transaction?.isSettled && handleTransactionClick ? 'button' : ''}
          onClick={() => {
            if (handleTransactionClick) {
              handleTransactionClick(transaction)
            }
          }}
        >
          <div className={styling['list-item-icon-container']}>{icon}</div>
          <div className={styling['list-item-text-container']}>
            <p>{transaction?.description}</p>
            <p className={styling['date-text']}>{!isPending ? `${dateText}, ${transactionTime}` : 'Pending'}</p>
          </div>
          <div className={styling['list-item-amount-container']}>
          {getBalance(`${transactionAmount || 0}`)}
          </div>
        </div>
      )
    })
  )

  const hasRecentTransactions = useMemo(() => transactions?.length, [transactions?.length])

  const accountType = useMemo(() => title?.split(' ')[0], [title])

  const groupedTransactions = useMemo(() => {
    const groupTransactionsByMonth = transactions => {
      return transactions?.reduce((groupedTransactions, transaction) => {
        const transactionDetails = getDateDetails(transaction.transactionTime)

        let headingText = transactionDetails.transactionDate

        if (transactionDetails.isToday) {
          headingText = todayText
        } else if (transactionDetails.isYesterday) {
          headingText = yesterdayText
        }

        groupedTransactions[headingText] = groupedTransactions[headingText] || []
        groupedTransactions[headingText].push(transaction)

        return groupedTransactions
      }, [])
    }

    return groupTransactionsByMonth(transactions)
  }, [transactions])

  return (
    <div className={classNames(styling['recent-transactions-container'], className)}>
      <div className={styling['transactions-title-container']}>
        {isDashboardView ? (
          <>
            <h3 className='v2-header'>
              Recent Transactions <span className={styling[accountType?.toLowerCase()]}>{accountType}</span>
            </h3>
            <Link
              onClick={() => {
                trackEvent({
                  event: SEGMENT_EVENTS.DASHBOARD_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.VIEW_ACCOUNT_DETAILS),
                })
              }}
              to={staticRoutes.myAccount.pathname}
              state={{ id: accountId }}
            >
              view more
            </Link>
          </>
        ) : (
          <h3 className='v2-header'>Transaction History</h3>
        )}
      </div>
      <div
        className={classNames(
          styling.transactions,
          {
            [styling['is-dashboard-view']]: isDashboardView,
            [styling['is-empty']]: !hasRecentTransactions,
          }
        )}
      >
        {hasRecentTransactions ? (
          <>
            {isDashboardView ? (
              getTransactionList(transactions?.slice(0, 4))
            ) : (
              <>
                {Object.keys(groupedTransactions)?.map((date, index) => (
                  <div key={`transaction-group-${index}`} className={styling['transaction-group']}>
                    <p>{date}</p>
                    {getTransactionList(groupedTransactions[date])}
                  </div>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            <div className={styling['is-empty-icon-container']}><TimerIcon /></div>
            <p>No Transactions yet</p>
            <p>Unlock endless possibilities with your first transaction - Welcome to seamless banking!</p>
          </>
        )}
        {!isDashboardView && (
          <div className={styling['logo-container']}>
            <GreenwoodLogo className={styling.logo} />
            {transactions?.length ? <p>End of your 30-day transaction history</p> : null}
          </div>
        )}
      </div>
    </div>
  )
}

RecentTransactions.propTypes = {
  accountId: PropTypes.string,
  title: PropTypes.string,
  transactions: PropTypes.any,
  isDashboardView: PropTypes.bool,
  className: PropTypes.string,
  handleTransactionClick: PropTypes.func,
}

export default RecentTransactions
