import React, { useContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Loading from '@shared/components/Loading'

import { setPollingHasMaxedOut } from '@redux/application/applicationActions'
import { useCheckpointNavigation } from '@common/utils'
import { staticRoutes } from '@routing/routes'
import { CreateAccountContext } from './CreateAccount'

import styling from './createAccountProcessing.module.scss'

const CreateAccountProcessing = React.memo(
  () => {
    const navigate = useNavigate()
    const pollInterval = useRef()
    const maxTimeout = useRef()
    const dispatch = useDispatch()
    const pollingHasMaxedOut = useSelector(state => state?.application?.pollingHasMaxedOut)

    const { data, fetchError, refetchApplication } = useContext(CreateAccountContext)

    useCheckpointNavigation({
      checkpoints: data?.checkpoints,
      applicationStatus: data?.application?.customerApplicationStatus,
      pollingHasMaxedOut,
    })

    useEffect(() => {
      pollInterval.current = setInterval(() => {
        refetchApplication()
      }, 5000)

      // poll for a max of 60 seconds
      maxTimeout.current = setTimeout(() => {
        dispatch(setPollingHasMaxedOut())
        navigate(staticRoutes.createAccountInReview.pathname)
      }, 60000)

      return () => {
        clearInterval(pollInterval.current)
        clearTimeout(maxTimeout.current)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (fetchError) {
        navigate(staticRoutes.createAccountInReview.pathname)
      }
    }, [fetchError, navigate, pollInterval])

    return (
      <div className='create-account-content-wrapper' data-cy='authenticated-loaded-view'>
        <Loading
          className={styling['loading-icon']}
        />
      </div>
    )
  },
  (prevProps, nextProps) =>
    prevProps?.data?.application?.customerApplicationStatus ===
    nextProps?.data?.application?.customerApplicationStatus
)

export default CreateAccountProcessing
