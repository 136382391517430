import React, { useContext } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import CtaCard from '@common/components/v2/ctaCard/CtaCard'

import { staticRoutes } from '@routing/routes'
import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import styling from './exploreInvestCallout.module.scss'

const ExploreInvestCallout = () => {
  const navigate = useNavigate()

  const { theme } = useContext(BankingContainerContext)

  return (
    <CtaCard
      className={classNames(styling.card, 'new-card', styling[theme])}
      headerText='Explore Investment Opportunities with Greenwood'
      subText='Invest in stocks & securities with Greenwood today!'
      onClick={() => navigate(staticRoutes.invest.pathname)}
    ></CtaCard>
  )
}

export default ExploreInvestCallout
