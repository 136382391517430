import React, { useContext, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import Modal from '@shared/components/modal/Modal'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ModalBody from '@shared/components/modal/ModalBody'
import FormRadio from '@shared/components/formRadio/FormRadio'
import Button from '@shared/components/button/Button'

import { ROUTE_USER_FLOW } from '@shared/constants/uiConstants'
import { staticRoutes } from '@routing/routes'
import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import styling from './privacySecurityView.module.scss'

const radioThemes = [
  { value: 'light', label: 'Light' },
  { value: 'dark', label: 'Dark' },
]

const PrivacySecurityView = () => {
  const [showThemeModal, toggleThemeModal] = useState(false)
  const navigate = useNavigate()
  const sessionId = useSelector(state => state.auth?.sessionId)

  const { setPageDetails, theme, toggleTheme } = useContext(BankingContainerContext)

  const [selectedTheme, setSelectedTheme] = useState(theme)

  useEffect(() => {
    setPageDetails({
      title: 'Settings',
      showBackArrow: true,
    })

    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails])

  const securityOptionRows = useMemo(
    () => [
      {
        label: 'Reset Password',
        dataCy: 'settings-security-reset-password-row',
        onClick: () =>
          navigate(staticRoutes.recoverProvideEmail.pathname, {
            state: {
              userFlow: ROUTE_USER_FLOW.RESET_PASSWORD,
              sessionId,
            },
          }),
      },
      {
        label: 'Verification Codes',
        dataCy: 'settings-security-verification-codes-row',
        onClick: () => navigate(staticRoutes.settingsPrivacySecurityVerificationCodes.pathname),
      },
    ],
    [navigate, sessionId]
  )

  return (
    <>
      <div
        className={classNames(
          'settings-view-content',
          styling['settings-privacy-security-view-container']
        )}
      >
        <h2>Security</h2>
        <p className='subtext'>Manage security preferences and further secure your accounts.</p>
        <table className={classNames('simple-table', styling['settings-link-table'])}>
          <tbody>
            {securityOptionRows.map((rowItem, index) => (
              <tr className='link-row' key={index} data-cy={rowItem.dataCy} onClick={rowItem.onClick}>
                <th>{rowItem.label}</th>
                <td className='row-chevron'>
                  <Chevron />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>Others</h2>
        <table className={classNames('simple-table', styling['settings-link-table'])}>
          <tbody>
            <tr className='link-row' onClick={() => toggleThemeModal(true)}>
              <th>Theme</th>
              <td>{theme}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal
        centered
        size='md'
        open={showThemeModal}
        toggleModal={() => toggleThemeModal(!showThemeModal)}
        modalClassName={classNames('v2-modal', styling['theme-modal'])}
        modalContentClassName='v2-modal-content'
        className={classNames(styling['modal-dialog'], 'v2-modal-dialog')}
      >
        <ModalHeader
          withCloseButton
          closeModal={() => toggleThemeModal(false)}
          className='v2-modal-header'
        >
          <h3>Theme</h3>
        </ModalHeader>
        <ModalBody className='v2-modal-body'>
          <FormRadio
            name='theme'
            type='radio'
            options={radioThemes}
            defaultSelected={theme}
            onChange={event => setSelectedTheme(event?.target?.value)}
          />
          <Button
            onClick={() => {
              toggleTheme()
              toggleThemeModal(false)
            }}
            disabled={selectedTheme === theme}
            className={styling['confirm-button']}
          >
            Confirm
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PrivacySecurityView
