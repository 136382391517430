import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as BankIcon } from '@common/images/icons/v2/bank.svg'

import StatementRow from '../statementRow/StatementRow'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'

import { gql, useQuery } from '@services/serviceUtils'

import styling from './statementsTable.module.scss'

const statementListQuery = gql`
  query {
    statements {
      statements {
        id
        title
      }
    }
  }
`

const StatementsTable = ({ className, rowClassName, useTheme = true }) => {
  const {
    data: statementListData,
    loading: statementListLoading,
    error: statementListError,
  } = useQuery(statementListQuery)

  const statements = useMemo(() => statementListData?.statements?.statements || [], [statementListData])

  return (
    <LoadingContainer
      error={statementListError}
      errorMessage='Error loading statements.'
      loading={statementListLoading}
    >
      {statements?.length ? (
        <table
          className={classNames('simple-table', styling['statements-table'], className)}
          data-cy='statements-list'
        >
          <tbody>
            {statements.map(statement => (
              <StatementRow
                key={statement.id}
                statement={statement}
                className={rowClassName}
                useTheme={useTheme}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className={classNames(styling['no-statements-container'], useTheme && styling['use-theme'])}>
          <div className={styling['bank-icon-container']}>
            <BankIcon />
          </div>
          <p>Account Statements</p>
          <p>This is were your monthly statements will appear once they're available</p>
        </div>
      )}
    </LoadingContainer>
  )
}

StatementsTable.propTypes = {
  className: PropTypes.string,
  rowClassName: PropTypes.string,
}

export default StatementsTable
