import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import Button from '@shared/components/button/Button'

import { staticRoutes } from '@routing/routes'
import { AddressResubmissionContext } from './AddressResubmission'

import styling from './addressResubmissionRequired.module.scss'

const AddressResubmissionRequired = () => {
  const navigate = useNavigate()

  const { data: applicationData } = useContext(AddressResubmissionContext)

  const { application = null } = applicationData

  const errorMessages = useMemo(() => {
    const errors = []

    const addressAttribute = application?.attributesRequiringResubmission?.find(
      attribute => attribute.key === 'address'
    )

    if (addressAttribute) {
      addressAttribute.errorMessages?.forEach((message, index) => {
        errors.push(
          <p key={index} className={styling['error-message']}>
            {message}
          </p>
        )
      })
    }
    return errors
  }, [application?.attributesRequiringResubmission])

  return (
    <div
      className={classNames(
        'create-account-black-page',
        styling['address-resubmission-required-container']
      )}
    >
      <GreenwoodLogo className='logo' />
      <div className='create-account-content-wrapper'>
        <h1>We could not verify your address</h1>
        {errorMessages}
        <div className='buttons-container'>
          <Button onClick={() => navigate(staticRoutes.addressResubmissionEntry.pathname)}>
            Update Address
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddressResubmissionRequired
