import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'

import Button from '@shared/components/button/Button'
import FormInput from '@shared/components/formInput/FormInput'
import ManualAddressEntryForm from '@common/components/manualAddressEntryForm/ManualAddressEntryForm'

import {
  useCheckpointNavigation,
  trackEvent,
  trackPage,
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'
import { setCustomerAction } from '@redux/customer/customerActions'
import {
  CUSTOMER_NAME_MAX_LENGTH,
  NAME_ADDRESS_VALIDATION_SCHEMA,
} from '@common/constants'
import ReduxField from '@common/components/reduxField/ReduxField'
import usePreventBrowserBackClick from '@common/utils/usePreventBrowserBackClick'
import { removeAlertsAction } from '@redux/alerts/alertsActions'
import { CreateAccountContext } from '../CreateAccount'

import styling from './createAccountNameAddress.module.scss'

const CreateAccountNameAddress = () => {
  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)
  const [isSelectingAddress, setIsSelectingAddress] = useState(false)
  const [showManualEntry, setShowManualEntry] = useState(false)

  const { updateApplication, data, loading } = useContext(CreateAccountContext)

  const dispatch = useDispatch()
  usePreventBrowserBackClick()

  // Track visits to registration name page
  // Track visits to the address page
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_NAME })
    trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_ADDRESS })
    // When a user visits the name input page, track the event
    trackEvent({ event: SEGMENT_EVENTS.BANK_APPLICATION_STARTED })
  }, [])

  const { proceedToNextCheckpoint } = useCheckpointNavigation({
    checkpoints: data?.checkpoints,
    applicationStatus: data?.application?.customerApplicationStatus,
  })

  const handleSubmit = async (values, actions) => {
    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */
    dispatch(removeAlertsAction())

    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }

    trackEvent({
      event: SEGMENT_EVENTS.NEW_ADDRESS_CONFIRM_BUTTON_CLICK,
    })

    const appValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      deliveryAddress: values.deliveryAddress,
      buildingNumber: values.aptUnitFloor,
      locality: values.locality,
      subdivisions: [values.state], // state
      postalCode: values.zipCode,
    }

    const response = await updateApplication(appValues)
    if (response) {
      dispatch(setCustomerAction({
        firstName: values.firstName,
        lastName: values.lastName,
      }))
      proceedToNextCheckpoint()
    }

    actions.setSubmitting(false)
  }

  return (
    <div className='create-account-content-wrapper'>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          deliveryAddress: '',
          aptUnitFloor: '',
          locality: '',
          state: '',
          zipCode: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={NAME_ADDRESS_VALIDATION_SCHEMA}
        enableReinitialize
      >
        {form => {
          const { errors, isSubmitting, touched, handleBlur } = form
          const disabled = isSubmitting
          const isLoading = loading || isSelectingAddress || isSubmitting

          return (
            <Form data-cy='name-address-form' className={styling['name-address-form']}>
              <div className={styling['name-container']}>
                <ReduxField
                  as={FormInput}
                  name='firstName'
                  type='text'
                  placeholder='First Name'
                  autoComplete='given-name'
                  showRequiredAsterisk={false}
                  invalid={errors.firstName && touched.firstName}
                  errorText={errors.firstName}
                  disabled={disabled}
                  autoFocus
                  maxLength={CUSTOMER_NAME_MAX_LENGTH}
                  data-cy='create-account-name-first-name-input'
                  onFocus={() => {
                    setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.FIRST_NAME)
                  }}
                  onBlur={e => {
                    handleBlur(e)

                    // Track any time the user leaves the first name field during registration
                    trackEvent({
                      event: SEGMENT_EVENTS.registrationFormFieldEntry({
                        sourceDetail: SEGMENT_SOURCE_DETAILS.FIRST_NAME,
                      }),
                    })

                    setFocusedInputSourceDetail(null)
                  }}
                />
                <ReduxField
                  as={FormInput}
                  name='lastName'
                  type='text'
                  placeholder='Last Name'
                  autoComplete='family-name'
                  showRequiredAsterisk={false}
                  invalid={errors.lastName && touched.lastName}
                  errorText={errors.lastName}
                  disabled={disabled}
                  maxLength={CUSTOMER_NAME_MAX_LENGTH}
                  data-cy='create-account-name-last-name-input'
                  onFocus={() => {
                    setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.LAST_NAME)
                  }}
                  onBlur={e => {
                    handleBlur(e)

                    // Track any time the user leaves the last name field during registration
                    trackEvent({
                      event: SEGMENT_EVENTS.registrationFormFieldEntry({
                        sourceDetail: SEGMENT_SOURCE_DETAILS.LAST_NAME,
                      }),
                    })

                    setFocusedInputSourceDetail(null)
                  }}
                />
              </div>
              <p className={styling['name-hint']}>Use your legal name. You can add a preferred name later.</p>
              <ManualAddressEntryForm
                setIsSelectingAddress={value => setIsSelectingAddress(value)}
                formValues={form}
                showManualEntry={showManualEntry}
                setShowManualEntry={value => setShowManualEntry(value)}
                className={styling['address-entry-container']}
              />
              <div className='button-container'>
                <Button
                  type='submit'
                  isLoading={isLoading}
                  disabled={disabled}
                >
                  Next
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CreateAccountNameAddress
