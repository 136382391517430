import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

import DownloadAppCallout from '@common/components/callout/DownloadAppCallout'
import UpgradeMembershipCallout from '@common/components/callout/UpgradeMembershipCallout'
import StandardUpgradeInvestCampaignCallout from '@common/components/callout/invest/StandardUpgradeInvestCampaignCallout'
import NewUserInvestCampaignCallout from '@common/components/callout/invest/NewUserInvestCampaignCallout'
import ExploreInvestCallout from '@common/components/callout/invest/ExploreInvestCallout'

import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import { INVEST_PROMOTIONS } from '@common/constants'
import { SEGMENT_PAGE_NAMES } from '@common/utils'
import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'

import styling from './dashboardCtas.module.scss'

const DashboardCtas = ({
  isStandardAccount,
  activePromotions,
  showInvestPromoCallout,
  showExploreInvestCallout,
}) => {
  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  // store swiper instance
  const [swiper, setSwiper] = useState(null)
  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)

  const getSwiperSlide = ({ key, component }) => (
    <SwiperSlide key={key} className={styling.slide}>
      {component}
    </SwiperSlide>
  )

  const ctaList = useMemo(() => {
    const list = []

    if (isStandardAccount) {
      list.push(
        getSwiperSlide({
          component: (
            <UpgradeMembershipCallout
              isDashboardCta
              segmentSource={SEGMENT_PAGE_NAMES.DASHBOARD_HOME}
            />
          ),
          key: 'upgrade-membership-cta',
        })
      )
    }

    list.push(
      getSwiperSlide({
        component: <DownloadAppCallout />,
        key: 'download-app-cta',
      })
    )

    if (isStandardAccount && activePromotions?.includes(INVEST_PROMOTIONS.INVEST_STOCK_PROMO)) {
      list.unshift(
        getSwiperSlide({
          component: <StandardUpgradeInvestCampaignCallout />,
          key: 'standard-upgrade-invest-campaign-cta',
        })
      )
    } else if (showInvestPromoCallout) {
      list.unshift(
        getSwiperSlide({
          component: <NewUserInvestCampaignCallout />,
          key: 'new-user-invest-campaign-cta',
        })
      )
    }

    if (showExploreInvestCallout) {
      list.unshift(
        getSwiperSlide({
          component: <ExploreInvestCallout />,
          key: 'explore-invest-cta',
        })
      )
    }

    return list
  }, [
    activePromotions,
    isStandardAccount,
    showExploreInvestCallout,
    showInvestPromoCallout,
  ])

  const getCtaSwiper = useCallback(() => (
    <Swiper
      modules={[A11y, Navigation]}
      a11y
      className={styling.swiper}
      direction={isTablet ? 'horizontal' : 'vertical'}
      enabled={isTablet}
      spaceBetween={8}
      onSwiper={setSwiper}
      navigation={ctaList?.length > 1 && {
        nextEl: nextButtonRef.current,
        prevEl: prevButtonRef.current,
      }}
      breakpoints={{
        320: {
          slidesPerView: ctaList?.length > 2 ? 1.75 : 1.25,
        },
        576: {
          slidesPerView: ctaList?.length > 2 ? 2 : 1.5,
        },
      }}
    >
      {ctaList}
    </Swiper>
  ), [ctaList, isTablet])

  return (
    <div className={styling['cta-main-container']}>
      <h3 className={classNames('v2-header', styling['suggested-for-you'])}>suggested for you</h3>
      {isTablet ? getCtaSwiper() : <div className={styling['desktop-cta-swiper']}>{getCtaSwiper()}</div>}
      {isTablet && ctaList?.length > 1 && (
        <div className={styling.controls}>
          <button
            className={styling['prev-button']}
            ref={prevButtonRef}
            onClick={() => {
              swiper.slidePrev()
            }}
          >
            <Chevron />
          </button>
          <button
            className={styling['next-button']}
            ref={nextButtonRef}
            onClick={() => {
              swiper.slideNext()
            }}
          >
            <Chevron />
          </button>
        </div>
      )}
    </div>
  )
}

DashboardCtas.propTypes = {
  isStandardAccount: PropTypes.bool,
  activePromotions: PropTypes.array,
  showInvestPromoCallout: PropTypes.bool,
  showExploreInvestCallout: PropTypes.bool,
}

export default DashboardCtas
