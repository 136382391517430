import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { geocodeByPlaceId } from 'react-places-autocomplete'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import classNames from 'classnames'

import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'
import PoweredByGoogle from '@common/images/powered-by-google.png'

import FormInput from '@shared/components/formInput/FormInput'
import Loading from '@shared/components/Loading'

import styling from './formPlacesInput.module.scss'

const FormPlacesInput = ({
  apiKey,
  className,
  label,
  name,
  placeholder = '123 Main Street',
  onChange,
  onSelect,
  onSelecting,
  value,
  ...rest
}) => {
  const [hasGoogleError, setHasGoogleError] = useState(false)
  const [showAutocompleteDropdown, setShowAutocompleteDropdown] = useState(false)

  const inputContainerRef = useRef(null)
  const inputRef = useRef(null)

  const searchOptions = {
    componentRestrictions: { country: 'us' },
    types: ['address'], // return only geocoding results with a precise address
  }

  const {
    getPlacePredictions,
    placePredictions,
    isPlacePredictionsLoading: loading,
  } = usePlacesService({
    apiKey,
    options: searchOptions,
  })

  useEffect(() => {
    if (inputRef?.current?.value && placePredictions.length === 0) {
      setHasGoogleError(true)
    } else {
      setHasGoogleError(false)
    }
  }, [placePredictions, inputRef.current])

  const handleChange = e => {
    setHasGoogleError(false)
    setShowAutocompleteDropdown(e.target.value.length >= 3)
    onChange(e)
  }

  const handleSelectSuggestion = address => {
    const searchAddress = address?.structured_formatting?.main_text
      ? address.structured_formatting.main_text
      : address
    const returnAddress = { searchAddress, addressComponents: {} }

    if (typeof address === 'object') {
      onSelecting(true)
      geocodeByPlaceId(address.place_id).then(results => {
        const place = results[0]
        const addressComponents = place.address_components

        if (addressComponents) {
          // build key/value pair from the type and short name
          addressComponents.forEach(v => {
            returnAddress.addressComponents[v.types[0]] = v.short_name
          })
        }

        onSelecting(false)
        onSelect(returnAddress)
      })
    } else {
      onSelect(returnAddress)
      setShowAutocompleteDropdown(false)
    }
  }

  const handleOutsideDropdownClick = e => {
    if (!inputContainerRef?.current?.contains(e.target)) setShowAutocompleteDropdown(false)
  }

  useEffect(() => {
    window.addEventListener('click', handleOutsideDropdownClick)

    return () => {
      window.removeEventListener('click', handleOutsideDropdownClick)
    }
  }, [])

  return ( 
    <div
      className={classNames(styling['input-container'], className)}
      ref={inputContainerRef}
    >
      <FormInput
        innerRef={inputRef}
        label={label}
        name={name}
        placeholder={placeholder}
        id={name}
        showRequiredAsterisk={false}
        autoComplete='off'
        onChange={e => {
          getPlacePredictions({ input: e.target.value })
          handleChange(e)
        }}
        value={value}
        {...rest}
      />
      {(loading || placePredictions.length > 0 || hasGoogleError) && showAutocompleteDropdown && (
        <div
          className={styling['autocomplete-dropdown-container']}
          id='autocomplete-dropdown-container'
        >
          <div className={classNames(styling.loading, { [styling.show]: loading })}>
            <Loading />
          </div>

          {hasGoogleError && (
            <div className={styling['no-results']}>
              <span className={styling['main-text']}>No results found.</span>
            </div>
          )}
          {!loading && (placePredictions.length > 0 || hasGoogleError) && (
            <>
              {placePredictions.slice(0, 4).map(suggestion => {
                return (
                  <div
                    className={styling.suggestion}
                    key={suggestion.place_id}
                    role='option'
                    onClick={() => {
                      handleSelectSuggestion(suggestion)
                      setShowAutocompleteDropdown(false)
                    }}
                  >
                    <div>
                      <span className={styling['main-text']}>
                        {suggestion.structured_formatting.main_text}
                      </span>
                      <span className={styling.description}>
                        {suggestion.description}
                      </span>
                    </div>
                    <Chevron />
                  </div>
                )
              })}
              {/* If the user's address isn't found, allow them to enter their own address.
                  To do that, override the default onMouseDown event.
                  PlacesAutocomplete uses onMouseDown, not onClick. */}
              <div
                className={styling.suggestion}
                id='do-not-see-address'
                onMouseDown={() => handleSelectSuggestion(value)}
              >
                <div>
                  <span className={styling['main-text']}>
                    I don't see my address here
                  </span>
                </div>
                <Chevron />
              </div>
            </>
          )}
          <div className={styling['powered-by']}>
            <img src={PoweredByGoogle} alt='Powered by Google' />
          </div>
        </div>
      )}
    </div>
  )
}

FormPlacesInput.propTypes = {
  /**
   * Google API key
   */
  apiKey: PropTypes.string,

  /**
   * CSS class to pass to the input
   */
  className: PropTypes.string,

  /**
   * Label for the input
   */
  label: PropTypes.string,

  /**
   * Name for the input
   */
  name: PropTypes.string,

  /**
   * Placeholder value for the input
   */
  placeholder: PropTypes.string,

  /**
   * Callback function for the onChange event for the input
   */
  onChange: PropTypes.func,

  /**
   * Callback function for the onSelect event for the input
   */
  onSelect: PropTypes.func,

  /**
   * Callback function to indicate selection is in progress
   * @argument boolean
   */
  onSelecting: PropTypes.func,

  /**
   * Value for the input
   */
  value: PropTypes.string,
}

export default FormPlacesInput
