import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Modal from '@shared/components/modal/Modal'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ModalBody from '@shared/components/modal/ModalBody'
import Button from '@shared/components/button/Button'

import styling from './tipModal.module.scss'

const TipModal = ({ isOpen, toggleModal, closeModal, selectedTip }) => {
  const iconContainerClasses = classNames(
    'icon-container',
    { [styling['is-pending']]: selectedTip?.innerLabel?.toLowerCase()?.includes('pending') }
  )

  return (
    <Modal
      centered
      size='md'
      open={isOpen}
      modalClassName='v2-modal info-modal'
      toggleModal={toggleModal}
      modalContentClassName='v2-modal-content'
      className='v2-modal-dialog'
    >
      <div className={iconContainerClasses}>{selectedTip?.icon}</div>
      <ModalHeader className='v2-modal-header'>
        <h3>{selectedTip?.innerLabel}</h3>
        <p>{selectedTip?.contentText}</p>
      </ModalHeader>
      <ModalBody className='v2-modal-body'>
        <Button onClick={closeModal}>Got It</Button>
      </ModalBody>
    </Modal>
  )
}

TipModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  closeModal: PropTypes.func,
  selectedTip: PropTypes.any,
}

export default TipModal
