import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styling from './tipCard.module.scss'

const TipCard = ({
  className,
  title,
  color = '#1a1b1d', // $new-black
  showHorizontalRule = true,
  icon,
  children,
  innerLabel,
  contentText,
}) => (
  <div className={classNames(className, styling['tip-card'])}>
    <div className={styling['heading-container']}>
      <div className={styling['icon-container']} style={{ backgroundColor: `${color}` }}>
        {icon}
      </div>
      {title}
    </div>
    {showHorizontalRule && <hr />}
    {children || (
      <div className={styling['content-container']}>
        {innerLabel && <p className={styling['inner-label']}>{innerLabel}</p>}
        {contentText && <p>{contentText}</p>}
      </div>
    )}
  </div>
)

TipCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  showHorizontalRule: PropTypes.bool,
  icon: PropTypes.any,
  children: PropTypes.any,
  innerLabel: PropTypes.string,
  contextLabel: PropTypes.string,
}

export default TipCard
