import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'

import { ReactComponent as DocumentUpload } from '@common/images/illustrations/illustration-document-upload.svg'
import { ReactComponent as InReviewIcon } from '@common/images/illustrations/illustration-in-review.svg'
import { ReactComponent as SadFace } from '@common/images/icons/sad-face.svg'
import Button from '@shared/components/button/Button'

import { APPLICATION_STATUSES } from '@shared/constants/uiConstants'
import { toMediumDateFormat } from '@shared/utils'
import { staticRoutes } from '@routing/routes'
import { SEGMENT_PAGE_NAMES, trackPage, isMobileBrowser } from '@common/utils'

import styling from './applicationStatusDisplay.module.scss'

const Display = ({
  icon,
  dataCy,
  heading,
  message,
  displayStatus,
  applicationData,
  buttonText = 'Continue',
  showButton = false,
  buttonOnClick = () => {},
}) => (
  <div className={styling['status-display']}>
    {icon}
    <div className={styling['status-display-content']}>
      <h2 data-cy={dataCy}>{heading}</h2>

      {message}

      <table className='v2-simple-table'>
        <tbody>
          {applicationData?.submittedAt && (
            <tr>
              <th scope='row'>Submitted On</th>
              <td>{toMediumDateFormat(applicationData.submittedAt)}</td>
            </tr>
          )}
          <tr>
            <th scope='row'>Application Status</th>
            <td>{displayStatus}</td>
          </tr>
        </tbody>
      </table>
      {showButton && <Button onClick={buttonOnClick} data-cy='mobile-continue-button'>{buttonText}</Button>}
    </div>
  </div>
)

const ApplicationStatusDisplay = ({ applicationData, status, pollingHasMaxedOut }) => {
  const navigate = useNavigate()

  const isMobile = isMobileBrowser()

  // Track bank application for any non-PASS status
  useEffect(() => {
    if (status === APPLICATION_STATUSES.DECLINED) {
      trackPage({ name: SEGMENT_PAGE_NAMES.BANK_APPLICATION_DECLINED })
    } else if (status !== APPLICATION_STATUSES.PASS) {
      trackPage({ name: SEGMENT_PAGE_NAMES.BANK_APPLICATION_IN_REVIEW })
    }
  }, [status])

  return useMemo(() => {
    switch (status) {
      case APPLICATION_STATUSES.REVIEW:
      case APPLICATION_STATUSES.DOCUMENT_UNDER_REVIEW:
      case APPLICATION_STATUSES.SUBMITTED: {
        if (status === APPLICATION_STATUSES.SUBMITTED && !pollingHasMaxedOut) {
          return null
        }

        return (
          <Display
            icon={<InReviewIcon />}
            heading='Your application is in review'
            dataCy='in-review-heading'
            displayStatus='In Review'
            applicationData={applicationData}
            message={
              <p>
                We are in the process of reviewing your information. If you have any questions,
                please{' '}
                <Link className='underlined-link bold' to={staticRoutes.settingsHelp.pathname}>
                  Contact Us
                </Link>
                .
              </p>
            }
          />
        )
      }

      case APPLICATION_STATUSES.DOCUMENT_REQUIRED:
      case APPLICATION_STATUSES.DOCUMENT_RESUBMISSION_REQUIRED: {
        const messageText = isMobile
          ? 'We need a little more information. Click continue to start the document upload process.'
          : 'Continue the document upload process on your mobile device.'

        return (
          <Display
            icon={<DocumentUpload />}
            heading='Documents required'
            dataCy='documents-required-heading'
            displayStatus='Documents Required'
            applicationData={applicationData}
            message={
              <>
                <p>{messageText}</p>
                <p>
                  If you have any questions, please{' '}
                  <Link className='underlined-link bold' to={staticRoutes.settingsHelp.pathname}>
                    Contact Us
                  </Link>
                  .
                </p>
              </>
            }
            showButton={isMobile}
            buttonOnClick={() =>
              navigate(staticRoutes.documentSubmit.pathname, { state: { isSubmittingDocuments: true } })
            }
          />
        )
      }

      case APPLICATION_STATUSES.DECLINED:
        return (
          <Display
            icon={<SadFace className={styling['application-declined-icon']} />}
            heading='Application Declined'
            dataCy='application-declined-heading'
            displayStatus='Declined'
            applicationData={applicationData}
            message={
              <p>
                Your account application with Greenwood has been declined. If you have any
                questions, please{' '}
                <Link className='underlined-link bold' to={staticRoutes.settingsHelp.pathname}>
                  Contact Us
                </Link>
                .
              </p>
            }
          />
        )

      default:
        return null
    }
  }, [status, pollingHasMaxedOut, applicationData, navigate, isMobile])
}

Display.propTypes = {
  /* Icon (React Component) to be shown */
  icon: PropTypes.any,
  /* String to describe the status display (for usage with Cypress testing) */
  dataCy: PropTypes.string,
  /* Status display header text */
  heading: PropTypes.string,
  /* Message string or JSX for status display */
  message: PropTypes.any,
  /* The description of the application status that is shown in the table */
  displayStatus: PropTypes.string,
  /* The user's application data */
  applicationData: PropTypes.object,
  buttonText: PropTypes.string,
  showButton: PropTypes.bool,
}

ApplicationStatusDisplay.propTypes = {
  /* The user's application data */
  applicationData: PropTypes.object,
  /* The user's application status; it will determine what is shown in the display */
  status: PropTypes.string.isRequired,
  pollingHasMaxedOut: PropTypes.bool,
}

export default ApplicationStatusDisplay
