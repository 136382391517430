// Not using @routing alias here because Cypress doesn't like it
import { staticRoutes } from '../../routing/routes'
import * as Yup from 'yup'
import { loadStripe } from '@stripe/stripe-js'

import MyGreenwoodCard from '../images/greenwood-plastic-card.svg'
import ElevateCard from '../images/greenwood-elevate-card.png'
import PlatinumCard from '../images/greenwood-platinum-card.png'

// Environment specific variables
let REST_API_BASE_URL
let GRAPHQL_BASE_URL
let CLIENT_BASE_URL
let IGNORE_IDLE_USER = false
let SHOULD_SUBMIT_RECAPTCHA
let PLAID_OAUTH_REDIRECT_URL
let TABAPAY_3DS_SONGBIRD_URL
let CONTENTFUL_ENVIRONMENT

// These variables are shared between all environments
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
const RISK_API_CNAME = process.env.REACT_APP_RISK_API_CNAME
const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
const BRAZE_SDK_ENDPOINT = process.env.REACT_APP_BRAZE_SDK_ENDPOINT
const CONTENTFUL_SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID
const DEPLOY_ENV = process.env.REACT_APP_DEPLOY_ENV
const SOCURE_KEY = process.env.REACT_APP_SOCURE_KEY
const SEGMENT_API_KEY = process.env.REACT_APP_SEGMENT_API_KEY
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
const LAUNCH_DARKLY_CLIENT_SIDE_ID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
const BRAZE_API_KEY = process.env.REACT_APP_BRAZE_API_KEY
const CONTENTFUL_DELIVERY_TOKEN = process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN
const TEST_JWT_TOKEN = process.env.REACT_APP_TEST_JWT_TOKEN

switch (process.env.REACT_APP_DEPLOY_ENV) {
  case 'production':
    REST_API_BASE_URL = process.env.REACT_APP_REST_API_BASE_URL_PROD
    GRAPHQL_BASE_URL = process.env.REACT_APP_GRAPHQL_API_BASE_URL_PROD
    CLIENT_BASE_URL = process.env.REACT_APP_REST_API_BASE_URL_PROD
    IGNORE_IDLE_USER = false
    SHOULD_SUBMIT_RECAPTCHA = true
    PLAID_OAUTH_REDIRECT_URL = CLIENT_BASE_URL
    TABAPAY_3DS_SONGBIRD_URL = process.env.REACT_APP_TABAPAY_3DS_SONGBIRD_URL_PROD
    CONTENTFUL_ENVIRONMENT = 'master'
    break
  case 'uat':
    REST_API_BASE_URL = process.env.REACT_APP_REST_API_BASE_URL_UAT
    GRAPHQL_BASE_URL = process.env.REACT_APP_GRAPHQL_API_BASE_URL_UAT
    CLIENT_BASE_URL = process.env.REACT_APP_REST_API_BASE_URL_UAT
    SHOULD_SUBMIT_RECAPTCHA = true
    PLAID_OAUTH_REDIRECT_URL = CLIENT_BASE_URL
    TABAPAY_3DS_SONGBIRD_URL = process.env.REACT_APP_TABAPAY_3DS_SONGBIRD_URL_DEV
    CONTENTFUL_ENVIRONMENT = 'UAT'
    break
  case 'dev':
  default:
    REST_API_BASE_URL = process.env.REACT_APP_REST_API_BASE_URL_DEV
    GRAPHQL_BASE_URL = process.env.REACT_APP_GRAPHQL_API_BASE_URL_DEV
    SHOULD_SUBMIT_RECAPTCHA = false
    TABAPAY_3DS_SONGBIRD_URL = process.env.REACT_APP_TABAPAY_3DS_SONGBIRD_URL_DEV
    CONTENTFUL_ENVIRONMENT = DEPLOY_ENV

    if (process.env.REACT_APP_DEPLOY_ENV === 'dev') {
      CLIENT_BASE_URL = process.env.REACT_APP_REST_API_BASE_URL_DEV
      PLAID_OAUTH_REDIRECT_URL = process.env.REACT_APP_PLAID_OAUTH_REDIRECT_URL_DEV
    } else {
      CLIENT_BASE_URL = window.location.origin
      PLAID_OAUTH_REDIRECT_URL = CLIENT_BASE_URL
    }
    break
}

export {
  REST_API_BASE_URL,
  GRAPHQL_BASE_URL,
  GOOGLE_API_KEY,
  SOCURE_KEY,
  RISK_API_CNAME,
  SEGMENT_API_KEY,
  CLIENT_BASE_URL,
  IGNORE_IDLE_USER,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  LAUNCH_DARKLY_CLIENT_SIDE_ID,
  RECAPTCHA_SITE_KEY,
  SHOULD_SUBMIT_RECAPTCHA,
  PLAID_OAUTH_REDIRECT_URL,
  TABAPAY_3DS_SONGBIRD_URL,
  DEPLOY_ENV,
  BRAZE_SDK_ENDPOINT,
  BRAZE_API_KEY,
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_DELIVERY_TOKEN,
  CONTENTFUL_ENVIRONMENT,
  TEST_JWT_TOKEN,
}

export const PRODUCT_TYPES = {
  basic: 'BASIC_DDA',
  premium: 'PREMIUM_DDA',
  savings: 'SAVINGS',
  premiumPlus: 'PREMIUM_PLUS_DDA',
  elevateCard: 'ELEVATE_CARD_DDA',
}

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

export const US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const CHECKPOINTS = {
  NAME: 'NAME',
  ADDRESS: 'ADDRESS',
  EMAIL: 'EMAIL',
  EMAIL_VERIFIED: 'EMAIL_VERIFIED',
  PHONE: 'PHONE',
  PHONE_VERIFIED: 'PHONE_VERIFIED',
  TAX_NUMBER: 'TAX_NUMBER',
  BIRTH_DATE: 'BIRTH_DATE',
}

// List of user onboarding checkpoints
export const ONBOARDING_ROUTES = {
  // Checkpoint onboarding routes
  EMAIL: { key: CHECKPOINTS.EMAIL, route: staticRoutes.signUpEmail.pathname, isCheckpoint: true },
  EMAIL_VERIFIED: {
    key: CHECKPOINTS.EMAIL_VERIFIED,
    route: staticRoutes.verifyEmail.pathname,
    isCheckpoint: true,
  },
  PHONE: { key: CHECKPOINTS.PHONE, route: staticRoutes.providePhone.pathname, isCheckpoint: true },
  PHONE_VERIFIED: { key: CHECKPOINTS.PHONE_VERIFIED, route: staticRoutes.verifyPhone.pathname, isCheckpoint: true },
  NAME: { key: CHECKPOINTS.NAME, route: staticRoutes.createAccountNameAddress.pathname, isCheckpoint: true },
  ADDRESS: {
    key: CHECKPOINTS.ADDRESS,
    route: staticRoutes.createAccountNameAddress.pathname,
    isCheckpoint: true,
  },
  BIRTH_DATE: {
    key: CHECKPOINTS.BIRTH_DATE,
    route: staticRoutes.createAccountFinalDetails.pathname,
    isCheckpoint: true,
  },
  TAX_NUMBER: {
    key: CHECKPOINTS.TAX_NUMBER,
    route: staticRoutes.createAccountFinalDetails.pathname,
    isCheckpoint: true,
  },
  /* This is present in the API but is not implemented anywhere yet. It is here
     for visibility only */
  // ANTI_MONEY_LAUNDERING: 'ANTI_MONEY_LAUNDERING',

  /* These are mobile only and not implemented for the web. They are here for
     visibility only */
  // PHOTO_ID: 'PHOTO_ID',
  // PHOTO_ID_ACCEPTED: 'PHOTO_ID_ACCEPTED',

  // Non-checkpoint onboarding routes
  CREATE_ACCOUNT: { key: 'CREATE_ACCOUNT', route: staticRoutes.createAccount.pathname, isCheckpoint: false },
  AGREEMENTS: {
    key: 'AGREEMENTS',
    route: staticRoutes.createAccountReviewConfirm.pathname,
    isCheckpoint: false,
  },
  ADDRESS_RESUBMISSION: {
    key: 'ADDRESS_RESUBMISSION',
    route: staticRoutes.addressResubmission.pathname,
    isCheckpoint: false,
  },
  ADDRESS_RESUBMISSION_ENTRY: {
    key: 'ADDRESS_RESUBMISSION_ENTRY',
    route: staticRoutes.addressResubmissionEntry.pathname,
    isCheckpoint: false,
  },
  ADDRESS_RESUBMISSION_REVIEW: {
    key: 'ADDRESS_RESUBMISSION_REVIEW',
    route: staticRoutes.addressResubmissionReview.pathname,
    isCheckpoint: false,
  },
}

export const CTA_TYPES = {
  OPEN_SAVINGS_ACCOUNT: 'OPEN_SAVINGS_ACCOUNT',
  UPGRADE_TO_PREMIUM_ACCOUNT: 'UPGRADE_TO_PREMIUM_ACCOUNT',
  FUND_ACCOUNT: 'FUND_ACCOUNT',
  FUND_FOR_PREMIUM: 'FUND_FOR_PREMIUM',
  SELECT_DDA_TYPE: 'SELECT_DDA_TYPE',
  UPGRADE_DDA_ACCOUNT: 'UPGRADE_DDA_ACCOUNT',
}

export const POST_ONBOARDING_ROUTES = {
  DASHBOARD: { key: 'DASHBOARD', route: staticRoutes.dashboard.pathname },
  REVIEW: { key: 'REVIEW', route: staticRoutes.createAccountReviewConfirm.pathname },
  APPROVED: { key: 'APPROVED', route: staticRoutes.selectAccountTier.pathname },
  IN_REVIEW: { key: 'IN_REVIEW', route: staticRoutes.createAccountInReview.pathname },
  SUBMITTED: { key: 'SUBMITTED', route: staticRoutes.createAccountProcessing.pathname },
  HELP_SUPPORT: { key: 'HELP_SUPPORT', route: staticRoutes.settingsHelp },
  SETTINGS: { key: 'SETTINGS', route: staticRoutes.settings.pathname },
  AGREEMENTS_DISCLOSURES: {
    key: 'AGREEMENTS_DISCLOSURES',
    route: staticRoutes.settingsAgreementsDisclosures,
  },
  PRIVACY_SECURITY: { key: 'PRIVACY_SECURITY', route: staticRoutes.settingsPrivacySecurity.pathname },
  DOCUMENT_SUBMIT: { key: 'DOCUMENT_SUBMIT', route: staticRoutes.documentSubmit.pathname },
  MEMBERSHIP: { key: 'MEMBERSHIP', route: staticRoutes.settingsMembership.pathname },
  MEMBERSHIP_DETAILS: { key: 'MEMBERSHIP_DETAILS', route: staticRoutes.settingsMembershipDetails.pathname },
  MEMBERSHIP_CHANGE_MY_MEMBERSHIP: {
    key: 'MEMBERSHIP_CHANGE_MY_MEMBERSHIP',
    route: staticRoutes.settingsMembershipChangeMyMembership.pathname,
  },
  MEMBERSHIP_PAYMENT_METHOD: {
    key: 'MEMBERSHIP_PAYMENT_METHOD',
    route: staticRoutes.settingsMembershipPaymentMethod.pathname,
  },
  ACCOUNT_MEMBERSHIP: { key: 'ACCOUNT_MEMBERSHIP', route: staticRoutes.settingsAccount.pathname },
  VERIFICATION_CODES: {
    key: 'VERIFICATION_CODES',
    route: staticRoutes.settingsPrivacySecurityVerificationCodes.pathname,
  },
  NOTIFICATIONS: {
    key: 'NOTIFICATIONS',
    route: staticRoutes.settingsNotifications.pathname,
  },
  VERIFY_PASSWORD_FOR_EDIT: { key: 'VERIFY_PASSWORD_FOR_EDIT', route: staticRoutes.verifyPassword.pathname },
  INVEST: { key: 'INVEST', route: staticRoutes.invest.pathname },
}

export const CARD_AVAILABLE_OPERATIONS = {
  SET_PIN: 'SET_PIN',
  RESET_PIN: 'RESET_PIN',
  ACTIVATE_CARD_TOGGLE: 'ACTIVATE_CARD_TOGGLE',
  ACTIVATE_CARD_CVV: 'ACTIVATE_CARD_CVV',
  BLOCK: 'BLOCK',
  UNBLOCK: 'UNBLOCK',
  REPORT_CARD: 'REPORT_CARD',
}

export const CARD_STATES = {
  NO_CARD: 'NO_CARD',
  VIRTUAL_ONLY: 'VIRTUAL_ONLY',
  PHYSICAL_CARD_EMBOSSED: 'PHYSICAL_CARD_EMBOSSED',
  PHYSICAL_CARD_ACTIVATED: 'PHYSICAL_CARD_ACTIVATED',
}

export const CARD_UPDATE_STATUSES = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  LOST: 'LOST',
  DAMAGED: 'DAMAGED',
  STOLEN: 'STOLEN',
  NOT_RECEIVED: 'NOT_RECEIVED',
  CANCEL: 'CANCEL',
}

const internationalization = Intl.DateTimeFormat().resolvedOptions()

export const API_HEADERS = {
  'accept-language': internationalization.locale,
  'bundle-id': 'WEB',
  platform: 'web',
  version: 1,
  'timezone-id': internationalization.timeZone,
}

export const CUSTOMER_NAME_VALIDATION_REGEX = /^[ a-zA-Z'-]*$/
export const CUSTOMER_NAME_MAX_LENGTH = 40
export const STREET_ADDRESS_VALIDATION_REGEX = /^(?!\sa\sp\s*[o|0]\b)(?!p\s*[o|0]\b)(?!\sa\sp\s*[o|0]\sb\b)(?!\bgp[o|0]b[o|0]x\b)(?!\bp[o|0]st [o|0]ffice\b)(?!\bcall b[o|0]x\b)(?!\bb[o|0]x\s[0-9]+\b)[^.]*$/gim
export const ADDRESS_BUILDING_NUMBER_MAX_LENGTH = 30

export const PASSWORD_VALIDATION_REGEX = {
  UPPERCASE: /.*[A-Z].*/,
  LOWERCASE: /.*[a-z].*/,
  NUMBER: /.*[\d].*/,
  SPECIAL_CHAR: /.*[@$!%*?&#].*/,
}

export const PLAID_LINKING_STATUSES = {
  VERIFIED: 'VERIFIED',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  PENDING_MANUAL_VERIFICATION: 'PENDING_MANUAL_VERIFICATION',
  BROKEN: 'BROKEN',
  ERROR: 'ERROR',
}

export const PLAID_LINKING_CTATYPES = {
  VERIFY_MICRO_DEPOSITS: 'VERIFY_MICRO_DEPOSITS',
  FIX_LINK: 'FIX_LINK',
  REMOVE: 'REMOVE',
}

export const GIVING_STATES = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  RECENT: 'RECENT',
}

export const MAX_TRANSFER_NOTE_CHAR_COUNT = 30

export const FUNDING_FLOWS = {
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
  ONBOARDING_PREMIUM: 'ONBOARDING_PREMIUM',
  TRANSFER: 'TRANSFER',
  DEBIT_CARD_DEPOSIT: 'DEBIT_CARD_DEPOSIT',
  DIRECT_DEPOSIT: 'DIRECT_DEPOSIT',
  ONBOARDING_ELEVATED_CARD: 'ONBOARDING_ELEVATED_CARD',
  UPGRADE_MEMBERSHIP: 'UPGRADE_MEMBERSHIP',
}

export const TRANSFER_STATUS_TYPES = {
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  COMPLETE: 'COMPLETE',
}

export const TRANSFER_ACCOUNT_TYPES = {
  GREENWOOD: 'GREENWOOD',
  LINKED: 'LINKED',
}

export const COASTAL_BANKING_SERVICES =
  'Banking services provided by Coastal Community Bank, Member FDIC.'

export const BANK_DISCLAIMER = `Greenwood is a financial technology company, not a bank. ${COASTAL_BANKING_SERVICES} Deposits are insured up to $250,000 per depositor. The Greenwood Mastercard\u00AE Debit Card is issued by Coastal Community Bank pursuant to a license from Mastercard International and may be used everywhere Mastercard debit cards are accepted.`

export const ATOMIC_CONFIG = {
  theme: {
    brandColor: '#1f9081', // green-500
    overlayColor: '#0000004A', // Match our other modal backdrops
  },
  deeplink: {
    step: 'search-company',
  },
}

export const ACCOUNT_FUNDING_REQUEST_PURPOSES = {
  PREMIUM_FEE: 'PREMIUM_FEE',
  FUNDS_IN_DDA: 'FUNDS_IN_DDA',
}

export const FUNDING_STATES = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  REQUIRED: 'REQUIRED',
  PROCESSING: 'PROCESSING',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
}

export const INTERNAL_EXTERNAL_TRANSFER_LIMIT = 5000

export const LOTTIE_CONFIG = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet',
  },
}

export const PLAID_OAUTH_REDIRECT_SESSION_VARIABLES = {
  FUNDING_FLOW: 'fundingFlow',
  PLAID_LINK_TOKEN: 'plaidLinkToken',
}

export const MANUAL_ADDRESS_VALIDATION_SCHEMA = {
  deliveryAddress: Yup.string()
    .required('Street Address is required')
    .min(2, 'Street Address must include at least 2 characters.')
    .matches(STREET_ADDRESS_VALIDATION_REGEX, 'Street Address has an invalid format.'),
  locality: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string()
    .required('Zip Code is required')
    .length(5, 'Zip Code must be exactly 5 digits')
    .matches(/^\d*$/gi, 'Zip Code can only be a number'),
}

export const NAME_ADDRESS_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .matches(CUSTOMER_NAME_VALIDATION_REGEX, 'First name contains invalid characters.'),
  lastName: Yup.string()
    .required('Required')
    .matches(CUSTOMER_NAME_VALIDATION_REGEX, 'Last name contains invalid characters.')
    .min(2, 'Last name must include at least 2 characters.'),
  ...MANUAL_ADDRESS_VALIDATION_SCHEMA,
})

export const CVV_VALIDATION_SCHEMA = {
  cvv: Yup.string()
    .required('Required')
    .test('cvv length', 'CVV must be 3 or 4 digits', value => {
      return [3, 4].includes(value?.length)
    }),
}

export const ADD_DEBIT_CARD_FLOW_TYPES = {
  FROM_DEPOSIT_FORM: 'FROM_DEPOSIT_FORM',
  BEFORE_DEPOSIT: 'BEFORE_DEPOSIT',
  BEFORE_FUNDING: 'BEFORE_FUNDING',
}

export const DEBIT_CARD_DEPOSIT_STATUS_TYPES = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
}

export const DEBIT_CARD_DEPOSIT_3DS_SESSION_TYPES = {
  DEVICE_CHECK: 'DEVICE_CHECK',
  CHALLENGE: 'CHALLENGE',
}

export const KNOWN_NESTED_400_ERRORS = [
  'firstName',
  'lastName',
  'birthDate',
  'taxNumber',
  'deliveryAddress',
  'locality',
]

export const DEPOSIT_AND_TRANSFER_DISCLAIMER_TEXT =
  'By selecting Confirm, you authorize Greenwood or its agents to debit or credit your linked card(s) subject to the Electronic Funds Transfer Terms and Conditions. You also understand that once processed, this transaction cannot be canceled or refunded.'

export const MFA_CHANNELS = {
  NONE: 'NONE',
  SMS: 'SMS',
  EMAIL: 'EMAIL',
}

export const MEMBERSHIP_CARD_TYPES = {
  [PRODUCT_TYPES.basic]: 'Standard',
  [PRODUCT_TYPES.premium]: 'Premium',
  [PRODUCT_TYPES.premiumPlus]: 'Premium',
  [PRODUCT_TYPES.elevateCard]: 'Elevate',
}

export const STRIPE_PAYMENT_STATUSES = {
  SUCCEEDED: 'succeeded',
  PROCESSING: 'processing',
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
}

export const ONBOARDING_FLOW_TYPES = {
  LEGACY: 'LEGACY',
  ELEVATE: 'ELEVATE',
  ELEVATE_ONLY: 'ELEVATE_ONLY',
  ELEVATE_AND_PREMIUM_ONLY: 'ELEVATE_AND_PREMIUM_ONLY',
  ELEVATE_ONLY_TGS: 'ELEVATE_ONLY_TGS',
  PREMIUM_ONLY: 'PREMIUM_ONLY',
}

export const ALL_MEMBERSHIP_OPTIONS_ONBOARDING_FLOW_TYPES = []

export const PRODUCT_DETAILS = {
  [PRODUCT_TYPES.basic]: {
    cardHeader: MEMBERSHIP_CARD_TYPES[PRODUCT_TYPES.basic],
    cardImage: MyGreenwoodCard,
  },
  [PRODUCT_TYPES.elevateCard]: {
    cardHeader: MEMBERSHIP_CARD_TYPES[PRODUCT_TYPES.elevateCard],
    cardImage: ElevateCard,
    cardSubText: 'Your Elevate Card',
  },
  [PRODUCT_TYPES.premiumPlus]: {
    cardHeader: MEMBERSHIP_CARD_TYPES[PRODUCT_TYPES.premiumPlus],
    cardImage: PlatinumCard,
    cardSubText: 'Your Premium Card',
  },
}

export const getStripeDisclaimerText = buttonText =>
  `Greenwood uses Stripe to process payments in accordance with the Account Terms and Conditions.  By clicking ${buttonText}, you authorize Greenwood to debit or credit your card.`

export const HOME_ROUTES = [
  staticRoutes.dashboard.pathname,
  staticRoutes.splash.pathname,
  staticRoutes.elevate.pathname,
  staticRoutes.elevateOnly.pathname,
  staticRoutes.elevatePremiumOnly.pathname,
  staticRoutes.premiumOnly.pathname,
]

export const SIGN_UP_ROUTES = [
  staticRoutes.signUp.pathname,
  staticRoutes.elevateSignUp.pathname,
  staticRoutes.elevateOnlySignUp.pathname,
  staticRoutes.elevatePremiumOnlySignUp.pathname,
  staticRoutes.premiumOnlySignUp.pathname,
]

export const INVEST_ROUTES = [
  staticRoutes.invest.pathname,
  staticRoutes.investDiscovery.pathname,
  staticRoutes.investPortfolio.pathname,
]

export const ONBOARDING_FLOW_TYPE_BY_ROUTE = {
  [staticRoutes.elevateOnly.pathname]: ONBOARDING_FLOW_TYPES.ELEVATE_ONLY,
  [staticRoutes.elevatePremiumOnly.pathname]: ONBOARDING_FLOW_TYPES.ELEVATE_AND_PREMIUM_ONLY,
  [staticRoutes.premiumOnly.pathname]: ONBOARDING_FLOW_TYPES.PREMIUM_ONLY,
}

export const STRIPE_INTENT_TYPES = {
  PAYMENT: 'PAYMENT_INTENT',
  SETUP: 'SETUP_INTENT',
}

export const VERIFICATION_CODE_MAX_ATTEMPTS = 3

export const SOCURE_DOCV_STATUSES = {
  VERIFICATION_COMPLETE: 'VERIFICATION_COMPLETE',
  VERIFICATION_ERROR: 'VERIFICATION_ERROR',
}

export const NON_BANKING_PRODUCTS = {
  ELEVATE_ONLY: 'ELEVATE_ONLY',
}

export const NON_BANKING_PRODUCT_TERMS = {
  SEMIYEARLY: 'SEMIYEARLY',
  YEARLY: 'YEARLY',
  MONTHLY: 'MONTHLY',
}

export const ACCOUNT_OPENING_DISCLAIMER_TEXT =
  'PROCEDURES FOR OPENING A NEW ACCOUNT: To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver’s license or other identifying documents.'

export const NOTIFICATION_UPDATE_TYPES = {
  ENABLE_SMS_CHANNEL: 'enableSmsChannel',
  ENABLE_PUSH_CHANNEL: 'enablePushChannel',
  ENABLE_EMAIL_CHANNEL: 'enableEmailChannel',
  ENABLE_BALANCE_NOTIFICATIONS: 'enableBalanceNotifications',
  ENABLE_TRANSACTIONAL_NOTIFICATIONS: 'enableTransactionalNotifications',
}

export const EDIT_FIELD_TYPES = {
  EMAIL: 'email',
  PHONE: 'phone',
}

export const INVEST_PROMOTIONS = {
  INVEST_STOCK_PROMO: 'INVEST_STOCK_PROMO',
}

export const FRAUD_ERROR_MESSAGE = 'your account has been temporarily restricted due to unusual activity'

export const DASHBOARD_ACCOUNT_CARD_TYPES = {
  SPENDING: 'spending',
  SAVINGS: 'savings',
  CARD: 'physical-card',
  FUND: 'add-funds',
}
