import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'
import classNames from 'classnames'

import AccountCard from '../accountCard/AccountCard'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { gql, useQuery } from '@services/serviceUtils'
import { getBalance } from '@common/utils/appUtils'
import { CARD_AVAILABLE_OPERATIONS, CARD_STATES, CTA_TYPES, DASHBOARD_ACCOUNT_CARD_TYPES } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import {
  trackEvent,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'

import styling from './accountCardsList.module.scss'

const totalBalanceQuery = gql`
  query Accounts {
    accounts {
      totalBalance {
        amount
      }
    }
  }
`

const AccountCardsList = ({
  ctas,
  cardDetails,
  openModal,
  accounts,
  setSelectedAccount,
  hasError = false,
}) => {
  const navigate = useNavigate()

  const [swiper, setSwiper] = useState(null)

  const containerRef = useRef(null)
  const cardRef = useRef(null)
  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)

  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const { loading, error, data } = useQuery(totalBalanceQuery, { fetchPolicy: 'no-cache' })

  const accountCards = useMemo(() => {
    const spendingAccount = accounts?.find(
      account => account?.title?.toLowerCase()?.includes('spending')
    )

    const savingsAccount = accounts?.find(
      account => account?.title?.toLowerCase()?.includes('savings')
    )

    const cards = [
      {
        type: DASHBOARD_ACCOUNT_CARD_TYPES.SPENDING,
        details: spendingAccount,
        onClick: () => {
          setSelectedAccount(spendingAccount?.id)
        },
      }, {
        type: DASHBOARD_ACCOUNT_CARD_TYPES.SAVINGS,
        isActivate: !!ctas?.find(cta => cta?.callToActionType === CTA_TYPES.OPEN_SAVINGS_ACCOUNT),
        details: savingsAccount || null,
        onClick: () => {
          if (ctas?.find(cta => cta?.callToActionType === CTA_TYPES.OPEN_SAVINGS_ACCOUNT)) {
            navigate(staticRoutes.createSavingsAccount.pathname)
            trackEvent({
              event: SEGMENT_EVENTS.DASHBOARD_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.OPEN_SAVINGS_ACCOUNT),
            })
          } else {
            setSelectedAccount(savingsAccount?.id)
          }
        },
      },
    ]

    if (ctas?.find(cta => cta?.callToActionType === CTA_TYPES.FUND_ACCOUNT)) {
      cards.unshift({
        type: DASHBOARD_ACCOUNT_CARD_TYPES.FUND,
        isActivate: true,
        onClick: () => openModal('add'),
      })
    }

    if (cardDetails?.cardState !== CARD_STATES.VIRTUAL_ONLY) {
      const canActivate = cardDetails?.availableOperations?.includes(
        CARD_AVAILABLE_OPERATIONS.ACTIVATE_CARD_TOGGLE
      )

      const physicalCardDetails = {
        type: DASHBOARD_ACCOUNT_CARD_TYPES.CARD,
        isActivate: canActivate,
        details: cardDetails,
        onClick: () => {
          if (canActivate) {
            openModal('activate')
          } else {
            navigate(staticRoutes.settingsMyCard.pathname)
          }
        },
      }

      if (canActivate) {
        cards.unshift(physicalCardDetails)
      } else {
        cards.push(physicalCardDetails)
      }
    }

    return cards
  }, [
    ctas,
    navigate,
    openModal,
    cardDetails,
    accounts,
    setSelectedAccount,
  ])

  const getAccountCardsSwiper = () => (
    <Swiper
      modules={[A11y, Navigation]}
      a11y
      className={styling.swiper}
      direction='horizontal'
      slidesPerView={(accountCards?.length - ((accountCards?.length - 1) / 2))}
      spaceBetween={8}
      onSwiper={setSwiper}
      navigation={{
        nextEl: nextButtonRef.current,
        prevEl: prevButtonRef.current,
      }}
    >
      {accountCards?.map((cardDetails, index) => (
        <SwiperSlide className={styling['swiper-slide']} key={`account-card-${index}`} ref={index === 0 ? cardRef : null}>
          <AccountCard {...cardDetails} />
        </SwiperSlide>
      ))}
    </Swiper>
  )

  return (
    <div className={styling['account-cards-container']} ref={containerRef}>
      {!isTablet && <h3 className='v2-header'>Accounts & Debit Card</h3>}
      {isTablet && (
        <LoadingContainer loading={loading} error={error} errorMessage='Error getting balance information'>
          <p className={styling['balance-header']}>Total Balance</p>
          <div className={classNames('v2-total-balance', styling['mobile-total-balance'])}>
            {getBalance(`${data?.accounts?.totalBalance?.amount || 0}`)}
          </div>
        </LoadingContainer>
      )}
      <LoadingContainer
        loading={!cardDetails || !accounts}
        error={hasError}
        errorMessage='Error getting account and/or card information'
      >
        <div className={styling['cards-container']}>
          <div className={styling.cards}>
            {getAccountCardsSwiper()}
          </div>
        </div>
        <div className={styling.controls}>
            <button
              className={styling['account-slider-button']}
              ref={prevButtonRef}
              onClick={() => {
                swiper?.slidePrev()
              }}
              disabled={swiper?.isBeginning}
            />
            <button
              className={styling['account-slider-button']}
              ref={nextButtonRef}
              onClick={() => {
                swiper?.slideNext()
              }}
              disabled={swiper?.isEnd}
            />
          </div>
      </LoadingContainer>
    </div>
  )
}

AccountCardsList.propTypes = {
  ctas: PropTypes.array,
  cardDetails: PropTypes.object,
  openModal: PropTypes.func,
  accounts: PropTypes.array,
  setSelectedAccount: PropTypes.func,
  hasError: PropTypes.bool,
}

export default AccountCardsList
