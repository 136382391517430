import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as CloseCircleIcon } from '@common/images/icons/v2/close-circle.svg'

import styling from './detailContainer.module.scss'

const DetailContainer = ({ label, children, handleCloseDetails }) => (
  <div className={styling['detail-container']}>
    <div className={styling['heading-container']}>
      {label}
      <CloseCircleIcon onClick={handleCloseDetails} />
    </div>
    {children}
    <GreenwoodLogo className={styling['detail-container-logo']} />
  </div>
)

DetailContainer.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  handleCloseDetails: PropTypes.func,
}

export default DetailContainer
