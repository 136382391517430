import React, { useContext, useEffect } from 'react'

import HelpSupportTable from './HelpSupportTable'

import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

const HelpSupport = () => {
  const { setPageDetails } = useContext(BankingContainerContext)

  useEffect(() => {
    setPageDetails({ title: 'Help & Support', showBackArrow: true })
    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails])

  return (
    <div className='settings-view-content'>
      <h2>How can we help you?</h2>
      <HelpSupportTable />
    </div>
  )
}

export default HelpSupport
