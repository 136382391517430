import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { staticRoutes } from '@routing/routes'

import styling from './createAccountWrapper.module.scss'

const CreateAccountWrapper = ({ children, className, header, paragraphText, greenwoodFact }) => {
  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const Header = () => (
    <div className={styling['header-container']}>
      <h1>{header}</h1>
      <p>{paragraphText}</p>
    </div>
  )

  return (
    <div className={classNames(styling['create-account-wrapper'], className)}>
      {isTablet ? <Header /> : null}
      <div className='onboarding-white-card-container'>
        {!isTablet ? (
          <>
            <Link to={staticRoutes.dashboard.pathname}>
              <GreenwoodLogo className='logo' />
            </Link>
            <Header />
          </>
        ) : null}
        {children}
      </div>
      <div className={styling['greenwood-fact-container']}>
        <h1>Greenwood Fact</h1>
        <hr />
        <h1>Did You Know?</h1>
        <p>{greenwoodFact}</p>
      </div>
    </div>
  )
}

CreateAccountWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  header: PropTypes.string,
  paragraphText: PropTypes.string,
  greenwoodFact: PropTypes.string,
}

export default CreateAccountWrapper
