import { sub } from 'date-fns'

import { parseDateString } from '@common/utils'

// Formik doesn't support passing options when using a validation schema.
// This workaround allows options to be passed. The critical part here is
// the abortEarly prop. Turning this off allows for all errors to be returned,
// not just the first one.
// Source: https://github.com/formium/formik/issues/1864#issuecomment-762514479
export const fullValidatorForSchema = schema => values =>
  schema
    .validate(values, {
      abortEarly: false,
      strict: false,
    })
    .then(() => ({}))
    .catch(({ inner }) =>
      inner.reduce(
        (memo, { path, message }) => ({
          ...memo,
          [path]: (memo[path] || []).concat(message),
        }),
        {}
      )
    )

export const getBirthdaySsnErrors = ({ values, reportUnderageEvent = () => {} }) => {
  const invalidDateText = 'Invalid Date'
  const todaysDate = new Date()
  const maxBirthDate = sub(todaysDate, { years: 18 })
  const birthdayValidationErrorMessage = 'You must be at least 18 years of age.'

  const { taxNumber, birthDate } = values

  const errors = {}

  if (!birthDate) {
    errors.birthDate = 'Required'
  } else {
    const dateString = parseDateString(birthDate)
    if (dateString.toString() === invalidDateText) {
      errors.birthDate = invalidDateText
    } else if (!(dateString < maxBirthDate)) {
      errors.birthDate = birthdayValidationErrorMessage
      reportUnderageEvent()
    }
  }

  if (!taxNumber) {
    errors.taxNumber = 'Required'
  } else {
    if (taxNumber?.replace(/-/g, '').length < 9) {
      errors.taxNumber = 'SSN must be 9 digits'
    }
  }

  return errors
}
