import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '@shared/components/button/Button'
import { staticRoutes } from '../../routing/routes'
import CelebrationView from '@common/components/celebrationView/CelebrationView'
import MyCardImage from 'src/views/myCard/MyCardImage'

const CardActivated = () => {
  const navigate = useNavigate()

  return (
    <CelebrationView className='card-inbound-container'>
      <h1>Your Card Is Activated</h1>
      <p>Your debit card is now ready for use.</p>
      <MyCardImage />
      <div className='buttons-container'>
        <Button onDark onClick={() => navigate(staticRoutes.settingsMyCard.pathname)}>
          Done
        </Button>
      </div>
    </CelebrationView>
  )
}

export default CardActivated
