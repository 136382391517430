import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as TickCircleIcon } from '@common/images/icons/v2/tick-circle.svg'

import { getBalance, getFormattedDate, getFormattedTime } from '@common/utils/appUtils'

import styling from './transactionDetails.module.scss'

const TransactionDetails = ({ transactionDetails }) => {
  return (
    <div className={styling['transaction-details-container']}>
      <div className={styling['status-container']}>
        <div className={styling['status-icon-container']}><TickCircleIcon /></div>
        <p>Transaction Success</p>
      </div>
      <div className={styling.details}>
        <table>
          <tbody>
            <tr>
              <td>status</td>
              <td className={styling.status}>completed</td>
            </tr>
            <tr>
              <td>date</td>
              <td>{getFormattedDate(new Date(transactionDetails?.transactionTime))}</td>
            </tr>
            <tr>
              <td>time</td>
              <td>{getFormattedTime(new Date(transactionDetails?.transactionTime))}</td>
            </tr>
            <tr>
              <td>description</td>
              <td>{transactionDetails?.description}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styling['amount-container']}>
        <p>Total transaction</p>
        <h2 className={styling.total}>{getBalance(transactionDetails?.amount?.amount)}</h2>
      </div>
    </div>
  )
}

TransactionDetails.propTypes = {
  transactionDetails: PropTypes.any,
}

export default TransactionDetails
