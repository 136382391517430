import React, { useContext, useEffect } from 'react'
import { Outlet, Navigate, Route, Routes } from 'react-router-dom'

import ProtectedRoute from '@routing/ProtectedRoute'
import CommunityInvest from './CommunityInvest'

import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import { staticRoutes } from '@routing/routes'

const Community = () => {
  const { setPageDetails } = useContext(BankingContainerContext)

  useEffect(() => {
    setPageDetails({ title: 'Community' })

    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails])

  return (
    <>
      <div className='page-content-container white-bg'>
        <Routes>
          <Route
            path={staticRoutes.communityInvest.relativePathname}
            element={<ProtectedRoute component={CommunityInvest} />}
          />
          <Route
            path='*'
            element={
              <Navigate to={staticRoutes.communityInvest.pathname} />
            }
          />
        </Routes>
        <Outlet />
      </div>
    </>
  )
}

export default Community
