import React, { useContext } from 'react'
import classNames from 'classnames'

import AppLinks from './AppLinks'

import { BankingContainerContext } from '../v2/bankingContainer/BankingContainer'

import styling from './downloadAppCallout.module.scss'

const DownloadAppCallout = () => {
  const { theme } = useContext(BankingContainerContext)

  return (
    <div className={classNames(styling.card, styling[theme])} size='small'>
      <h3>Download the Greenwood app</h3>
      <p>Use the app to find fee-free ATMs, get real-time alerts, view your rewards, and more.</p>
      <AppLinks alignMobileHorizontally />
    </div>
  )
}

export default DownloadAppCallout
