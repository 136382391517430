import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import VerificationFailedModal from '@common/components/verificationFailedModal/VerificationFailedModal'

import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import { gql, useQuery } from '@services/serviceUtils'
import { getDisplayName, getDisplayAddress, isCompleteCheckpoint } from '@common/utils/accountUtils'
import { staticRoutes } from '@routing/routes'
import { CHECKPOINTS, EDIT_FIELD_TYPES } from '@common/constants'
import { SEGMENT_PAGE_NAMES, SEGMENT_EVENTS, trackPage, trackEvent } from '@common/utils'

import styling from './accountMembership.module.scss'

const dataQuery = gql`
  query CheckpointValues {
    checkpoints {
      values
    }
  }
`

const AccountMembership = () => {
  const { loading, error, data } = useQuery(dataQuery)

  const { state = {}, pathname } = useLocation()
  const navigate = useNavigate()

  const { setPageDetails } = useContext(BankingContainerContext)

  const updateVerificationFailed = state?.updateVerificationFailed
  const customer = useSelector(state => state.customer)
  const firstName = customer?.firstName || ''
  const lastName = customer?.lastName || ''
  const emailAddress = customer?.emailAddress
  const phoneNumber = customer?.phoneNumber
  const buildingNumber = customer?.buildingNumber
  const deliveryAddress = customer?.deliveryAddress
  const locality = customer?.locality
  const subdivisions = customer?.subdivisions
  const postalCode = customer?.postalCode
  const applicationStatus = useSelector(state => state.application?.customerApplicationStatus)

  const [showVerificationFailedModal, toggleShowVerificationFailedModal] = useState(
    updateVerificationFailed
  )

  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.PERSONAL_INFORMATION })
  }, [])

  useEffect(() => {
    setPageDetails({ title: 'Personal Information', showBackArrow: true })

    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails])

  const { webEditAccountInfoMobile, webEditAccountInfoEmail } = useFlags()

  const tableHasEditableField = useMemo(
    () => applicationStatus === 'PASS' && (webEditAccountInfoEmail || webEditAccountInfoMobile),
    [webEditAccountInfoEmail, webEditAccountInfoMobile, applicationStatus]
  )

  const DataRow = ({ label, id, showVerified, data, canEdit, editFieldType }) => (
    <tr>
      <th>
        <span data-cy={`${id}-label`}>{label}</span>
        {showVerified && <span className='positive-status-label-display'>Verified</span>}
        <br />
        <p data-cy={`${id}-data`}>{data}</p>
      </th>
      <td>
        <span data-cy={`${id}-data`}>{data}</span>
      </td>
      {tableHasEditableField && (
        <td className={styling['edit-link']}>
          {canEdit && (
            <Link
              to={staticRoutes.verifyPassword.pathname}
              state={{ editFieldType }}
              data-cy={`edit-${editFieldType}-btn`}
            >
              Edit
            </Link>
          )}
        </td>
      )}
    </tr>
  )

  return (
    <div className={classNames('membership-view-content', styling['account-membership-container'])}>
      <p className='subtext'>
        *If you would like to update your account information, please{' '}
        <Link
          data-cy='contact-link'
          className='underlined-link bold'
          to={staticRoutes.settingsHelp.pathname}
          onClick={() => {
            trackEvent({
              event: SEGMENT_EVENTS.PERSONAL_INFO_CONTACT_US_BUTTON_CLICK,
            })
          }}
        >
          Contact Us
        </Link>{' '}
        for assistance.
      </p>
      <LoadingContainer
        loading={loading}
        error={error}
        errorMessage='Error loading account information.'
      >
        <table className='simple-table'>
          <tbody>
            <DataRow
              label='Name'
              id='name'
              data={getDisplayName({
                firstName,
                lastName,
              })}
            />
            <DataRow
              label='Address'
              id='address'
              data={getDisplayAddress({
                buildingNumber,
                deliveryAddress,
                locality,
                subdivisions,
                postalCode,
              })}
            />
            <DataRow
              label='Phone #'
              id='mobile-phone'
              data={phoneNumber}
              showVerified={isCompleteCheckpoint({
                checkpoints: data?.checkpoints,
                checkpoint: CHECKPOINTS.PHONE,
              })}
              canEdit={webEditAccountInfoMobile}
              editFieldType={EDIT_FIELD_TYPES.PHONE}
            />
            <DataRow
              label='Email'
              id='email'
              data={emailAddress}
              showVerified={isCompleteCheckpoint({
                checkpoints: data?.checkpoints,
                checkpoint: CHECKPOINTS.EMAIL,
              })}
              canEdit={webEditAccountInfoEmail}
              editFieldType={EDIT_FIELD_TYPES.EMAIL}
            />
          </tbody>
        </table>
      </LoadingContainer>
      <VerificationFailedModal
        isOpen={showVerificationFailedModal || false}
        toggleModal={() => toggleShowVerificationFailedModal(!showVerificationFailedModal)}
        closeModal={() => {
          toggleShowVerificationFailedModal(false)
          navigate(pathname, { state: { ...state, updateVerificationFailed: false }, replace: true })
        }}
        isUpdating
      />
    </div>
  )
}

export default AccountMembership
