import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import PinField from 'react-pin-field'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import FormCheckbox from '@shared/components/formCheckbox/FormCheckbox'
import Button from '@shared/components/button/Button'
import NeedHelpVerificationCodeModal from '@common/components/needHelpModal/NeedHelpVerificationCodeModal'

import { EDIT_FIELD_TYPES, MFA_CHANNELS, VERIFICATION_CODE_MAX_ATTEMPTS } from '@common/constants'
import { gql, useLazyQuery, useMutation, verify } from '@services/serviceUtils'
import {
  ALERT_TYPES,
  CHALLENGE_TYPES,
  DEFAULT_ALERT_DISMISS_DELAY,
  ROUTE_USER_FLOW,
} from '@shared/constants/uiConstants'
import { staticRoutes } from '@routing/routes'
import {
  getRedirectLocationByChallengeTypeAndUserFlow,
  SEGMENT_EVENTS,
  trackEvent,
  isInvalidSession,
  getApiErrorMessage,
  getMaskedEmailAddress,
} from '@common/utils'
import { setAuthAction } from '@redux/auth/authActions'
import { addAlertAction, removeAlertsAction } from '@redux/alerts/alertsActions'
import { setCustomerAction } from '@redux/customer/customerActions'

import styling from './submitVerificationCode.module.scss'

const updateEmailMutation = gql`
  mutation UpdateEmail($emailAddressInput: EmailAddressInput!) {
    updateEmail(emailAddressInput: $emailAddressInput) {
      responseMsg
    }
  }
`

const updatePhoneMutation = gql`
  mutation UpdatePhone($phoneNumberInput: PhoneNumberInput!) {
    updatePhone(phoneNumberInput: $phoneNumberInput) {
      responseMsg
    }
  }
`
const submitOTCMutation = gql`
  mutation SubmitOTC($oneTimeCodeInput: OneTimeCodeInput!) {
    submitOTC(oneTimeCodeInput: $oneTimeCodeInput)
  }
`

const customerQuery = gql`
  query Customer {
    customer {
      emailAddress
      phoneNumber
    }
  }
`

const SubmitVerificationCode = () => {
  const navigate = useNavigate()
  const { state = {} } = useLocation()
  const dispatch = useDispatch()
  const sessionId = useSelector(state => state.auth?.sessionId)

  const { isEditing = false, editFieldType, email, phone } = state || {}

  const [showNeedHelpModal, toggleShowNeedHelpModal] = useState(false)
  const pinRef = useRef(null)
  const [pin, setPin] = useState(null)
  const [isInvalid, setIsInvalid] = useState(false)
  const [validatedSuccessfully, setValidatedSuccessfully] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [attemptsRemaining, setAttemptsRemaining] = useState(VERIFICATION_CODE_MAX_ATTEMPTS)
  const [newCodeMessage, setNewCodeMessage] = useState('')

  const navigateToPersonalInformationWithError = () =>
    navigate({
      pathname: staticRoutes.settingsAccount.pathname,
      state: {
        updateVerificationFailed: true,
      },
    })

  const handleTooManyAttemptsError = error => {
    setNewCodeMessage('')
    const apiError = getApiErrorMessage(error)

    if (apiError.includes('too many attempts')) {
      dispatch(removeAlertsAction())
      navigateToPersonalInformationWithError()
    }
  }

  const [updateEmail] = useMutation(updateEmailMutation, {
    onError: error => {
      handleTooManyAttemptsError(error)
    },
  })

  const [updatePhone] = useMutation(updatePhoneMutation, {
    onError: error => {
      handleTooManyAttemptsError(error)
    },
  })

  const [submitOTC] = useMutation(submitOTCMutation, {
    onError: error => {
      dispatch(removeAlertsAction())
      const apiError = getApiErrorMessage(error)

      if (!apiError.includes('verification failed')) {
        setIsInvalid(true)
        if (apiError.includes('1 attempt')) {
          setAttemptsRemaining(1)
        }
      } else {
        navigateToPersonalInformationWithError()
      }
    },
  })

  const [getCustomer, { data: customerData }] = useLazyQuery(customerQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      const { __typename, ...customerDetails } = customerData?.customer
      dispatch(setCustomerAction(customerDetails))

      navigate(staticRoutes.settingsAccount.pathname)
      dispatch(
        addAlertAction({
          text: `You have successfully updated your ${verificationMethod}.`,
          type: ALERT_TYPES.SUCCESS,
          dismissible: false,
          autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
        })
      )
    },
  })

  const isPhoneVerification = useMemo(
    () => state?.mfaChannel === MFA_CHANNELS.SMS || editFieldType === EDIT_FIELD_TYPES.PHONE,
    [state?.mfaChannel, editFieldType]
  )

  const verificationType = useMemo(() => (isPhoneVerification ? 'A text' : 'An email'), [
    isPhoneVerification,
  ])

  const verificationMethod = useMemo(
    () => (isPhoneVerification ? 'mobile number' : 'email address'),
    [isPhoneVerification]
  )

  const methodDetails = useMemo(() => {
    if (isEditing) {
      return isPhoneVerification ? ` ******${phone?.slice(-4)}` : ` ${getMaskedEmailAddress(email)}`
    }
    return null
  }, [isEditing, isPhoneVerification, phone, email])

  const attemptsRemainingText = isEditing
    ? `1 attempt remaining to verify your ${verificationMethod}.`
    : `${attemptsRemaining} attempt remaining before your account is locked.`
  const subtext = `${verificationType} message with a verification code has been sent to your ${verificationMethod}${methodDetails ||
    ''}.`
  const errorSubtext =
    attemptsRemaining > 1
      ? 'Please verify your numbers match the code sent.'
      : attemptsRemainingText

  useEffect(() => {
    if (pinRef?.current) {
      pinRef.current.forEach(input => (input.inputMode = 'numeric'))
      pinRef.current[0].focus()
    }
  }, [pinRef])

  const showResentCodeMessage = message => {
    setIsInvalid(false)
    setNewCodeMessage(message)
    pinRef?.current?.forEach(input => (input.value = ''))
    pinRef?.current[0].focus()
  }

  const handleSubmitMfaVerificationCode = async values => {
    const { rememberMeToggle } = values

    setIsInvalid(false)
    setIsLoading(true)

    try {
      const response = await verify({
        sessionId,
        value: pin,
        onError: error => {
          if (isInvalidSession(error)) {
            dispatch(removeAlertsAction())
            navigate(staticRoutes.signIn.pathname, { state: { hasInvalidSession: true } })
          }
        },
      })
      const data = response?.data
      const userFlowFlag = ROUTE_USER_FLOW.SIGNIN

      const { accountLocked = false, mfaAttemptsRemaining = attemptsRemaining } = data

      setIsLoading(false)

      trackEvent({
        event: SEGMENT_EVENTS.verificationCodeSubmissionAttempt({
          rememberMeId: window.sessionStorage.getItem('mfaRememberMeId'),
          remembered: rememberMeToggle,
          success: !!data?.token,
        }),
      })

      if (!data?.token) {
        if (data?.challengeType === CHALLENGE_TYPES.VERIFY_MFA) {
          // if the user has failed to enter the correct verification code
          // set the isInvalid status and decrease their number of attempts remaining
          setAttemptsRemaining(mfaAttemptsRemaining)
          setIsInvalid(true)
        } else if (accountLocked) {
          // if the user has been locked out of their account,
          // send the user back to the sign in screen in 'account is locked' state

          const { pathname: navigatePath, state: navigateState } = getRedirectLocationByChallengeTypeAndUserFlow({
            challengeType: data?.challengeType,
            userFlow: userFlowFlag,
            sessionId: data?.sessionId,
            accountIsLocked: true,
            desiredSigninPathname: staticRoutes.signIn.pathname,
          })

          navigate(navigatePath, { state: navigateState, replace: true })
        }
      } else {
        dispatch(setAuthAction(data))
        setValidatedSuccessfully(true)

        // save the remember_me_token in local storage if the user has turned on the 'Remember My Device' toggle
        const rememberMeTokenName = `rememberMeToken+${state?.displayEmail}`
        if (rememberMeToggle) {
          localStorage.setItem(rememberMeTokenName, data?.token?.remember_me_token)
        } else {
          localStorage.removeItem(rememberMeTokenName)
        }

        const { pathname: navigatePath, state: navigateState } = getRedirectLocationByChallengeTypeAndUserFlow({
          challengeType: data?.challengeType,
          userFlow: userFlowFlag,
          sessionId: data?.sessionId,
        })

        navigate(navigatePath, { state: navigateState, replace: true })
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleSubmit = async values => {
    if (!isEditing) {
      await handleSubmitMfaVerificationCode(values)
    } else {
      const editResponse = await submitOTC({
        variables: {
          oneTimeCodeInput: {
            code: pin,
          },
        },
      })

      if (editResponse) {
        getCustomer()
      }
    }
  }

  const handleResendCode = async () => {
    let updateResponse

    if (isPhoneVerification) {
      updateResponse = await updatePhone({
        variables: {
          phoneNumberInput: {
            value: phone,
          },
        },
      })
    } else {
      updateResponse = await updateEmail({
        variables: {
          emailAddressInput: {
            value: email,
          },
        },
      })
    }

    if (updateResponse) {
      showResentCodeMessage(`A new verification code has been sent to your ${verificationMethod}.`)
    }
  }

  return (
    <div className='white-card-container'>
      <GreenwoodLogo className='logo' />
      <h1 data-cy='submit-verification-code-header'>Enter your verification code</h1>
      <p>{subtext}</p>
      <Formik
        initialValues={{
          rememberMeToggle: true,
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={styling['submit-verification-code-form']}>
              <div
                className={classNames('pin-container', {
                  success: validatedSuccessfully === true,
                  error: isInvalid === true,
                })}
              >
                <PinField
                  ref={pinRef}
                  length={6}
                  validate={/^[0-9]$/}
                  className='pin-field'
                  onChange={value => {
                    setIsInvalid(false)
                    setPin(value)
                    setNewCodeMessage('')
                  }}
                  autoComplete='off'
                />
              </div>
              {isInvalid && (
                <p className='invalid-pin'>
                  Incorrect code
                  <p className='attempts-error-text'>{errorSubtext}</p>
                </p>
              )}
              <p className={classNames('code-resent', styling['new-code-message'])}>
                {newCodeMessage}
              </p>
              {!isEditing ? (
                <Field
                  as={FormCheckbox}
                  name='rememberMeToggle'
                  id='rememberMeToggle'
                  type='checkbox'
                  toggle
                  data-cy='submit-verification-code-remember-my-device-button'
                  leftLabel='Remember My Device'
                  className={styling['remember-me-toggle']}
                />
              ) : null}
              <Button
                type='submit'
                isLoading={isLoading || isSubmitting}
                disabled={!pin || pin.length < 6}
                data-cy='submit-verification-code-submit-button'
                className={styling['verification-code-button']}
              >
                Next
              </Button>
              {!isEditing ? (
                <>
                  <Button
                    type='button'
                    onClick={() => toggleShowNeedHelpModal(true)}
                    ghost
                    className='additional-button'
                    data-cy='submit-verification-need-help-button'
                  >
                    Need Help?
                  </Button>
                  <NeedHelpVerificationCodeModal
                    isOpen={showNeedHelpModal}
                    toggleModal={() => toggleShowNeedHelpModal(!showNeedHelpModal)}
                    closeModal={() => toggleShowNeedHelpModal(false)}
                    selectedVerificationChannel={state?.mfaChannel}
                    setShowNewCodeSentMessage={message => showResentCodeMessage(message)}
                  />
                </>
              ) : (
                <Button
                  type='button'
                  ghost
                  onClick={() => handleResendCode()}
                  className={classNames('additional-button', styling['verification-code-button'])}
                >
                  Resend Code
                </Button>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default SubmitVerificationCode
