import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import { ReactComponent as StatementIcon } from '@common/images/icons/v2/statement.svg'
import { ReactComponent as InfoCircleIcon } from '@common/images/icons/v2/info-circle.svg'

import TipCard from '../tipCard/TipCard'

import { getBalance } from '@common/utils/appUtils'
import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'

import styling from './accountDetailsTable.module.scss'

const AccountDetailsTable = ({ className, accountDetails, currentBalance, onTipButtonClick }) => {
  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const isSavings = useMemo(() => accountDetails?.title?.toLowerCase().includes('savings'), [accountDetails])

  const accountTableData = useMemo(() => {
    const {
      accountNumber,
      lastStatementDate: date,
      routingNumber,
    } = accountDetails || {}

    const lastStatementDate = new Date(date)?.toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
      day: 'numeric',
    })

    const hiddenAccountNumber = `${'\u2022'.repeat(4)} ${accountNumber.slice(-4)}`

    const data = [
      {
        title: 'Current Balance',
        details: getBalance(currentBalance || 0),
        handleTipButtonClick: () => onTipButtonClick('current balance'),
      }, {
        title: 'Account Number',
        details: hiddenAccountNumber,
      }, {
        title: 'Last Statement Date',
        details: lastStatementDate,
      },
    ]

    data.splice(2, 0, {
      title: isSavings ? 'Current APY' : 'Routing Number',
      details: isSavings ? '4.15%' : routingNumber,
      handleTipButtonClick: isSavings ? () => onTipButtonClick('apy') : null,
    })

    return data
  }, [accountDetails, currentBalance, isSavings, onTipButtonClick])

  return (
    <TipCard
      className={className}
      title='Account Details'
      showHorizontalRule={false}
      icon={<StatementIcon />}
    >
      <div className={styling['account-details-table-container']}>
        <table>
          <tbody>
            {accountTableData?.map((data, index) => (
              <tr
                key={`${data?.title}-${index}`}
                className={classNames({ [styling.apy]: data?.title?.toLowerCase().includes('apy') })}
              >
                <td>
                  {data?.title}
                  {data?.handleTipButtonClick && isTablet && <InfoCircleIcon onClick={data?.handleTipButtonClick} />}
                </td>
                <td>{data?.details || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </TipCard>
  )
}

AccountDetailsTable.propTypes = {
  className: PropTypes.string,
  accountDetails: PropTypes.any,
  currentBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onTipButtonClick: PropTypes.func,
}

export default AccountDetailsTable
