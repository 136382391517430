import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import Button from '@shared/components/button/Button'

import { useCheckpointNavigation, trackPage, SEGMENT_PAGE_NAMES } from '@common/utils'
import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { staticRoutes } from '@routing/routes'
import { CreateAccountContext } from '../CreateAccount'

import styling from './createAccountInReview.module.scss'

const CreateAccountInReview = () => {
  const pollingHasMaxedOut = useSelector(state => state?.application?.pollingHasMaxedOut)
  const navigate = useNavigate()

  const [showNextSteps, setShowNextSteps] = useState(true)

  const { data } = useContext(CreateAccountContext)

  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  useCheckpointNavigation({
    checkpoints: data?.checkpoints,
    applicationStatus: data?.application?.customerApplicationStatus,
    pollingHasMaxedOut,
  })

  // Track bank application pending review page visits
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.BANK_APPLICATION_IN_REVIEW })
  }, [])

  const getBarSelector = isSelected => (
    <div
      className={classNames(styling['bar-selector'], { [styling['is-selected']]: isSelected })}
      role='button'
      onClick={() => setShowNextSteps(!showNextSteps)}
    />
  )

  return (
      <div
        className={classNames('create-account-content-wrapper', styling['in-review-container'])}
        data-cy='authenticated-loaded-view'
      >
        {showNextSteps ? (
          <>
            <h3>Next Steps:</h3>
            <ol>
              <li>
                Your application review may take up to 10 business days. We appreciate your patience as we make sure everything is in order.
              </li>
              <li>If additional documents are needed, we'll contact you by email, so please keep an eye on your inbox.</li>
              <li>Visit your dashboard anytime to check the latest status on your application.</li>
            </ol>
          </>
        ) : (
          <>
            <h3>Greenwood Fact</h3>
            <hr />
            <h3>Did You Know?</h3>
            <p>In Greenwood, each dollar circulated within the community up to 36 times before leaving. Imagine what that could mean today for Black wealth and opportunity.</p>
          </>
        )}
        <div className='button-container'>
          {isTablet ? (
            <div className={styling['mobile-selector-container']}>
              {getBarSelector(showNextSteps === true)}
              {getBarSelector(showNextSteps === false)}
            </div>
          ) : null}
          <Button
            onClick={() => navigate(staticRoutes.dashboard.pathname)}
            data-cy='in-review-done-button'
          >
            Got It
          </Button>
        </div>
      </div>
  )
}

export default CreateAccountInReview
