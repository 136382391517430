import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import MaskedInput from 'react-text-mask'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { ReactComponent as Eye } from '@shared/images/icons/eye.svg'
import { ReactComponent as EyeClosed } from '@shared/images/icons/eye-closed.svg'

import Button from '@shared/components/button/Button'
import FormInput from '@shared/components/formInput/FormInput'
import ExternalLink from '@common/components/link/ExternalLink'
import ReduxField from '@common/components/reduxField/ReduxField'
import FormInputAddon from '@shared/components/formInput/FormInputAddon'

import { shortMonthDayYearToIso8601Date } from '@shared/utils'
import {
  BOOTSTRAP_SITEMAP_RESOURCES,
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  trackPage,
  trackEvent,
  useCheckpointNavigation,
  SEGMENT_SOURCE_DETAILS,
  getBootstrapSitemapResource,
} from '@common/utils'
import { removeAlertsAction } from '@redux/alerts/alertsActions'
import { getBirthdaySsnErrors } from '@common/utils/formUtils'
import { CreateAccountContext } from '../CreateAccount'

import styling from './createAccountFinalDetails.module.scss'

// birthday and ssn
const CreateAccountFinalDetails = () => {
  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)
  const [isUnderageEventTracked, setIsUnderageEventTracked] = useState(false)
  const [show, setShow] = useState(false)

  const dispatch = useDispatch()

  const { updateApplication, data } = useContext(CreateAccountContext)

  const { url: faqPrivacySecurityUrl } = getBootstrapSitemapResource(
    BOOTSTRAP_SITEMAP_RESOURCES.FAQ_PRIVACY_SECURITY
  )

  const { proceedToNextCheckpoint } = useCheckpointNavigation({
    checkpoints: data?.checkpoints,
    applicationStatus: data?.application?.customerApplicationStatus,
  })

  // Track birthday registration page visits
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_BIRTHDAY })
    trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_SOCIAL })
  }, [])

  // Checks if the underage error occurrs. If so, track the event
  const reportUnderageEvent = () => {
    if (!isUnderageEventTracked) {
      trackEvent({ event: SEGMENT_EVENTS.USER_DISQUALIFIED })
      setIsUnderageEventTracked(true)
    }
  }

  const handleValidation = values => {
    const errors = getBirthdaySsnErrors({ values, reportUnderageEvent })

    return errors
  }

  const handleSubmit = async (values, actions) => {
    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */
    dispatch(removeAlertsAction())

    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }

    trackEvent({
      event: SEGMENT_EVENTS.NEW_FINAL_DETAILS_BUTTON_CLICK,
    })

    const response = await updateApplication({
      birthDate: { value: shortMonthDayYearToIso8601Date(values?.birthDate) },
      taxNumber: { value: values.taxNumber.replaceAll('-', '') },
    })

    if (response) {
      proceedToNextCheckpoint()
    }

    actions.setSubmitting(false)
  }

  return (
    <div className='create-account-content-wrapper'>
      <Formik
        initialValues={{
          birthDate: '',
          taxNumber: '',
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validate={handleValidation}
        data-cy='authenticated-loaded-view'
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form data-cy='birthday-ssn-form' className={styling['create-account-final-details-form']}>
            <MaskedInput
              disabled={isSubmitting}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              onChange={handleChange}
              onFocus={() => {
                setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.BIRTHDAY)
              }}
              onBlur={params => {
                handleBlur(params)

                // Track any time the user leaves the birthday field during registration
                trackEvent({
                  event: SEGMENT_EVENTS.registrationFormFieldEntry({
                    sourceDetail: SEGMENT_SOURCE_DETAILS.BIRTHDAY,
                  }),
                })

                setFocusedInputSourceDetail(null)
              }}
              placeholder='Date of Birth (MM/DD/YYYY)'
              autoFocus
              guide={false}
              value={values.birthDate}
              data-cy='create-account-birthday-input'
              render={(ref, props) => (
                <ReduxField
                  as={FormInput}
                  name='birthDate'
                  type='text'
                  autoComplete='bday'
                  autoFocus
                  showRequiredAsterisk={false}
                  invalid={errors.birthDate && touched.birthDate}
                  errorText={errors.birthDate}
                  disabled={isSubmitting}
                  inputMode='numeric'
                  innerRef={ref}
                  {...props}
                  defaultValue={undefined}
                />
              )}
            />
            <MaskedInput
              disabled={isSubmitting}
              mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              onChange={handleChange}
              onFocus={() => {
                setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.SOCIAL)
              }}
              onBlur={params => {
                handleBlur(params)

                // Track any time the user leaves the birthday field during registration
                trackEvent({
                  event: SEGMENT_EVENTS.registrationFormFieldEntry({
                    sourceDetail: SEGMENT_SOURCE_DETAILS.SOCIAL,
                  }),
                })

                setFocusedInputSourceDetail(null)
              }}
              placeholder='SSN (XXX-XX-XXXX)'
              data-cy='create-account-ssn-input'
              value={values.taxNumber}
              guide={false}
              render={(ref, props) => (
                <ReduxField
                  as={FormInput}
                  name='taxNumber'
                  type={show ? 'text' : 'password'}
                  showRequiredAsterisk={false}
                  invalid={errors.taxNumber && touched.taxNumber}
                  errorText={errors.taxNumber}
                  disabled={isSubmitting}
                  inputMode='numeric'
                  innerRef={ref}
                  {...props}
                  defaultValue={undefined}
                >
                  <FormInputAddon type='trailing'>
                    <div
                      className={classNames({ clickable: !isSubmitting }, 'icon-container')}
                      onClick={() => !isSubmitting && setShow(!show)}
                      id='form-ssn-show-button'
                    >
                      {show ? <EyeClosed /> : <Eye />}
                    </div>
                  </FormInputAddon>
                </ReduxField>
              )}
            />
            <div className='button-container'>
              <ExternalLink className='underlined-link bold help-text' to={faqPrivacySecurityUrl}>
                Why does Greenwood need your social security?
              </ExternalLink>
              <Button type='submit' isLoading={isSubmitting}>Next</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateAccountFinalDetails
