import React, { useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ReactComponent as DirectDepositIcon } from '@common/images/icons/direct-deposit.svg'
import { ReactComponent as MoneyTransferIcon } from '@common/images/icons/money-transfer.svg'
import { ReactComponent as InstantDepositIcon } from '@common/images/icons/instant-deposit.svg'
import { ReactComponent as WireTransferIcon } from '@common/images/icons/wire-transfer.svg'

import LinksTable from '@common/components/link/LinksTable'
import TransferMoneyButton from './TransferMoneyButton'

import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import {
  trackEvent,
  trackPage,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'
import { FUNDING_FLOWS, ADD_DEBIT_CARD_FLOW_TYPES } from '@common/constants'
import { staticRoutes } from '@routing/routes'

import styling from './moveMoney.module.scss'

const AddMoney = () => {
  const navigate = useNavigate()

  const { setPageDetails } = useContext(BankingContainerContext)

  const { webDebitCardTabapay } = useFlags()

  const tableItems = useMemo(() => {
    const addMoneyOptions = [
      {
        heading: 'Direct Deposit',
        subtext: 'Available in 1-3 pay cycles',
        icon: <DirectDepositIcon />,
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.DIRECT_DEPOSIT),
          })

          navigate(staticRoutes.directDepositSelection.pathname, {
            state: {
              fundingFlow: FUNDING_FLOWS.DIRECT_DEPOSIT,
            },
          })
        },
        dataCy: 'move-money-add-money-direct-deposit-list-item',
      },
      {
        heading: 'Add Money from Other Banks',
        subtext: 'Available in 5-7 business days',
        icon: <MoneyTransferIcon />,
        renderContent: ({ children, className }) => {
          return <TransferMoneyButton className={className} displayText={children} ghost />
        },
        dataCy: 'move-money-add-money-transfer-list-item',
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(
              SEGMENT_SOURCE_DETAILS.ADD_MONEY_FROM_OTHER_BANKS
            ),
          })
        },
      },
      {
        heading: 'Wire Transfer',
        subtext: 'Available in 4 hours or less',
        icon: <WireTransferIcon />,
        dataCy: 'move-money-add-money-wire-transfer-list-item',
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.WIRE_TRANSFER),
          })

          navigate(staticRoutes.wireTransfer.pathname)
        },
      },
    ]
    if (webDebitCardTabapay) {
      addMoneyOptions.splice(1, 0, {
        heading: 'Instant Card Deposit',
        subtext: 'Funds available within minutes',
        icon: <InstantDepositIcon />,
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(
              SEGMENT_SOURCE_DETAILS.INSTANT_CARD_DEPOSITS
            ),
          })

          navigate(staticRoutes.debitCardDeposit.pathname, {
            state: {
              fundingFlow: FUNDING_FLOWS.DEBIT_CARD_DEPOSIT,
              addDebitCardFlow: ADD_DEBIT_CARD_FLOW_TYPES.BEFORE_DEPOSIT,
            },
          })
        },
        dataCy: 'move-money-add-money-instant-deposit-list-item',
      })
    }

    return addMoneyOptions
  }, [navigate, webDebitCardTabapay])

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.MOVE_MONEY_ADD_MONEY })
  }, [])

  useEffect(() => {
    setPageDetails({ title: 'Move Money/Add', showBackArrow: true })

    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails])

  return (
    <div className={classNames(styling['move-money'], styling['add-money'])}>
      <h2>Add Money</h2>
      <p>
        Add money to your Greenwood account using direct deposit, debit cards, or from external bank
        accounts.
      </p>
      <div className={styling['table-container']}>
        <LinksTable rowsData={tableItems} />
      </div>
    </div>
  )
}

export default AddMoney
