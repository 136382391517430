import React, { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import PinField from 'react-pin-field'
import { useNavigate, useLocation } from 'react-router-dom'
import { differenceInMinutes } from 'date-fns'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import Button from '@shared/components/button/Button'
import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'
import CreateAccountWrapper from '../createAccountWrapper/CreateAccountWrapper'

import { ROUTE_USER_FLOW, CHALLENGE_TYPES } from '@shared/constants/uiConstants'
import { staticRoutes } from '@routing/routes'
import { verify, resendVerifyChallenge } from '@services/serviceUtils'
import { setEmailAction } from '@redux/unauthenticatedUser/unauthenticatedUserActions'
import { setAuthAction, setSessionIdAction } from '@redux/auth/authActions'
import {
  getRedirectLocationByChallengeTypeAndUserFlow,
  isInvalidSession,
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  trackEvent,
  trackPage,
} from '@common/utils'
import useFinalizeAccountCreation from '@common/utils/useFinalizeAccountCreation'
import { removeAlertsAction } from '@redux/alerts/alertsActions'
import usePreventBrowserBackClick from '@common/utils/usePreventBrowserBackClick'

import styling from './verifyAccount.module.scss'

const emailText = 'your email'
const phoneText = 'your phone number'

const VerifyAccount = ({ type = 'email' }) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useDispatch()
  const sessionId = useSelector(state => state.auth?.sessionId)

  usePreventBrowserBackClick()

  const { userFlow, displayEmail, phoneNumber, waitlistCode, ...additionalState } = state || {}

  const pinRef = useRef(null)
  const [codeResent, setCodeResent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pin, setPin] = useState(null)
  const [isInvalid, setIsInvalid] = useState(false)
  const [validatedSuccessfully, setValidatedSuccessfully] = useState(false)
  const [isResendingCode, setIsResendingCode] = useState(false)
  const [attemptsRemaining, setAttemptsRemaining] = useState(null)
  const [showResendCodeLockedModal, toggleShowResendCodeLockedModal] = useState(false)
  const [waitMinutes, setWaitMinutes] = useState(null)

  const isResetPassword = useMemo(() => userFlow === ROUTE_USER_FLOW.RESET_PASSWORD, [userFlow])
  const isForgotPassword = useMemo(() => userFlow === ROUTE_USER_FLOW.FORGOT_PASSWORD, [userFlow])

  useEffect(() => {
    if (!isResetPassword && !isForgotPassword) {
      trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_VERIFICATION_CODE })
    }
  }, [isResetPassword, isForgotPassword])

  useEffect(() => {
    // If there is no sessionId present, redirect to the sign-up page instead
    if (!sessionId) {
      navigate(staticRoutes.signUpEmail.pathname, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, sessionId])

  // If there are no more validation attempts remaining, set isInvalid to false
  useEffect(() => {
    if (attemptsRemaining === 0) {
      setIsInvalid(false)
      const { pathname: navigatePath, state: navigateState } = getRedirectLocationByChallengeTypeAndUserFlow({
        challengeType: CHALLENGE_TYPES.NONE,
        userFlow: ROUTE_USER_FLOW.SIGNIN,
        sessionId,
        phoneVerificationFailed: true,
        desiredSigninPathname: staticRoutes.signIn.pathname,
      })

      navigate(navigatePath, { state: navigateState, replace: true })
    }
  }, [attemptsRemaining, navigate, sessionId, userFlow])

  const { updateAnalyticsAndApplicationStatus } = useFinalizeAccountCreation()

  const navigateFromInvalidSession = () => {
    navigate(staticRoutes.signIn.pathname, { state: { hasInvalidSession: true } })
  }

  const handleSubmit = async e => {
    if (e) {
      e.preventDefault()
    }
    setCodeResent(false)
    setIsLoading(true)

    if (!isResetPassword && !isForgotPassword) {
      trackEvent({
        event: SEGMENT_EVENTS.NEW_SUBMIT_CODE_BUTTON_CLICK,
      })
    }

    const response = await verify({
      sessionId,
      value: pin || state?.pin,
      onError: error => {
        setIsLoading(false)

        const isInvalidCode = error?.data?.errors?.code === 'invalid'

        if (isInvalidCode || isInvalidSession(error)) {
          dispatch(removeAlertsAction())
        }

        if (isInvalidCode) {
          setIsInvalid(true)
        } else {
          setIsInvalid(false)
        }

        if (error?.data?.errors?.remainingAttempts) {
          setAttemptsRemaining(parseInt(error.data.errors.remainingAttempts))
        }

        if (isInvalidSession(error)) {
          navigateFromInvalidSession()
        }
      },
    })
    const data = response?.data

    if (data) {
      setIsLoading(false)
      setValidatedSuccessfully(true)

      if (!isEmailVerification) {
        await dispatch(setAuthAction(data))

        const { pathname: redirectLocationPath, state: redirectLocationState } = getRedirectLocationByChallengeTypeAndUserFlow({
          challengeType: data?.challengeType,
          userFlow,
          sessionId,
          ...additionalState,
        })

        // Fetch the application data after signing up. Defer navigation
        // to after the data is fetched. Otherwise navigate immediately
        if (userFlow === ROUTE_USER_FLOW.SIGNUP) {
          updateAnalyticsAndApplicationStatus({
            data,
            displayEmail,
            waitlistCode,
            redirectTo: { pathname: redirectLocationPath, state: redirectLocationState },
          })
        } else {
          navigate(redirectLocationPath, { state: redirectLocationState, replace: true })
        }
      } else {
        // if the flow is not provided by the state, likely due to this being a new tab,
        // we can fall back to determining the flow based on the returned challenge type,
        // for which password workflows align with our constants
        const userFlowFlag = state?.userFlow || ROUTE_USER_FLOW[CHALLENGE_TYPES[data.challengeType]]

        const { pathname: redirectLocationPath, state: redirectLocationState } = getRedirectLocationByChallengeTypeAndUserFlow({
          challengeType: data?.challengeType,
          userFlow: userFlowFlag,
          sessionId: data?.sessionId,
        })

        navigate(redirectLocationPath, { state: redirectLocationState, replace: true })
      }
    }
  }

  // Automatically submit the PIN if it's prepopulated by the router
  useEffect(() => {
    if (state?.pin) {
      setPin(state?.pin)
      const digits = [...state?.pin]

      pinRef.current.forEach((input, index) => {
        input.value = digits[index] || null
      })

      if (displayEmail) {
        dispatch(setEmailAction({ email: displayEmail }))
      }

      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.pin])

  useEffect(() => {
    if (pinRef?.current) {
      pinRef.current.forEach(input => (input.inputMode = 'numeric'))
    }
  }, [pinRef])

  const handleResendCodeClick = async () => {
    setCodeResent(false)
    setIsInvalid(false)
    setIsResendingCode(true)

    const response = await resendVerifyChallenge({
      sessionId,
      onError: error => {
        setIsResendingCode(false)

        const lockedUntil = error?.data?.errors?.lockedUntil

        if (lockedUntil || isInvalidSession(error)) {
          dispatch(removeAlertsAction())
        }

        if (lockedUntil) {
          const lockedUntilDate = new Date(lockedUntil)
          const minutes = differenceInMinutes(lockedUntilDate, new Date())
          setWaitMinutes(minutes > 0 ? minutes : 1)
          toggleShowResendCodeLockedModal(true)
        }

        if (isInvalidSession(error)) {
          navigateFromInvalidSession()
        }
      },
    })

    const data = response?.data

    if (data) {
      dispatch(setSessionIdAction(data?.sessionId))
      setCodeResent(true)
      setIsResendingCode(false)
    }
  }

  const getVerificationForm = () => (
    <div className='create-account-content-wrapper'>
      <form data-cy='pin-form' onSubmit={handleSubmit} noValidate>
        <div
          className={classNames('pin-container', {
            success: validatedSuccessfully === true,
            error: isInvalid === true,
          })}
        >
          <PinField
            ref={pinRef}
            length={6}
            validate={/^[0-9]$/}
            className='pin-field'
            autoFocus
            onChange={value => {
              setIsInvalid(false)
              setPin(value)
              setCodeResent(false)
            }}
            autoComplete='off'
          />
        </div>
        {codeResent && <p className='code-resent'>A new verification code has been sent.</p>}
        {isInvalid && (
          <p className='invalid-pin'>
            Incorrect code
            <p className='attempts-error-text'>{errorSubtext}</p>
          </p>
        )}
        <div className='button-container'>
          <Button type='submit' isLoading={isLoading} disabled={!pin || pin.length < 6}>
            NEXT
          </Button>
          <Button
            className='additional-button'
            type='button'
            ghost={!isResetPassword && !isForgotPassword}
            outline={isResetPassword || isForgotPassword}
            disabled={isResendingCode}
            isLoading={isResendingCode}
            onClick={handleResendCodeClick}
          >
            Resend Code
          </Button>
          {isResetPassword && (
            <Button
              className='additional-button'
              type='button'
              ghost
              onClick={() => {
                dispatch(setEmailAction(null))
                navigate(staticRoutes.settingsPrivacySecurity.pathname, { replace: true })
              }}
            >
              Cancel
            </Button>
          )}
        </div>
      </form>
    </div>
  )

  const isEmailVerification = useMemo(() => type === 'email', [type])
  const verificationType = useMemo(() => isEmailVerification ? 'An email' : 'A text message', [isEmailVerification])

  const verificationChannelDetails = useMemo(() => {
    if (isEmailVerification) {
      return displayEmail ? `${emailText} ${displayEmail}` : emailText
    } else {
      return phoneNumber ? `${phoneText} ******${phoneNumber.slice(-4)}` : phoneText
    }
  }, [isEmailVerification, displayEmail, phoneNumber])

  const errorSubtext = useMemo(() => (
    `${attemptsRemaining} attempt remaining to verify ${isEmailVerification ? emailText : phoneText}.`
  ), [attemptsRemaining, isEmailVerification])

  return (
    <>
      {isResetPassword || isForgotPassword ? (
        <div className='white-card-container'>
          <GreenwoodLogo className='logo' />
          <h1 data-cy='verification-code-heading'>Enter your verification code</h1>
          <p>
            {verificationType} with a verification code has been sent to{' '}
            <b className={styling['verification-channel']}>{verificationChannelDetails}</b>.
          </p>
          {getVerificationForm()}
        </div>
      ) : (
        <CreateAccountWrapper
          className={styling.verify}
          header='Secure Your Account'
          paragraphText='Add an extra layer of security by choosing your preferred verification method. Your safety is our top priority.'
          greenwoodFact="Greenwood's economic success was so remarkable that the district included more than 15 doctors' offices, a hospital, and multiple law offices, all Black-owned, providing essential services and support to the community."
        >
          {getVerificationForm()}
        </CreateAccountWrapper>
      )}
      <Modal
        centered
        size='md'
        open={showResendCodeLockedModal}
        modalClassName='centered-content-modal'
        toggleModal={() => toggleShowResendCodeLockedModal(!showResendCodeLockedModal)}
      >
        <ModalHeader
          withCloseButton
          className='centered-content-modal-header'
          closeModal={() => toggleShowResendCodeLockedModal(false)}
        >
          <h4>Please Wait</h4>
        </ModalHeader>
        <ModalBody>
          <div>
            <p className='centered-content-modal-text'>{`Please wait ${waitMinutes} minute${
              waitMinutes > 1 ? 's' : ''
            } before requesting a new verification code.`}</p>
            <Button
              type='button'
              onClick={() => toggleShowResendCodeLockedModal(false)}
              className='additional-button'
            >
              Dismiss
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

VerifyAccount.propTypes = {
  /** Verification method type (either email or phone) */
  type: PropTypes.string,
}

export default VerifyAccount
