import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as StarIcon } from '@common/images/icons/v2/star.svg'

import styling from './ctaCard.module.scss'

const CtaCard = ({
  className,
  headerText = 'I am the header of this card',
  subText = '',
  buttonText = 'Get Started',
  onClick = () => {},
}) => {
  const cardClasses = classNames(styling.card, className)

  const getStarIcons = () => {
    const iconKeys = ['top-left', 'top-right', 'bottom-left', 'bottom-right']

    return iconKeys.map(key => (
      <StarIcon className={classNames(styling['star-icon'], styling[key])} key={key} />
    ))
  }

  return (
    <div className={cardClasses}>
      <h3>{headerText}</h3>
      <div className={styling['text-and-button-container']}>
        <p>{subText}</p>
        <button onClick={onClick}>{buttonText}</button>
      </div>
      {getStarIcons()}
    </div>
  )
}

CtaCard.propTypes = {
  className: PropTypes.string,
  headerText: PropTypes.string,
  subText: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
}

export default CtaCard
