import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as StatementIcon } from '@common/images/icons/v2/statement.svg'
import { ReactComponent as DownloadIcon } from '@common/images/icons/v2/cloud-plus.svg'

import Loading from '@shared/components/Loading'

import { gql, useLazyQuery } from '@services/serviceUtils'
import { openUrlInNewTab } from '@common/utils'
import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import styling from './statementRow.module.scss'

const statementUrlQuery = gql`
  query($statementId: ID!) {
    statement(statementId: $statementId) {
      url
    }
  }
`

const StatementRow = ({ statement, className, useTheme = true }) => {
  const { theme } = useContext(BankingContainerContext)

  const [getStatementUrl, { data: statementData, loading: statementLoading }] = useLazyQuery(
    statementUrlQuery,
    {
      fetchPolicy: 'no-cache',
    }
  )

  // Fetch the URL for this statement
  const handleClick = () => {
    getStatementUrl({ variables: { statementId: statement.id } })
  }

  const url = statementData?.statement?.url

  // When the URL arrives, open it in a new tab
  useEffect(() => {
    if (url) {
      openUrlInNewTab(url)
    }
  }, [url])

  return (
    <tr
      className={classNames(styling['statement-row'], className, useTheme && styling[theme])}
      role='button'
      onClick={handleClick}
      data-cy={`statement-list-item_${statement.id}`}
    >
      <th
        scope='row'
        className={classNames('link-row', styling['statement-row-header'], {
          disabled: statementLoading,
        })}
      >
        <div className={styling['statement-icon-container']}>
          <StatementIcon />
        </div>
        <span data-cy={`statement-list-item_${statement.id}`}>
          {statement.title}
        </span>
        <div className={styling['download-button-container']}>
          {statementLoading ? (
            <Loading className='row-loader' />
          ) : (
            <DownloadIcon />
          )}
        </div>
      </th>
    </tr>
  )
}

StatementRow.propTypes = {
  statement: PropTypes.any,
  className: PropTypes.string,
}

export default StatementRow
