import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { staticRoutes } from '@routing/routes'
import FlowError from 'src/views/flowError/FlowError'

import styling from '../directDeposit/directDepositFormSendFailed.module.scss'

const WireTransferFormSendFailed = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  return (
    <FlowError
      header='Wire Transfer Failed'
      onPrimaryButtonClick={() => {
        navigate(staticRoutes.wireTransfer.pathname, { state })
      }}
      onSecondaryButtonClick={() => navigate(staticRoutes.dashboard.pathname)}
      className={styling['direct-deposit-form-send-failed']}
    />
  )
}

export default WireTransferFormSendFailed
