import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import HelpSupport from './helpSupport/HelpSupport'
import AgreementsDisclosures from './agreementsDisclosures/AgreementsDisclosures'
import Notifications from './notifications/Notifications'
import Statements from './statements/Statements'
import LinkedAccounts from './linkedAccounts/LinkedAccounts'
import MyCard from '../myCard/MyCard'
import Membership from '../membership/Membership'
import AccountMembership from '../membership/account/AccountMembership'
import PrivacySecurityView from './privacySecurity/PrivacySecurityView'
import VerificationCodes from './privacySecurity/VerificationCodes'
import ProtectedRoute from '@routing/ProtectedRoute'
import Tabs from '@shared/components/tabs/Tabs'

import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import { useTabRouting } from '@common/utils'
import { getTabOptions } from './settingsUtils.js'
import { staticRoutes } from '@routing/routes'

import styling from './settings.module.scss'

const fullPageViews = [
  'Help & Support',
  'Statements',
  'Agreements & Disclosures',
  'Linked Accounts/Cards',
  'My Card',
  'Membership',
  'Personal Information',
]

// array of settings urls that have already been updated (for styling purposes)
// can be removed once all settings pages are updated
const updatedSettingsPages = [
  staticRoutes.settingsDocuments.pathname,
]

const Settings = () => {
  const [levelTwoActiveTab, setLevelTwoActiveTab] = useState()
  const location = useLocation()
  const navigate = useNavigate()
  const [tabOptions, setTabOptions] = useState(getTabOptions({}))
  const { webNotificationsOptOut } = useFlags()

  const applicationStatus = useSelector(state => state.application?.customerApplicationStatus)

  const { setPageDetails } = useContext(BankingContainerContext)

  useEffect(() => {
    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails])

  useEffect(() => {
    setTabOptions(getTabOptions({ applicationStatus, webNotificationsOptOut }))
  }, [applicationStatus, webNotificationsOptOut])

  const { activeTab } = useTabRouting({
    defaultActiveTab: tabOptions[0],
    location,
    tabOptions,
    tabsEnabled: true,
  })

  useEffect(() => {
    let levelTwoOption = null

    if (activeTab?.subTabs) {
      const { pathname, subTabs } = activeTab
      if (location.pathname === pathname) {
        levelTwoOption = {
          ...subTabs[0],
          pathname,
        }
      } else {
        const option = subTabs.find(
          o =>
            o.pathname.includes(location.pathname) || o.childPathnames?.includes(location.pathname)
        )
        levelTwoOption = {
          ...option,
          pathname: location.pathname,
        }
      }
    }

    delete levelTwoOption?.childPathnames

    setLevelTwoActiveTab(levelTwoOption)
  }, [activeTab, location])

  const isUpdatedPage = useMemo(() => updatedSettingsPages.includes(location?.pathname), [location?.pathname])

  const handleTabChange = tab => {
    if (tab) {
      navigate(tab.pathname)
    }
  }

  const containerClasses = classNames(
    isUpdatedPage ? 'v2-page-content-container' : 'page-content-container white-bg',
    { [styling['v2-settings-container']]: isUpdatedPage }

  )

  return (
    <>
      <div className={containerClasses}>
        <div className={classNames({ row: !isUpdatedPage }, styling['page-container'])}>
          <div className={styling['tabs-content-container']}>
            {!fullPageViews.includes(activeTab?.label) ? (
              <Tabs
                dataCy='settings-subtabs'
                className={styling['level-two-tabs']}
                defaultActiveTab={levelTwoActiveTab}
                options={tabOptions[0].subTabs}
                onChangeCallback={handleTabChange}
                vertical='md'
                asSubTabs
              />
            ) : null}
          </div>
          <div className={styling['main-page-content']}>
            <Routes>
              <Route path={staticRoutes.settingsPrivacySecurity.relativePathname}>
                <Route
                  index
                  element={<ProtectedRoute component={PrivacySecurityView} />}
                />
                <Route
                  path={staticRoutes.settingsPrivacySecurityVerificationCodes.relativePathname}
                  element={<ProtectedRoute component={VerificationCodes} />}
                />
                <Route
                  path='*'
                  element={<Navigate to={staticRoutes.settingsPrivacySecurity.pathname} />}
                />
              </Route>
              <Route
                path={staticRoutes.settingsNotifications.relativePathname}
                Component={Notifications}
              />
              <Route
                path={staticRoutes.settingsLinkedAccounts.relativePathname}
                Component={LinkedAccounts}
              />
              <Route
                path={staticRoutes.settingsDocuments.relativePathname}
                Component={Statements}
              />
              <Route
                path={staticRoutes.settingsAgreementsDisclosures.relativePathname}
                Component={AgreementsDisclosures}
              />
              <Route
                path={staticRoutes.settingsHelp.relativePathname}
                Component={HelpSupport}
              />
              <Route
                path={staticRoutes.settingsMyCard.relativePathname}
                Component={MyCard}
              />
              <Route
                path={staticRoutes.settingsMembership.relativePathname}
                Component={Membership}
              />
              <Route
                path={staticRoutes.settingsAccount.relativePathname}
                Component={AccountMembership}
              />
              <Route
                path='*'
                element={<Navigate to={staticRoutes.settingsAccount.pathname} replace={true} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings
