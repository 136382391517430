import React, { useMemo, useState } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import 'yup-phone-lite'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import PhoneInputField from '@common/components/form/PhoneInputField'
import FormInput from '@shared/components/formInput/FormInput'
import Button from '@shared/components/button/Button'

import { EDIT_FIELD_TYPES } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { gql, useMutation } from '@services/serviceUtils'
import { removeAlertsAction } from 'src/redux/alerts/alertsActions'
import { getApiErrorMessage } from '@common/utils'

import styling from './editAccountInfo.module.scss'

const validateEmailMutation = gql`
  mutation ValidateEmail($emailAddressInput: EmailAddressInput!) {
    validateEmail(emailAddressInput: $emailAddressInput)
  }
`
const validatePhoneMutation = gql`
  mutation ValidatePhone($phoneNumberInput: PhoneNumberInput!) {
    validatePhone(phoneNumberInput: $phoneNumberInput)
  }
`

const updateEmailMutation = gql`
  mutation UpdateEmail($emailAddressInput: EmailAddressInput!) {
    updateEmail(emailAddressInput: $emailAddressInput) {
      responseMsg
    }
  }
`

const updatePhoneMutation = gql`
  mutation UpdatePhone($phoneNumberInput: PhoneNumberInput!) {
    updatePhone(phoneNumberInput: $phoneNumberInput) {
      responseMsg
    }
  }
`

const editValidationSchema = Yup.object().shape({
  isEditingEmail: Yup.boolean(),
  phone: Yup.string().when('isEditingEmail', {
    is: false,
    then: Yup.string()
      .phone('US', 'A valid phone number is required')
      .required('Required'),
  }),
  email: Yup.string().when('isEditingEmail', {
    is: true,
    then: Yup.string()
      .email('Invalid Email')
      .required('Required'),
  }),
})

const EditAccountInfo = ({ type }) => {
  const { state = {} } = useLocation()
  const { passwordVerified = false } = state || {}
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const showErrorMessage = message => {
    dispatch(removeAlertsAction())
    setErrorMessage(message)

    if (!isEditingEmail) {
      setHasError(true)
    }
  }

  const handleTooManyAttemptsError = error => {
    setHasError(false)
    setErrorMessage('')

    const apiError = getApiErrorMessage(error)

    if (apiError.includes('too many attempts')) {
      dispatch(removeAlertsAction())
      navigate(staticRoutes.settingsAccount.pathname, {
        state: {
          updateVerificationFailed: true,
        },
      })
    }
  }

  const [validateEmail] = useMutation(validateEmailMutation, {
    onError: error => {
      const apiError = getApiErrorMessage(error)

      if (apiError.includes('existing')) {
        showErrorMessage('You are already using this email address.')
      } else if (apiError.includes('invalid')) {
        showErrorMessage('Invalid email. Please go back and enter a valid email address.')
      }
    },
  })

  const [validatePhone] = useMutation(validatePhoneMutation, {
    onError: error => {
      const apiError = getApiErrorMessage(error)

      if (apiError.includes('voip')) {
        showErrorMessage('VoIP numbers are prohibited. Please enter a valid mobile number.')
      } else if (apiError.includes('existing')) {
        showErrorMessage('You are already using this mobile number.')
      }
    },
  })

  const [updateEmail] = useMutation(updateEmailMutation, {
    onError: error => {
      handleTooManyAttemptsError(error)
    },
  })
  const [updatePhone] = useMutation(updatePhoneMutation, {
    onError: error => {
      handleTooManyAttemptsError(error)
    },
  })

  const isEditingEmail = useMemo(() => type === EDIT_FIELD_TYPES.EMAIL, [type])

  const header = isEditingEmail ? 'Edit Email Address' : 'Edit Mobile Phone'
  const paragraphText = isEditingEmail
    ? 'Enter your new email address'
    : 'Enter your new mobile phone number'

  const navigateToSubmitVerificationCode = ({ email, phone }) =>
    navigate(staticRoutes.submitVerificationCode.pathname, {
      state: {
        email,
        phone,
        editFieldType: type,
        isEditing: true,
      },
    })

  const handleUpdateEmailAddress = async email => {
    const validateResponse = await validateEmail({
      variables: {
        emailAddressInput: {
          value: email,
        },
      },
    })

    if (validateResponse) {
      const updateResponse = await updateEmail({
        variables: {
          emailAddressInput: {
            value: email,
          },
        },
      })

      if (updateResponse) {
        navigateToSubmitVerificationCode({ email })
      }
    }
  }

  const handleUpdatePhoneNumber = async phoneNumber => {
    const value = `1${phoneNumber.replace(/[^\d]/g, '')}`

    const validateResponse = await validatePhone({
      variables: {
        phoneNumberInput: {
          value,
        },
      },
    })

    if (validateResponse?.data) {
      const updateResponse = await updatePhone({
        variables: {
          phoneNumberInput: {
            value,
          },
        },
      })

      if (updateResponse) {
        navigateToSubmitVerificationCode({ phone: value })
      }
    }
  }

  const handleSubmit = async values => {
    if (isEditingEmail) {
      await handleUpdateEmailAddress(values.email)
    } else {
      await handleUpdatePhoneNumber(values.phone)
    }
  }

  return (
    <>
      {passwordVerified ? (
        <div className={classNames('white-card-container', styling['edit-account-info-container'])}>
          <GreenwoodLogo className='logo' />
          <h1>{header}</h1>
          <p>{paragraphText}.</p>
          {hasError && (
            <p className={classNames('error', 'phone-validation-error')}>{errorMessage}</p>
          )}
          <Formik
            initialValues={{
              phone: '',
              email: '',
              isEditingEmail,
            }}
            onSubmit={handleSubmit}
            validationSchema={editValidationSchema}
            enableReinitialize
          >
            {({ errors, handleChange, handleBlur, isSubmitting, touched, values, dirty }) => {
              const phoneLength = values.phone.split('').filter(char => /^[0-9]+$/.test(char))
                .length
              const isSubmitDisabled =
                (isEditingEmail && !dirty) || (!isEditingEmail && !phoneLength) || isSubmitting

              return (
                <Form>
                  {isEditingEmail ? (
                    <Field
                      as={FormInput}
                      name='email'
                      type='email'
                      label='Email Address'
                      autoComplete='off'
                      invalid={(!!errors.email && touched.email) || !!errorMessage}
                      placeholder='email@address.com'
                      errorText={errors.email}
                      disabled={isSubmitting}
                      autoCapitalize='off'
                      autoFocus
                      hintContent={
                        errorMessage ? <span className='error'>{errorMessage}</span> : null
                      }
                      onChange={e => {
                        handleChange(e)
                        setErrorMessage('')
                      }}
                    />
                  ) : (
                    <PhoneInputField
                      value={values.phone}
                      isInvalid={errors.phone && touched.phone}
                      errorText={errors.phone}
                      isDisabled={isSubmitting}
                      autoFocus
                      handlePhoneChange={e => {
                        handleChange(e)
                        setHasError(false)
                        setErrorMessage('')
                      }}
                      handlePhoneBlur={e => handleBlur(e)}
                      fieldName='phone'
                    />
                  )}
                  <Button type='submit' isLoading={isSubmitting} disabled={isSubmitDisabled}>
                    NEXT
                  </Button>
                </Form>
              )
            }}
          </Formik>
          {!isEditingEmail && <p className='disclaimer-text'>Message and data rates may apply.</p>}
        </div>
      ) : (
        <Navigate
          to={staticRoutes.verifyPassword.pathname}
          state={{
            editFieldType: type,
          }}
        />
      )}
    </>
  )
}

export default EditAccountInfo
