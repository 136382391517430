import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import MaskedInput from 'react-text-mask'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ReactComponent as EditIcon } from '@common/images/icons/edit.svg'

import FormInput from '@shared/components/formInput/FormInput'
import FormInputAddon from '@shared/components/formInput/FormInputAddon'
import FormSelect from '@shared/components/formSelect/FormSelect'
import Button from '@shared/components/button/Button'

import {
  ADDRESS_BUILDING_NUMBER_MAX_LENGTH,
  COASTAL_BANKING_SERVICES,
  CUSTOMER_NAME_MAX_LENGTH,
  CUSTOMER_NAME_VALIDATION_REGEX,
  STREET_ADDRESS_VALIDATION_REGEX,
  US_STATES,
} from '@common/constants'
import { getBirthdaySsnErrors } from '@common/utils/formUtils'
import {
  SEGMENT_EVENTS,
  trackEvent,
} from '@common/utils'
import { shortMonthDayYearToIso8601Date } from '@shared/utils'
import { staticRoutes } from '@routing/routes'
import { removeAlertsAction } from '@redux/alerts/alertsActions'

import styling from './editForm.module.scss'

const EditForm = ({ updating, applicationData, handleClick, updateApplication }) => {
  const [isUnderageEventTracked, setIsUnderageEventTracked] = useState(false)
  const [isEditingSsn, setIsEditingSsn] = useState(false)

  const dispatch = useDispatch()

  const {
    firstName,
    lastName,
    deliveryAddress,
    buildingNumber,
    locality,
    postalCode,
  } = useMemo(() => applicationData?.application, [applicationData?.application])

  const state = useMemo(() => (
    applicationData?.application?.subdivisions?.length ? applicationData.application.subdivisions[0] : ''
  ), [applicationData?.application])

  const { birthDate, ssn } = useMemo(() => applicationData?.review, [applicationData?.review])

  const initialValues = useMemo(() => {
    const values = {
      firstName,
      lastName,
      deliveryAddress,
      buildingNumber,
      locality,
      state,
      zipCode: postalCode,
    }

    // only show birthDate and taxNumber fields if it is the user's first visit and the data is available to show
    if (!birthDate?.toLowerCase()?.includes('x')) {
      values.birthDate = birthDate
      values.taxNumber = ssn
    }

    return values
  }, [
    firstName,
    lastName,
    birthDate,
    ssn,
    deliveryAddress,
    buildingNumber,
    locality,
    state,
    postalCode,
  ])

  const reportUnderageEvent = () => {
    // Checks if the underage error occurrs. If so, track the event
    if (!isUnderageEventTracked) {
      trackEvent({ event: SEGMENT_EVENTS.USER_DISQUALIFIED })
      setIsUnderageEventTracked(true)
    }
  }

  const handleValidation = values => {
    let errors = {}

    if (!values.firstName) {
      errors.firstName = 'Required'
    } else {
      if (!values.firstName.match(CUSTOMER_NAME_VALIDATION_REGEX)) {
        errors.firstName = 'First name contains invalid characters'
      }
    }

    if (!values.lastName) {
      errors.lastName = 'Required'
    } else {
      if (!values.lastName.match(CUSTOMER_NAME_VALIDATION_REGEX)) {
        errors.lastName = 'Last name contains invalid characters'
      } else if (values.lastName.length < 2) {
        errors.lastName = 'Last name must include at least two characters'
      }
    }

    if (!values.state) {
      errors.state = 'State is required'
    }

    if (!values.locality) {
      errors.locality = 'City is required'
    }

    if (!values.zipCode) {
      errors.zipCode = 'Zip Code is required'
    } else {
      if (values.zipCode.length < 5) {
        errors.zipCode = 'Zip Code must be exactly 5 digits'
      } else if (!values.zipCode.match(/^\d*$/gi)) {
        errors.zipCode = 'Zip Code can only be a number'
      }
    }

    if (!values.deliveryAddress) {
      errors.deliveryAddress = 'Street Address is required'
    } else {
      if (values.deliveryAddress.length < 2) {
        errors.deliveryAddress = 'Street Address must include at least 2 characters'
      } else if (!values.deliveryAddress.match(STREET_ADDRESS_VALIDATION_REGEX)) {
        errors.deliveryAddress = 'Street Address has an invalid format'
      }
    }

    if (Object.keys(initialValues).includes('birthDate')) {
      const birthdaySsnErrors = getBirthdaySsnErrors({
        values,
        reportUnderageEvent,
      })

      errors = {
        ...errors,
        ...birthdaySsnErrors,
      }
    }

    return errors
  }

  const handleSubmit = useCallback(async values => {
    const updateValues = {}
    dispatch(removeAlertsAction())

    Object.keys(values).forEach(key => {
      if (key === 'birthDate') {
        if (values[key] !== birthDate) {
          updateValues[key] = { value: shortMonthDayYearToIso8601Date(values?.birthDate) }
        }
      } else if (key === 'taxNumber') {
        if (values[key] !== ssn) {
          updateValues[key] = { value: values?.taxNumber.replaceAll('-', '') }
        }
      } else if (key === 'state') {
        if (
          applicationData?.application?.subdivisions?.length &&
          values[key] !== applicationData?.application?.subdivisions[0]
        ) {
          updateValues.subdivisions = [values[key]]
        }
      } else if (key === 'zipCode') {
        if (values[key] !== postalCode) {
          updateValues.postalCode = values[key]
        }
      } else {
        if (values[key] !== applicationData?.application[key]) {
          updateValues[key] = values[key]
        }
      }
    })

    if (Object.keys(updateValues).length) {
      const response = await updateApplication(updateValues)

      if (response.data && handleClick) {
        handleClick()
      }
    } else if (handleClick) {
      handleClick()
    }
  }, [
    applicationData?.application,
    birthDate,
    ssn,
    postalCode,
    handleClick,
    updateApplication,
    dispatch,
  ])

  const getFormEditAddon = () => (
    <FormInputAddon type='trailing'>
      <EditIcon />
    </FormInputAddon>
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={handleValidation}
    >
      {({ errors, handleChange, isSubmitting, touched, setFieldValue, values }) => (
        <Form data-cy='create-account-review-confirm-form' className={styling['edit-form']}>
          <div className={styling['form-inputs-container']}>
            <div className={styling['multi-input-container']}>
              <Field
                as={FormInput}
                name='firstName'
                placeholder='First Name'
                autoComplete='given-name'
                showRequiredAsterisk={false}
                invalid={errors.firstName && touched.firstName}
                errorText={errors.firstName}
                disabled={isSubmitting}
                maxLength={CUSTOMER_NAME_MAX_LENGTH}
                data-cy='create-account-edit-first-name-input'
              >
                {getFormEditAddon()}
              </Field>
              <Field
                as={FormInput}
                name='lastName'
                placeholder='Last Name'
                autoComplete='family-name'
                showRequiredAsterisk={false}
                invalid={errors.lastName && touched.lastName}
                errorText={errors.lastName}
                disabled={isSubmitting}
                maxLength={CUSTOMER_NAME_MAX_LENGTH}
                data-cy='create-account-edit-last-name-input'
              >
                {getFormEditAddon()}
              </Field>
            </div>
            {Object.keys(initialValues).includes('birthDate') ? (
              <>
                <MaskedInput
                  disabled={isSubmitting}
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  placeholder='Date of Birth (MM/DD/YYYY)'
                  guide={false}
                  onChange={handleChange}
                  data-cy='create-account-edit-birthday-input'
                  value={values.birthDate}
                  render={(ref, props) => (
                    <Field
                      as={FormInput}
                      name='birthDate'
                      showRequiredAsterisk={false}
                      invalid={errors.birthDate && touched.birthDate}
                      errorText={errors.birthDate}
                      disabled={isSubmitting}
                      inputMode='numeric'
                      innerRef={ref}
                      {...props}
                      defaultValue={undefined}
                    >
                      {getFormEditAddon()}
                    </Field>
                  )}
                />
                <MaskedInput
                  disabled={isSubmitting}
                  mask={isEditingSsn
                    ? [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                    : ['X', 'X', 'X', '-', 'X', 'X', '-', /\d/, /\d/, /\d/, /\d/]
                  }
                  onFocus={() => {
                    if (!isEditingSsn) {
                      setFieldValue('taxNumber', '')
                      setIsEditingSsn(true)
                    }
                  }}
                  onChange={handleChange}
                  placeholder='SSN (XXX-XX-XXXX)'
                  data-cy='create-account-ssn-input'
                  value={values.taxNumber}
                  guide={false}
                  render={(ref, props) => (
                    <Field
                      as={FormInput}
                      name='taxNumber'
                      showRequiredAsterisk={false}
                      invalid={errors.taxNumber && touched.taxNumber}
                      errorText={errors.taxNumber}
                      disabled={isSubmitting}
                      inputMode='numeric'
                      innerRef={ref}
                      {...props}
                      defaultValue={undefined}
                    >
                      {getFormEditAddon()}
                    </Field>
                  )}
                />
              </>
            ) : (
              <p className={styling['edit-birthday-ssn-text']}>
                We do not store your birthday and SSN information. If you are unsure if you've entered your information correctly, please {' '}
                <Link
                  to={staticRoutes.createAccountFinalDetails.pathname}
                  state={{ isEditing: true }}
                  className='underlined-link bold'
                >
                  click here
                </Link>.
              </p>
            )}
            <Field
              as={FormInput}
              name='deliveryAddress'
              invalid={errors.deliveryAddress && touched.deliveryAddress}
              errorText={errors.deliveryAddress}
              disabled={isSubmitting}
              placeholder='Street Address'
              showRequiredAsterisk={false}
              value={values.deliveryAddress}
            >
              {getFormEditAddon()}
            </Field>
            <Field
              as={FormInput}
              name='buildingNumber'
              placeholder='Apartment, Unit, or Floor'
              disabled={isSubmitting}
              maxLength={ADDRESS_BUILDING_NUMBER_MAX_LENGTH}
            >
              {getFormEditAddon()}
            </Field>
            <Field
              as={FormInput}
              name='locality'
              invalid={errors.locality && touched.locality}
              placeholder='City'
              errorText={errors.locality}
              disabled={isSubmitting}
              showRequiredAsterisk={false}
              maxLength={30}
              value={values.locality}
            >
              {getFormEditAddon()}
            </Field>
            <div className={styling['multi-input-container']}>
              <Field
                as={FormSelect}
                name='state'
                invalid={errors.state && touched.state}
                errorText={errors.state}
                disabled={isSubmitting}
                showRequiredAsterisk={false}
                showRequiredText={false}
                required
                value={values.state}
              >
                <option value='' disabled={true}>State</option>
                {Object.entries(US_STATES).map(option => (
                  <option value={option[0]} key={option[0]}>
                    {option[1]}
                  </option>
                ))}
              </Field>
              <Field
                as={FormInput}
                name='zipCode'
                invalid={errors.zipCode && touched.zipCode}
                errorText={errors.zipCode}
                disabled={isSubmitting}
                placeholder='Zip Code'
                showRequiredAsterisk={false}
                maxLength={5}
                value={values.zipCode}
              >
                {getFormEditAddon()}
              </Field>
            </div>
          </div>
          <div className='button-container'>
            <p className='disclaimer-text'>{COASTAL_BANKING_SERVICES}</p>
            <Button
              type='submit'
              data-cy='create-account-review-agree-button'
              isLoading={updating}
            >
              Next
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

EditForm.propTypes = {
  updating: PropTypes.bool,
  applicationData: PropTypes.any,
  handleClick: PropTypes.func,
  updateApplication: PropTypes.func,
}

export default EditForm
