import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as DollarCircleIcon } from '@common/images/icons/v2/dollar-circle.svg'
import { ReactComponent as BankingIcon } from '@common/images/icons/v2/banking.svg'
import { ReactComponent as CommunityIcon } from '@common/images/icons/v2/community.svg'
import { ReactComponent as GivingIcon } from '@common/images/icons/v2/giving.svg'
import { ReactComponent as InvestIcon } from '@common/images/icons/v2/invest.svg'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'

import { gql, useQuery } from '@services/serviceUtils'
import { staticRoutes } from '@routing/routes'
import { getBalance } from '@common/utils/appUtils'
import { BankingContainerContext } from '../../BankingContainer'

import styling from './sideNavigation.module.scss'

const totalBalanceQuery = gql`
  query Accounts {
    accounts {
      totalBalance {
        amount
      }
    }
  }
`

const nonBankingPaths = [
  staticRoutes.invest.pathname,
  staticRoutes.community.pathname,
  staticRoutes.giving.pathname,
].map(item => item.replace('/*', ''))

const SideNavigation = ({ isTablet }) => {
  const { pathname } = useLocation()

  const customer = useSelector(state => state.customer)

  const { theme } = useContext(BankingContainerContext)

  const hasPrimaryAccount = useMemo(() => customer?.hasPrimaryAccount, [customer])
  const isStandardUser = useMemo(() => !customer?.hasElevate && !customer?.hasPremium, [customer])

  const { loading, error, data } = useQuery(totalBalanceQuery, { fetchPolicy: 'no-cache' })

  const navigationItems = useMemo(() => {
    const links = [
      {
        title: 'Banking',
        icon: <BankingIcon />,
        pathname: staticRoutes.dashboard.pathname,
      }, {
        title: 'Community',
        icon: <CommunityIcon />,
        pathname: staticRoutes.community.pathname,
      },
    ]

    if (hasPrimaryAccount) {
      if (!isStandardUser) {
        links.splice(1, 0, {
          title: 'Invest',
          icon: <InvestIcon />,
          pathname: staticRoutes.invest.pathname,
        })
      }

      links.push({
        title: 'Giving',
        icon: <GivingIcon />,
        pathname: staticRoutes.giving.pathname,
      })
    }

    return links
  }, [hasPrimaryAccount, isStandardUser])

  const getNavItems = () => {
    return navigationItems.map((item, index) => {
      const linkProps = {
        className: ({ isActive }) => {
          let isActiveSideNavItem = false

          if (item?.title?.toLowerCase() === 'banking') {
            isActiveSideNavItem = pathname === staticRoutes.dashboard.pathname || !nonBankingPaths?.find(path => pathname.includes(path))
          } else {
            isActiveSideNavItem = pathname.startsWith(item.pathname.replace('/*', ''))
          }

          return classNames(styling['nav-link'], { [styling.active]: isActive || isActiveSideNavItem })
        },
        to: item?.pathname,
      }

      if (item?.title?.toLowerCase() === 'giving') {
        linkProps.state = { fromPathname: pathname }
      }

      return (
        <NavLink key={`nav-item-${index}`} {...linkProps}>
          {item.icon}{item.title}
        </NavLink>
      )
    })
  }

  const mobileNavigationClasses = classNames(
    styling['mobile-bottom-navigation-container'],
    { [styling['is-open']]: true }
  )

  return (
   <>
    {isTablet ? (
      <div className={mobileNavigationClasses}>{getNavItems()}</div>
    ) : (
      <div className={classNames(styling['side-navigation-container'], styling[theme])}>
        <div className={styling['logo-container']}>
          <GreenwoodLogo className={styling.logo} />
        </div>
        <div className={styling['nav-items-container']}>
          <div className={styling['balance-container']}>
            <LoadingContainer loading={loading} error={error} errorMessage='Error getting balance information'>
              <div className={styling['green-bar']}>
                <DollarCircleIcon />
              </div>
              <span>Total Balance</span>
              <div className='v2-total-balance'>
                {getBalance(`${data?.accounts?.totalBalance?.amount || 0}`)}
              </div>
            </LoadingContainer>
          </div>
          <div className={styling['nav-items']}>
            {getNavItems()}
          </div>
        </div>
      </div>
    )}
   </>
  )
}

SideNavigation.propTypes = {
  isTablet: PropTypes.bool,
}

export default SideNavigation
