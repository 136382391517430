import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import FormPasswordInput from '@shared/components/FormPasswordInput'
import Button from '@shared/components/button/Button'
import { ReactComponent as XIcon } from '@shared/images/icons/x.svg'

import { fullValidatorForSchema } from '@common/utils/formUtils'
import { secondaryLogin } from '@services/serviceUtils'
import { removeAlertsAction } from 'src/redux/alerts/alertsActions'
import { EDIT_FIELD_TYPES } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { purgeStore } from 'src/redux/store'

import styling from './verifyPassword.module.scss'

const passwordValidationSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
})

const VerifyPassword = () => {
  const { state = {} } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const email = useSelector(state => state.launchDarklyCustomer?.email)

  const [errorMessage, setErrorMessage] = useState('')

  const { editFieldType } = state || {}

  const handleSubmit = async values => {
    const response = await secondaryLogin({
      username: email,
      password: values.password,
      onError: error => {
        dispatch(removeAlertsAction())

        if (!error?.data?.message.includes('been locked')) {
          setErrorMessage('Incorrect password. Please try again.')
        } else {
          window.sessionStorage.setItem('hasEditAccountLock', true)
          purgeStore({ redirectPath: staticRoutes.signIn.pathname })
        }
      },
    })

    if (response) {
      const editPath =
        editFieldType === EDIT_FIELD_TYPES.EMAIL
          ? staticRoutes.editEmailAddress.pathname
          : staticRoutes.editPhoneNumber.pathname

      navigate(editPath, { state: { passwordVerified: true } })
    }
  }

  return (
    <>
      {!editFieldType ? (
        <Navigate to={staticRoutes.settingsAccount.pathname} />
      ) : (
        <div className={classNames('white-card-container', styling['verify-password-container'])}>
          <GreenwoodLogo className='logo' />
          <h1>Enter password</h1>
          <p>Enter your password to verify your account.</p>
          <Formik
            validate={fullValidatorForSchema(passwordValidationSchema)}
            initialValues={{
              password: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ errors, isSubmitting, touched, dirty, handleChange }) => (
              <Form>
                <Field
                  as={FormPasswordInput}
                  name='password'
                  label='Password'
                  autoComplete='new-password'
                  autoFocus
                  invalid={(errors.password && touched.password) || !!errorMessage}
                  disabled={isSubmitting}
                  errorText={errors.password}
                  hintContent={
                    errorMessage ? (
                      <ul className='password-requirements-list'>
                        <li className='error'>
                          <span>
                            <XIcon />
                          </span>
                          <span>{errorMessage}</span>
                        </li>
                      </ul>
                    ) : null
                  }
                  onChange={e => {
                    handleChange(e)
                    setErrorMessage('')
                  }}
                />
                <Button type='submit' isLoading={isSubmitting} disabled={!dirty || isSubmitting}>
                  NEXT
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  )
}

export default VerifyPassword
