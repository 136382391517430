import React, { useEffect, useMemo } from 'react'
import { Outlet, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import ProvideEmail from '@common/components/ProvideEmail'
import ProvidePassword from '@common/components/providePassword/ProvidePassword'
import ProtectedRoute from '@routing/ProtectedRoute'
import CreateAccountWrapper from '@common/components/createAccountWrapper/CreateAccountWrapper'

import { staticRoutes } from '@routing/routes'
import { setOnboardingFlowTypeAction } from '@redux/application/applicationActions'
import {
  ONBOARDING_FLOW_TYPES,
  ONBOARDING_FLOW_TYPE_BY_ROUTE,
  SIGN_UP_ROUTES,
} from '@common/constants'
import { ROUTE_USER_FLOW } from '@shared/constants/uiConstants'

import styling from './signUp.module.scss'

/**
 * Unauthenticated sign-up workflow
 */

const SignUp = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const onboardingType = useSelector(state => state.onboardingType)

  const signUpPageDetails = useMemo(() => {
    let header
    let paragraphText
    let className
    let greenwoodFact

    if (pathname === staticRoutes.signUpEmail.pathname) {
      greenwoodFact = "The original Greenwood District, known as 'Black Wall Street', was home to around 10,000 Black residents who built a thriving, self-sustained community."
      header = "Let's Get Started"
      paragraphText =
        "Let's get started on your journey to financial freedom. Your email is the first step toward smarter banking. With FDIC insurance, no hidden fees, and a fully digital bank, you can manage your finances anytime, anywhere. At Greenwood, we're here for you."
      className = styling.email
    } else {
      greenwoodFact = "Greenwood's 35-block district boasted over 30 restaurants, 45 grocery and meat markets, 21 churches, two movie theaters, and even a luxury hotel—all Black-owned and operated."
      header = 'Create Your Login Password'
      paragraphText = 'Secure your account with a password to keep your information private as you take your first steps toward financial freedom.'
      className = styling.password
    }

    return {
      header,
      paragraphText,
      className,
      greenwoodFact,
    }
  }, [pathname])

  useEffect(() => {
    if (SIGN_UP_ROUTES.includes(pathname) && pathname !== staticRoutes.signUp.pathname) {
      const typeKey = Object.keys(ONBOARDING_FLOW_TYPE_BY_ROUTE).find(key => pathname.includes(key))
      const flowType = ONBOARDING_FLOW_TYPE_BY_ROUTE[typeKey] || ONBOARDING_FLOW_TYPES.ELEVATE

      if (onboardingType !== flowType) {
        // Save the onboardingFlowType in state
        dispatch(setOnboardingFlowTypeAction(flowType))
      }
    }
  }, [pathname, dispatch, onboardingType])

  return (
    <CreateAccountWrapper {...signUpPageDetails} >
      <Routes>
        <Route
          path={staticRoutes.signUpEmail.relativePathname}
          element={<ProtectedRoute component={ProvideEmail} />}
        />
        <Route
          path={staticRoutes.signUpPassword.relativePathname}
          element={<ProtectedRoute component={ProvidePassword}/>}
        />
        <Route
          path='*'
          element={
            <Navigate
              to={staticRoutes.signUpEmail.pathname}
              state={{ userFlow: ROUTE_USER_FLOW.SIGNUP }}
            />
          }
        />
      </Routes>
      <Outlet />
    </CreateAccountWrapper>
  )
}

export default SignUp
