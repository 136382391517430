import React, { useContext, useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { ReactComponent as StatementIcon } from '@common/images/icons/v2/statement.svg'
import { ReactComponent as InfoCircleIcon } from '@common/images/icons/v2/info-circle.svg'
import { ReactComponent as MastercardIcon } from '@common/images/icons/v2/mc-logo.svg'

import { DASHBOARD_ACCOUNT_CARD_TYPES } from '@common/constants'
import { getBalance } from '@common/utils/appUtils'
import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import styling from './accountCard.module.scss'

const AccountCard = ({
  type = DASHBOARD_ACCOUNT_CARD_TYPES.SPENDING,
  isActivate,
  details,
  onClick = () => {},
}) => {
  const { theme } = useContext(BankingContainerContext)

  const customer = useSelector(state => state?.customer)

  const customerName = useMemo(() => (
    `${customer?.firstName} ${customer?.lastName?.charAt(0)}.`
  ), [customer])

  const isPhysicalCard = useMemo(() => (
    type === DASHBOARD_ACCOUNT_CARD_TYPES.CARD
  ), [type])

  const innerCardText = useMemo(() => {
    switch (type) {
      case DASHBOARD_ACCOUNT_CARD_TYPES.SAVINGS:
        return 'Account is not created'
      case DASHBOARD_ACCOUNT_CARD_TYPES.FUND:
        return 'Your spending account balance is $0'
      case DASHBOARD_ACCOUNT_CARD_TYPES.CARD:
      default:
        return 'Card is not activated'
    }
  }, [type])

  const activateText = useMemo(() => {
    switch (type) {
      case DASHBOARD_ACCOUNT_CARD_TYPES.SAVINGS:
        return 'Create Account'
      case DASHBOARD_ACCOUNT_CARD_TYPES.FUND:
        return 'Fund Now'
      case DASHBOARD_ACCOUNT_CARD_TYPES.CARD:
      default:
        return 'Activate Card'
    }
  }, [type])

  const icon = useMemo(() => {
    if (type === DASHBOARD_ACCOUNT_CARD_TYPES.FUND) {
      return
    }

    return !isPhysicalCard && !isActivate ? <StatementIcon /> : <InfoCircleIcon />
  }, [type, isPhysicalCard, isActivate])

  const cardClasses = classNames(
    styling['account-card'],
    type && styling[type],
    styling[theme]
  )

  return (
    <div
      className={cardClasses}
      role={!isActivate ? 'button' : ''}
      onClick={() => !isActivate ? onClick() : null}
    >
      <div className={styling['account-card-main-container']}>
        <div className={styling['account-card-title-container']}>
          <span>
            {type !== DASHBOARD_ACCOUNT_CARD_TYPES.FUND ? (
              <>{type.split('-').join(' ')} {'// USD'}</>
            ) : null}
          </span>
          {icon}
        </div>
        {isActivate ? (
          <div className={styling['inner-card']}>
            {innerCardText}
          </div>
        ) : (
          <>
            <div className={styling['content-container']}>
              <div>
                <div>Num</div>
                <div>{isPhysicalCard ? details?.last4 : details?.accountNumber?.slice(-4)}</div>
              </div>
              {!isPhysicalCard && (
                <div>
                  <div>Account</div>
                  <div>VIRTUAL</div>
                </div>
              )}
            </div>
            {isPhysicalCard && (
              <div className={styling['card-logo']}>
                <MastercardIcon />
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={styling['account-card-secondary-container']}
        role={isActivate ? 'button' : ''}
        onClick={() => isActivate ? onClick() : null}
      >
        {isActivate ? activateText : (
          <div className={styling['secondary-content-container']}>
            <div>{isPhysicalCard ? 'Name' : 'Balance'}</div>
            <div className={classNames(!isPhysicalCard && 'v2-total-balance')}>
              {isPhysicalCard ? customerName : getBalance(`${details?.balance?.amount || 0}`)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

AccountCard.propTypes = {
  type: PropTypes.string,
  isActivate: PropTypes.bool,
  details: PropTypes.object,
  onClick: PropTypes.func,
}

export default AccountCard
