import React from 'react'
import PropTypes from 'prop-types'

import TransactionDetails from '../../transactionDetails/TransactionDetails'
import Modal from '@shared/components/modal/Modal'

import styling from './mobileTransactionDetailsModal.module.scss'

const MobileTransactionDetailsModal = ({ isOpen, toggleModal, transaction }) => (
  <Modal
    centered
    size='md'
    open={isOpen}
    modalClassName='v2-modal'
    toggleModal={toggleModal}
    modalContentClassName={styling['transaction-details-modal']}
  >
    <TransactionDetails transactionDetails={transaction} />
  </Modal>
)

MobileTransactionDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  transaction: PropTypes.any,
}

export default MobileTransactionDetailsModal
