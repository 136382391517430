import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'

import Modal from '@shared/components/modal/Modal'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ModalBody from '@shared/components/modal/ModalBody'
import Button from '@shared/components/button/Button'
import ExternalLink from '@common/components/link/ExternalLink'

import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS } from '@common/constants'

import styling from './investStockCampaignModal.module.scss'

const InvestStockCampaignModal = ({
  isOpen,
  toggleModal,
  closeModal,
  isUpgrading,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const paragraphText = isUpgrading
    ? 'Earn $10 by upgrading to Premium and activating Greenwood Invest.'
    : 'Earn $10 when you open a brand new investment account!'

  const buttonText = isUpgrading ? 'Upgrade Now' : 'Claim $10 Now'

  const handleClick = () => {
    if (isUpgrading) {
      navigate(staticRoutes.selectAccountTier.pathname, {
        state: {
          isUpgrading: true,
          fundingFlow: FUNDING_FLOWS.UPGRADE_MEMBERSHIP,
          cancelUpgradePathname: pathname,
        },
      })
    } else {
      navigate(staticRoutes.invest.pathname)
    }
  }

  return (
    <Modal
      centered
      size='md'
      open={isOpen}
      toggleModal={toggleModal}
      className={styling['invest-campaign-modal']}
    >
      <ModalHeader withCloseButton closeModal={closeModal} className={styling['modal-header']} />
      <ModalBody className={styling['modal-body']}>
        <h4>Get $10 Instantly</h4>
        <p>{paragraphText}</p>
        <Button onClick={handleClick}>{buttonText}</Button>
      </ModalBody>
      <ExternalLink
        className={styling.terms}
        to='https://gogreenwood.com/we-invest-too-tc/'
      >
        Terms & Conditions Apply
      </ExternalLink>
    </Modal>
  )
}

InvestStockCampaignModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  closeModal: PropTypes.func,
  isUpgrading: PropTypes.bool,
}

export default InvestStockCampaignModal
