import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { useDispatch } from 'react-redux'

import ReduxField from '@common/components/reduxField/ReduxField'
import FormInput from '@shared/components/formInput/FormInput'
import FormSelect from '@shared/components/formSelect/FormSelect'
import Button from '@shared/components/button/Button'
import FormPlacesInput from '@shared/components/formPlacesInput/FormPlacesInput'

import { SEGMENT_EVENTS, SEGMENT_SOURCE_DETAILS, trackEvent } from '@common/utils'
import {
  US_STATES,
  ADDRESS_BUILDING_NUMBER_MAX_LENGTH,
  MANUAL_ADDRESS_VALIDATION_SCHEMA,
  GOOGLE_API_KEY,
} from '@common/constants'
import { setCustomerAction } from '@redux/customer/customerActions'
import { removeAlertsAction } from '@redux/alerts/alertsActions'

import styling from './manualAddressEntryForm.module.scss'

const manualAddressValidationSchema = Yup.object().shape({
  // Generic manual address validation schema (for: deliveryAddress, city, state, zipCode)
  ...MANUAL_ADDRESS_VALIDATION_SCHEMA,
})

const ManualAddressEntryForm = ({
  selectedCity,
  selectedAddress,
  setIsSelectingAddress,
  submitAddress,
  isResubmitting = false,
  formValues,
  showManualEntry,
  setShowManualEntry,
  className,
}) => {
  const dispatch = useDispatch()

  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)

  const trackPendingEvent = () => {
    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */
    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }
  }

  const handleAddressSelection = ({ address, setValues, values }) => {
    trackPendingEvent()

    const { addressComponents = {}, searchAddress: googleSearchAddress = '' } = address

    const locality = addressComponents?.locality ||
      addressComponents?.administrative_area_level_3 ||
      addressComponents?.political || ''
    const state = addressComponents?.administrative_area_level_1 || ''
    const zipCode = addressComponents?.postal_code?.slice(0, 5) || ''

    setValues({
      ...values,
      state,
      locality,
      zipCode,
      deliveryAddress: googleSearchAddress,
    })

    if (!Object.keys(addressComponents).length) {
      setShowManualEntry(true)
    }
  }

  const handleManualSubmit = (values, actions) => {
    dispatch(removeAlertsAction())
    trackPendingEvent()

    const address = {
      deliveryAddress: values.deliveryAddress,
      buildingNumber: values.aptUnitFloor,
      locality: values.locality,
      subdivisions: [values.state],
      postalCode: values.zipCode,
    }

    // Save the customer address info
    dispatch(setCustomerAction(address))
    submitAddress({ actions, address })
  }

  const handleFormFieldBlur = sourceDetail => {
    // Track any time the user leaves a field while updating their address
    trackEvent({
      event: SEGMENT_EVENTS.registrationFormFieldEntry({
        sourceDetail,
      }),
    })

    setFocusedInputSourceDetail(null)
  }

  const getFormFields = ({
    errors,
    touched,
    isSubmitting,
    handleChange,
    setFieldValue,
    setValues,
    values,
  }) => (
    <>
      {(isResubmitting || showManualEntry) ? (
        <ReduxField
          as={FormInput}
          name='deliveryAddress'
          label={isResubmitting ? 'Street Address' : ''}
          invalid={errors.deliveryAddress && touched.deliveryAddress}
          errorText={errors.deliveryAddress}
          disabled={isSubmitting}
          required={isResubmitting}
          placeholder={isResubmitting ? '(No P.O. Box or Business Address)' : 'Street Address'}
          showRequiredAsterisk={false}
          onFocus={() => setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.ADDRESS_LINE_1)}
          onBlur={() => handleFormFieldBlur(SEGMENT_SOURCE_DETAILS.ADDRESS_LINE_1)}
          onChange={e => {
            handleChange(e)

            if (!isResubmitting) {
              setFieldValue('deliveryAddress', e.target.value)
            }
          }}
          value={values.deliveryAddress}
        />
      ) : (
        <Field
          as={FormPlacesInput}
          apiKey={GOOGLE_API_KEY}
          id='searchAddress'
          name='deliveryAddress'
          errorText={errors.deliveryAddress}
          placeholder='Street Address'
          disabled={isSubmitting}
          onChange={e => {
            handleChange(e)
            setFieldValue('deliveryAddress', e.target.value)
          }}
          onSelect={address => {
            handleAddressSelection({ address, setValues, values })
          }}
          invalid={errors.deliveryAddress && touched.deliveryAddress}
          onSelecting={setIsSelectingAddress}
          value={values.deliveryAddress || ''}
          onFocus={() => setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.GOOGLE_ADDRESS_SEARCH)}
          onBlur={() => handleFormFieldBlur(SEGMENT_SOURCE_DETAILS.GOOGLE_ADDRESS_SEARCH)}
        />
      )}
      <Field
        as={FormInput}
        name='aptUnitFloor'
        label={isResubmitting ? 'Apt#, Suite#, Unit#' : ''}
        placeholder={isResubmitting ? 'Optional' : 'Apartment, Unit, or Floor'}
        disabled={isSubmitting}
        maxLength={ADDRESS_BUILDING_NUMBER_MAX_LENGTH}
        onFocus={() => setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.ADDRESS_LINE_2)}
        onBlur={() => handleFormFieldBlur(SEGMENT_SOURCE_DETAILS.ADDRESS_LINE_2)}
      />
      <Field
        as={FormInput}
        name='locality'
        label={isResubmitting ? 'City' : ''}
        invalid={errors.locality && touched.locality}
        placeholder={isResubmitting ? '' : 'City'}
        errorText={errors.locality}
        disabled={isSubmitting}
        required={isResubmitting}
        showRequiredAsterisk={false}
        maxLength={30}
        onFocus={() => setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.CITY)}
        onBlur={() => handleFormFieldBlur(SEGMENT_SOURCE_DETAILS.CITY)}
        value={values.locality}
      />
      <div className={styling['state-postal-code']}>
        <Field
          as={FormSelect}
          name='state'
          label={isResubmitting ? 'State' : ''}
          invalid={errors.state && touched.state}
          errorText={errors.state}
          disabled={isSubmitting}
          showRequiredAsterisk={false}
          showRequiredText={isResubmitting}
          required
          onFocus={() => setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.STATE)}
          onBlur={() => handleFormFieldBlur(SEGMENT_SOURCE_DETAILS.STATE)}
          value={values.state}
        >
          <option value='' disabled={!isResubmitting}>{isResubmitting ? '' : 'State'}</option>
          {Object.entries(US_STATES).map(option => (
            <option value={option[0]} key={option[0]}>
              {option[1]}
            </option>
          ))}
        </Field>
        <Field
          as={FormInput}
          name='zipCode'
          label={isResubmitting ? 'Zip Code' : ''}
          invalid={errors.zipCode && touched.zipCode}
          errorText={errors.zipCode}
          disabled={isSubmitting}
          required={isResubmitting}
          placeholder={isResubmitting ? '' : 'Zip Code'}
          showRequiredAsterisk={false}
          maxLength={5}
          onFocus={() => setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.ZIP)}
          onBlur={() => handleFormFieldBlur(SEGMENT_SOURCE_DETAILS.ZIP)}
          value={values.zipCode}
        />
      </div>
    </>
  )

  return (
    <div className={className}>
      {isResubmitting ? (
        <>
          <h1 data-cy='manual-address-header'>Manually enter your home address</h1>
          <p>We need to verify your home address. This is also where we’ll send your new card.</p>
          <Formik
            validationSchema={manualAddressValidationSchema}
            initialValues={{
              deliveryAddress: selectedAddress?.deliveryAddress || '',
              aptUnitFloor: selectedAddress?.aptUnitFloor || '',
              locality: selectedCity || '',
              state: selectedAddress?.state || '',
              zipCode: selectedAddress?.zipCode || '',
            }}
            enableReinitialize
            onSubmit={handleManualSubmit}
          >
            {resubmitForm => {
              const { isSubmitting } = resubmitForm

              return (
                <Form data-cy='manual-address-form'>
                  {getFormFields({ ...resubmitForm })}
                  <Button
                    type='submit'
                    isLoading={isSubmitting}
                    data-cy='create-account-address-manual-confirm-button'
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </>
      ) : (
        <>{getFormFields({ ...formValues })}</>
      )}
    </div>
  )
}

ManualAddressEntryForm.propTypes = {
  selectedAddress: PropTypes.any,
  setIsSelectingAddress: PropTypes.func,
  selectedCity: PropTypes.string,
  submitAddress: PropTypes.func,
  isResubmitting: PropTypes.bool,
  formValues: PropTypes.object, // Formik form object
  showManualEntry: PropTypes.bool,
  setShowManualEntry: PropTypes.func,
  className: PropTypes.string,
}

export default ManualAddressEntryForm
