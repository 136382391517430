import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import CtaCard from '@common/components/v2/ctaCard/CtaCard'

import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS } from '@common/constants'
import { trackEvent, SEGMENT_EVENTS } from '@common/utils'
import { BankingContainerContext } from '../v2/bankingContainer/BankingContainer'

import styling from './upgradeMembershipCallout.module.scss'

const UpgradeMembershipCallout = ({ isDashboardCta = false, segmentSource }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { theme } = useContext(BankingContainerContext)

  const handleClick = () => {
    trackEvent({
      event: SEGMENT_EVENTS.UPGRADE_MEMBERSHIP_BUTTON_CLICK(segmentSource),
    })

    navigate({
      pathname: staticRoutes.selectAccountTier.pathname,
      state: {
        isUpgrading: true,
        fundingFlow: FUNDING_FLOWS.UPGRADE_MEMBERSHIP,
        cancelUpgradePathname: pathname,
      },
    })
  }

  const cardClasses = classNames(
    styling['upgrade-membership-card'],
    styling[theme],
    'new-card',
    !isDashboardCta && styling['membership-page-cta']
  )

  return (
    <CtaCard
      className={cardClasses}
      onClick={handleClick}
      subText='Join for unlimited rewards & exclusive benefits.'
      headerText='Interested in a premium membership?'
    />
  )
}

UpgradeMembershipCallout.propTypes = {
  isDashboardCta: PropTypes.bool,
}

export default UpgradeMembershipCallout
