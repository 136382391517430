import React, { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'
import { ReactComponent as ProfileIcon } from '@common/images/icons/v2/profile.svg'
import { ReactComponent as CardIcon } from '@common/images/icons/v2/settings/card.svg'
import { ReactComponent as HelpSupportIcon } from '@common/images/icons/v2/settings/help-support.svg'
import { ReactComponent as LinkedAccountsIcon } from '@common/images/icons/v2/settings/linked-accounts.svg'
import { ReactComponent as MembershipIcon } from '@common/images/icons/v2/settings/membership.svg'
import { ReactComponent as SettingsIcon } from '@common/images/icons/v2/settings/settings.svg'
import { ReactComponent as StatementsIcon } from '@common/images/icons/v2/settings/statements.svg'
import { ReactComponent as TermsPoliciesIcon } from '@common/images/icons/v2/settings/terms-policies.svg'

import { staticRoutes } from '@routing/routes'
import { gql, useLazyQuery } from '@services/serviceUtils'
import { MEMBERSHIP_CARD_TYPES, PRODUCT_TYPES } from '@common/constants'

import styling from './settingsNavigation.module.scss'

const mainSettingsRoutes = [staticRoutes.settingsNotifications.pathname]

const accountSummaryQuery = gql`
  query AccountSummary {
    accountSummary {
      accountType
    }
  }
`

const SettingsNavigation = ({ onNavItemClick, isOpen = false, closeNavMenu }) => {
  const navigate = useNavigate()
  const { pathname: currentPathname } = useLocation()

  const customer = useSelector(state => state.customer)
  const hasPrimaryAccount = useSelector(state => state.customer?.hasPrimaryAccount)

  const [getAccountSummary, { data: accountSummaryData }] = useLazyQuery(accountSummaryQuery, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (hasPrimaryAccount) {
      getAccountSummary()
    }
  }, [hasPrimaryAccount, getAccountSummary])

  const accountType = useMemo(() => (
    accountSummaryData?.accountSummary?.accountType
  ), [accountSummaryData?.accountSummary?.accountType])

  const membershipType = useMemo(() => (
    hasPrimaryAccount
      ? MEMBERSHIP_CARD_TYPES[accountType]
      : MEMBERSHIP_CARD_TYPES[PRODUCT_TYPES.elevateCard]
  ), [hasPrimaryAccount, accountType])

  const navItems = useMemo(() => {
    const links = [
      {
        title: 'Personal Information',
        subtext: 'Update your account info',
        pathname: staticRoutes.settingsAccount.pathname,
        color: 'primary',
        icon: <ProfileIcon />,
      }, {
        title: 'Settings',
        subtext: 'Privacy & Security and Notifications',
        pathname: staticRoutes.settingsPrivacySecurity.pathname,
        color: 'moderate-blue',
        icon: <SettingsIcon />,
      }, {
        title: 'Terms & Policies',
        subtext: 'Review your legal documents',
        pathname: staticRoutes.settingsAgreementsDisclosures.pathname,
        color: 'lime-green',
        icon: <TermsPoliciesIcon />,
      }, {
        title: 'Help & Support',
        subtext: 'Contact us, FAQ’s',
        pathname: staticRoutes.settingsHelp.pathname,
        color: 'light-blue',
        icon: <HelpSupportIcon />,
      },
    ]

    if (hasPrimaryAccount) {
      links.splice(2, 0, {
        title: 'Linked Accounts/Cards',
        subtext: 'Manage external funding options',
        pathname: staticRoutes.settingsLinkedAccounts.pathname,
        color: 'green',
        icon: <LinkedAccountsIcon />,
      }, {
        title: 'My Card',
        subtext: 'Change PIN, lock card or report card',
        pathname: staticRoutes.settingsMyCard.pathname,
        color: 'gold',
        icon: <CardIcon />,
      }, {
        title: 'Statements',
        subtext: 'Statements, Tax and other documents',
        pathname: staticRoutes.settingsDocuments.pathname,
        color: 'burnt-orange',
        icon: <StatementsIcon />,
      })
    }

    return links
  }, [hasPrimaryAccount])

  const getNavCard = ({ title, subtext, icon, pathname, color }) => {
    const isActive = currentPathname.includes(pathname) ||
      (mainSettingsRoutes.includes(currentPathname) && pathname === staticRoutes.settingsPrivacySecurity.pathname)

    return (
      <div
        className={classNames(
          styling['nav-card'],
          { [styling['is-active']]: isActive }
        )}
        role='button'
        key={title}
        onClick={() => {
          if (onNavItemClick) {
            onNavItemClick()
          }

          navigate(pathname)
        }}
      >
        <div className={classNames(styling['icon-container'], styling[color])}>
          {icon}
        </div>
        <div className={styling['text-container']}>
          {title}
          <p>{subtext}</p>
        </div>
        <Chevron className={styling.chevron} />
      </div>
    )
  }

  return (
    <div className={classNames(styling['settings-nav-container'], isOpen && styling['is-open'])}>
      <div className={styling['member-icon-container']}>
        <ProfileIcon />
      </div>
      {isOpen && (
        <div className={styling['close-btn-container']}>
          <Chevron className={styling.chevron} onClick={() => closeNavMenu() }/>
        </div>
      )}
      <div className={styling['name-container']}>
        <h3>{customer?.firstName} {customer?.lastName}</h3>
        <p>{membershipType} Member</p>
      </div>
      <div className={styling['nav-items']}>
      {hasPrimaryAccount && getNavCard({
        title: membershipType ? `${membershipType} Plan` : '',
        color: 'magenta',
        icon: <MembershipIcon />,
        pathname: staticRoutes.settingsMembership.pathname,
      })}
      <div className={styling['connected-container']}>
        {navItems.map(item => getNavCard(item))}
      </div>
      <div
        className={classNames(styling['nav-card'], styling['sign-out-btn'])}
        role='button'
        onClick={() => navigate(staticRoutes.signOut.pathname)}
      >
        Sign Out
      </div>
      </div>
    </div>
  )
}

SettingsNavigation.propTypes = {
  onNavItemClick: PropTypes.func,
  isOpen: PropTypes.bool,
  closeNavMenu: PropTypes.func,
}

export default SettingsNavigation
