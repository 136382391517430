import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import EditForm from './components/editForm/EditForm'
import AgreementsForm from './components/agreementsForm/AgreementsForm'

import { useCheckpointNavigation } from '@common/utils'
import { gql, useQuery } from '@services/serviceUtils'
import { CreateAccountContext } from '../CreateAccount'
import { AddressResubmissionContext } from 'src/views/addressResubmission/AddressResubmission'

import styling from './createAccountReviewConfirm.module.scss'

const productsQuery = gql`
  query ProductAgreements($legacy: Boolean) {
    products(legacy: $legacy) {
      productType
      agreements {
        id
        title
        url
      }
    }
  }
`

const CreateAccountReviewConfirm = ({ isResubmitting = false }) => {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  const [isUpdated, setIsUpdated] = useState(false)
  const [isEditing, setIsEditing] = useState(true)

  const addressResubmissionContext = useContext(AddressResubmissionContext)
  const createContext = useContext(CreateAccountContext)

  const {
    data: applicationData,
    submitApplication,
    submitting,
    updateApplication,
    updating,
  } = useMemo(() => (
    isResubmitting ? addressResubmissionContext : createContext
  ), [isResubmitting, addressResubmissionContext, createContext])

  const setParagraphText = createContext?.setParagraphText || null

  const userIsResubmitting = useMemo(() => isResubmitting || state?.isResubmitting, [
    isResubmitting,
    state?.isResubmitting,
  ])

  useEffect(() => {
    if (!isEditing && !userIsResubmitting) {
      setParagraphText("By confirming, you're joining a community focused on financial empowerment and shared success.")
    }
  }, [isEditing, userIsResubmitting, setParagraphText])

  useEffect(() => {
    if (userIsResubmitting && !state?.isResubmitting) {
      navigate(pathname, {
        state: {
          ...state,
          isResubmitting: true,
          shouldSetupDirectDeposit: true,
        },
        replace: true,
      })
    }
  }, [userIsResubmitting, navigate, pathname, state])

  useCheckpointNavigation({
    checkpoints: applicationData?.checkpoints,
    applicationStatus: applicationData?.application?.customerApplicationStatus,
    isResubmitting: userIsResubmitting,
  })

  const { loading, error, data: productsData } = useQuery(productsQuery, { variables: { legacy: false } })

  useEffect(() => {
    if (isUpdated) {
      submitApplication({})
    }
  }, [isUpdated, submitApplication])

  return (
    <LoadingContainer error={error} errorMessage='Error loading agreements.' loading={loading}>
      <div className={classNames('create-account-content-wrapper', styling['review-confirm-container'])}>
        {isEditing ? (
          <EditForm
            handleClick={() => setIsEditing(false)}
            updating={updating}
            applicationData={applicationData}
            updateApplication={updateApplication}
          />
        ) : (
          <AgreementsForm
            updating={updating}
            submitting={submitting}
            products={productsData?.products}
            handleClick={() => setIsUpdated(true)}
            updateApplication={updateApplication}
          />
        )}
      </div>
    </LoadingContainer>
  )
}

CreateAccountReviewConfirm.propTypes = {
  isResubmitting: PropTypes.bool,
}

export default CreateAccountReviewConfirm
