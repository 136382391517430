import React, { useContext, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import AppLinks from '@common/components/callout/AppLinks'
import ReportCard from './ReportCard'
import Tabs from '@shared/components/tabs/Tabs'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Collapse from '@shared/components/Collapse'
import MyCardImage from './MyCardImage'
import LockCard from './LockCard'
import CardActivationCallout from 'src/views/myCard/CardActivationCallout'
import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ExternalLink from '@common/components/link/ExternalLink'

import { ReactComponent as LockedIcon } from '@shared/images/icons/locked.svg'
import { ReactComponent as InfoIcon } from '@shared/images/icons/info.svg'

import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import { staticRoutes } from '@routing/routes'
import useTabRouting from '@common/utils/useTabRouting'
import { CARD_STATES, CARD_AVAILABLE_OPERATIONS } from '@common/constants'
import { gql, useLazyQuery, useMutation } from '@services/serviceUtils'
import { removeAlertsAction } from '@redux/alerts/alertsActions'
import { getBootstrapValueByKey, BOOTSTRAP_KEYS } from '@common/utils'

import styling from './myCard.module.scss'

const fetchCardQuery = gql`
  query Card {
    card {
      last4
      message
      cardState
      availableOperations
      reportingOptions {
        caption
        cardStatus
      }
    }
  }
`

const updateCardStatusMutation = gql`
  mutation UpdateCardStatus($updatedCardStatus: UpdateCardStatusDTOInput!) {
    updateCardStatus(updateCardStatusDTOInput: $updatedCardStatus) {
      cardState
    }
  }
`

const MyCard = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { setPageDetails } = useContext(BankingContainerContext)

  useEffect(() => {
    setPageDetails({ title: 'My Card' })

    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails])

  const contactOptions = getBootstrapValueByKey(BOOTSTRAP_KEYS.SUPPORT)?.contact
  const phoneContact = contactOptions?.find(option => option.method === 'phone')
  const emailContact = contactOptions?.find(option => option.method === 'email')

  // Tracks whether or not the card activation callout should be displayed
  const [showActivateCallout, setShowActivateCallout] = useState(false)

  // We are using this boolean because using refetch queires appears to create
  // issues with loading state so we manually fetch the card
  const [initialLoad, setInitialLoad] = useState(true)

  const [showReplacementEligibilityModal, toggleShowReplacementIneligibilityModal] = useState(false)
  const [messageDetails, setMessageDetails] = useState('')

  const [fetchCard, { data, error, loading }] = useLazyQuery(fetchCardQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      setInitialLoad(false)
    },
  })

  const [updateCardStatus, { loading: updatingCardStatus }] = useMutation(updateCardStatusMutation, {
    onError: e => {
      const responseBody = e?.graphQLErrors[0]?.extensions?.responseBody

      if (responseBody?.message?.toLowerCase()?.includes('currently ineligible')) {
        dispatch(removeAlertsAction())
        setMessageDetails({ message: responseBody?.message, requestId: responseBody?.requestId })
        toggleShowReplacementIneligibilityModal(true)
      }
    },
  })

  const hasPreActivatedPhysicalCard = [
    CARD_STATES.PHYSICAL_CARD_ACTIVATED,
    CARD_STATES.PHYSICAL_CARD_EMBOSSED,
  ].includes(data?.card?.cardState)

  const hasCard = !data?.card?.cardState?.includes(CARD_STATES.NO_CARD)
  const canSetPin = data?.card?.availableOperations?.includes(CARD_AVAILABLE_OPERATIONS.SET_PIN)
  const cardIsLocked = data?.card?.availableOperations?.includes(CARD_AVAILABLE_OPERATIONS.UNBLOCK)
  const canActivate = data?.card?.availableOperations?.includes(
    CARD_AVAILABLE_OPERATIONS.ACTIVATE_CARD_TOGGLE
  )

  useEffect(() => {
    // Shows the activation callout depending on the flag
    if (!loading) {
      setShowActivateCallout(canActivate)
    }
  }, [loading, canActivate])

  const tabOptions = useMemo(
    () => [
      {
        label: 'Report Card',
        enabled: true,
        pathname: staticRoutes.settingsMyCardReportCard.pathname,
        dataCy: 'my-card-report-card-tab',
      },
      {
        label: cardIsLocked ? 'Unlock Card' : 'Lock Card',
        enabled: true,
        pathname: staticRoutes.settingsMyCardLockCard.pathname,
        dataCy: 'my-card-lock-unlock-card-tab',
      },
    ],
    [cardIsLocked]
  )

  const { activeTab, redirectPath } = useTabRouting({
    defaultActiveTab: staticRoutes.settingsMyCardReportCard,
    loading,
    location,
    tabOptions,
    tabsDisabledRoute: staticRoutes.settingsMyCard.pathname,
    tabsEnabled: true,
  })

  const handleUpdateCardStatus = async (updateData, onHandleUpdateSuccess) => {
    const { data } = await updateCardStatus(updateData)

    if (data) {
      if (onHandleUpdateSuccess) {
        onHandleUpdateSuccess()
      }
      fetchCard()
    }
  }

  useEffect(() => {
    fetchCard()
  }, [fetchCard])

  useEffect(() => {
    if (redirectPath) {
      navigate(redirectPath)
    }
  }, [navigate, redirectPath])

  const handleTabChange = tab => {
    if (tab) {
      navigate(tab.pathname)
    }
  }

  return (
    <>
      <Collapse open={showActivateCallout}>
        <CardActivationCallout
          onCardActivated={() => {
            // Navigate to success screen
            navigate(staticRoutes.cardActivated.pathname)
          }}
        />
      </Collapse>
      <div className='page-content-container'>
        <div className={classNames('row', styling['my-card-container'])}>
          <div className={styling['card-col']}>
            <MyCardImage key={hasCard} />

            {cardIsLocked && (
              <p className={styling['status-indicator']}>
                <LockedIcon />
                Your card is locked.
              </p>
            )}

            {data?.card?.message && (
              <p className={styling['status-indicator']}>
                <InfoIcon />
                {data?.card?.message}
              </p>
            )}

            {canSetPin && <p>Set up PIN for your new card in the Greenwood Mobile App</p>}
            {!loading && canActivate && <AppLinks />}

            {hasPreActivatedPhysicalCard && (
              <p className={styling['contact-us']}>
                Please{' '}
                <Link className='underlined-link bold' to={staticRoutes.settingsHelp.pathname}>
                  Contact Us
                </Link>{' '}
                If you haven’t received your card.
              </p>
            )}
          </div>

          <div className={styling['content-container']}>
            <LoadingContainer loading={initialLoad}>
              <>
                <Tabs
                  defaultActiveTab={activeTab}
                  options={tabOptions}
                  onChangeCallback={handleTabChange}
                  className={styling['tabs-container']}
                />

                {activeTab?.label === tabOptions[0].label ? (
                  <ReportCard
                    last4={data?.card?.last4}
                    updateCardStatus={handleUpdateCardStatus}
                    reportingOptions={data?.card?.reportingOptions}
                    canActivateCard={showActivateCallout}
                  />
                ) : (
                  <LockCard
                    last4={data?.card?.last4}
                    cardIsLocked={cardIsLocked}
                    loading={initialLoad}
                    error={error}
                    updateCardStatus={handleUpdateCardStatus}
                    updatingCardStatus={updatingCardStatus || loading}
                  />
                )}
              </>
            </LoadingContainer>
          </div>
        </div>
      </div>
      <Modal
        centered
        size='md'
        open={showReplacementEligibilityModal}
        toggleModal={() => toggleShowReplacementIneligibilityModal(!showReplacementEligibilityModal)}
        className={styling['ineligibility-modal']}
      >
        <ModalHeader withCloseButton closeModal={() => toggleShowReplacementIneligibilityModal(false)} />
        <ModalBody>
          <p>{messageDetails?.message}</p>
          <p>
            Call{' '}
            <a className='bold' href={`tel:${phoneContact.value}`}>
              {phoneContact.value}
            </a>{' '}
            or email{' '}
            <ExternalLink className='underlined-link bold' to={`mailto:${emailContact?.value}`}>
              {emailContact?.value}
            </ExternalLink>
            .
          </p>
          <p>{`(ID: ${messageDetails?.requestId})`}</p>
        </ModalBody>
      </Modal>
    </>
  )
}

export default MyCard
