import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ReactComponent as CheckmarkIcon } from '@shared/images/icons/checkmark.svg'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as XIcon } from '@shared/images/icons/x.svg'

import Button from '@shared/components/button/Button'
import FormPasswordInput from '@shared/components/FormPasswordInput'
import AccountIsLockedModal from '@common/components/accountIsLockedModal/AccountIsLockedModal'

import { purgeStore } from '@redux/store'
import { setEmailAction } from '@redux/unauthenticatedUser/unauthenticatedUserActions'
import { setAuthAction } from '@redux/auth/authActions'
import { staticRoutes } from '@routing/routes'
import { addAlertAction, removeAlertsAction } from '@redux/alerts/alertsActions'
import { register, verify, decodeJwtPayload } from '@services/serviceUtils'
import usePreventBrowserBackClick from '@common/utils/usePreventBrowserBackClick'
import { fullValidatorForSchema } from '@common/utils/formUtils'
import {
  getRedirectLocationByChallengeTypeAndUserFlow,
  SEGMENT_EVENTS,
  SEGMENT_IDENTIFIER_TRAITS,
  SEGMENT_PAGE_NAMES,
  SEGMENT_SOURCE_DETAILS,
  trackEvent,
  trackIdentity,
  trackPage,
} from '@common/utils'
import {
  ONBOARDING_FLOW_TYPES,
  PASSWORD_VALIDATION_REGEX,
  ACCOUNT_OPENING_DISCLAIMER_TEXT,
  FRAUD_ERROR_MESSAGE,
} from '@common/constants'
import {
  ROUTE_USER_FLOW,
  CHALLENGE_TYPES,
  ALERT_TYPES,
  DEFAULT_ALERT_DISMISS_DELAY,
} from '@shared/constants/uiConstants'

// Extend Yup with password validation
require('yup-password')(Yup)

const newPasswordMinLength = 10
const newPasswordMaxLength = 64
const characterLimitMessage = `Password must be between ${newPasswordMinLength}-${newPasswordMaxLength} characters`
const numberRequiredMessage = 'At least one numeric'
const uppercaseCharRequiredMessage = 'At least one uppercase letter'
const specialCharRequiredMessage = 'At least one special character (@$!%*?&#)'

const passwordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(newPasswordMinLength, characterLimitMessage)
    .max(newPasswordMaxLength, characterLimitMessage)
    .test('has-number', numberRequiredMessage, value => {
      return value.match(PASSWORD_VALIDATION_REGEX.NUMBER)
    })
    .test('has-uppercase', uppercaseCharRequiredMessage, value => {
      return value.match(PASSWORD_VALIDATION_REGEX.UPPERCASE)
    })
    .test('has-special-char', specialCharRequiredMessage, value => {
      return value.match(PASSWORD_VALIDATION_REGEX.SPECIAL_CHAR)
    })
    .required('Required'),
})

const PasswordHintContent = ({ error: errors, touched }) => {
  const requirements = [
    characterLimitMessage,
    numberRequiredMessage,
    uppercaseCharRequiredMessage,
    specialCharRequiredMessage,
  ]

  return (
    <ul className='password-requirements-list'>
      {requirements.map((requirement, index) => {
        const error = errors?.includes(requirement) && touched
        const success = !errors?.includes(requirement) && touched
        return (
          <li
            key={index}
            className={classNames({
              error,
              success,
            })}
          >
            <span>
              {error && <XIcon />}
              {success && <CheckmarkIcon />}
            </span>
            {requirement}
          </li>
        )
      })}
    </ul>
  )
}

const ProvidePassword = () => {
  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)
  const [registrationError, setRegistrationError] = useState()
  const [hasFraudError, setHasFraudError] = useState(false)
  const [fraudError, setFraudError] = useState(null)
  const [isInvalid, setIsInvalid] = useState(false)

  const navigate = useNavigate()
  const { state } = useLocation()

  const dispatch = useDispatch()

  const email = useSelector(state => state.unauthenticatedUser.email)
  const waitlistCode = useSelector(state => state.unauthenticatedUser.waitlistCode)
  const onboardingType = useSelector(state => state.onboardingType)
  const sessionId = useSelector(state => state.auth?.sessionId)

  const { webBrazeSmsOptIn } = useFlags()

  const { userFlow, displayEmail, ...additionalState } = state || {}

  usePreventBrowserBackClick(true)

  const isForgotPassword = userFlow === ROUTE_USER_FLOW.FORGOT_PASSWORD
  const isResetPassword = userFlow === ROUTE_USER_FLOW.RESET_PASSWORD
  const errorMessage = `There was an error trying to ${
    isForgotPassword ? 'update your password' : 'register'
  }. Please try again.`

  // Tracks page visits during password reset and registration flows
  useEffect(() => {
    if (isResetPassword) {
      trackPage({ name: SEGMENT_PAGE_NAMES.RESET_PASSWORD })
    } else if (!isForgotPassword) {
      trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_PASSWORD })
    }
  }, [isForgotPassword, isResetPassword])

  useEffect(() => {
    if (!email) {
      navigate(staticRoutes.signIn.pathname, { replace: true })
    }
    // note: only check this on mount, because email might disappear on submission, and we don't want to redirect in that case, so:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetOrForgottenPassword = async (password) => {
    const { data } = await verify({
      sessionId,
      value: password,
      onError: error => {
        const { message = '' } = error?.data

        if (message?.toLowerCase().includes('password is invalid')) {
          dispatch(removeAlertsAction())

          setRegistrationError(`${message}.`)
          setIsInvalid(true)
        } else {
          setIsInvalid(false)
        }
      },
    })

    if (data.challengeType === CHALLENGE_TYPES.NONE) {
      dispatch(setEmailAction(null))

      dispatch(
        addAlertAction({
          dismissible: false,
          autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
          type: ALERT_TYPES.SUCCESS,
          text: 'Success! Your password has been changed',
        })
      )

      if (isResetPassword) {
        purgeStore({
          callback: () => {
            window.location.href = staticRoutes.signIn.pathname
          },
          timeout: 1000,
        })
      } else {
        const { pathname: navigatePath, state: navigateState } = getRedirectLocationByChallengeTypeAndUserFlow({
          challengeType: data.challengeType,
          userFlow,
          displayEmail,
          sessionId,
        })

        navigate(navigatePath, { state: navigateState, replace: true })
      }
    }
  }

  const handleSignUp = async (password, actions) => {
    // Remove any current alerts
    dispatch(removeAlertsAction())
    try {
      let userFlow = ROUTE_USER_FLOW.SIGNUP

      if (window.sessionStorage.getItem('mfaRememberMeId') == null) {
        window.sessionStorage.setItem('mfaRememberMeId', uuid())
      }

      const rememberMeToken = localStorage.getItem(`rememberMeToken+${email}`)

      const registerParams = {
        username: email,
        password,
        waitlistCode,
        onboardingFlowType: onboardingType || ONBOARDING_FLOW_TYPES.ELEVATE,
        onError: error => {
          const { message = '' } = error?.data
          const isFraudError = message?.toLowerCase().includes(FRAUD_ERROR_MESSAGE)
          const isInvalidError = message?.toLowerCase().includes('password is invalid')

          if (isFraudError || isInvalidError) {
            dispatch(removeAlertsAction())
          }

          if (isFraudError) {
            setHasFraudError(true)
            setFraudError(error?.data)
          } else {
            setHasFraudError(false)
            setFraudError(null)
            setRegistrationError(errorMessage)
          }

          if (isInvalidError) {
            setRegistrationError(`${message}.`)
            setIsInvalid(true)
          } else {
            setIsInvalid(false)
          }
        },
      }

      if (rememberMeToken) {
        registerParams.axiosConfig = {
          headers: { 'remember-me-token': rememberMeToken },
        }
      }

      const { data } = await register(registerParams)

      const isSignInFlow =
        [CHALLENGE_TYPES.VERIFY_PHONE_NUMBER, CHALLENGE_TYPES.VERIFY_MFA].includes(
          data?.challengeType
        ) || data?.token?.access_token

      if (isSignInFlow) {
        userFlow = ROUTE_USER_FLOW.SIGNIN
      }

      /* Check if the response includes an access_token. If so, that means the user already
       has an account, so this is considered a signin flow instead. Otherwise continue
       user account creation as normal */
      if (data?.token?.access_token) {
        dispatch(setAuthAction(data))

        // Get the userId out of the JWT payload to identify the user for analytics
        const userId = decodeJwtPayload(data?.token?.access_token)?.sub

        trackIdentity({
          userId,
          traits: SEGMENT_IDENTIFIER_TRAITS.login({ email }),
        })
      }

      dispatch(setEmailAction(null))

      const { pathname: navigatePath, state: navigateState } = getRedirectLocationByChallengeTypeAndUserFlow({
        challengeType: data.challengeType,
        userFlow,
        sessionId: data?.sessionId,
        waitlistCode,
        displayEmail,
        mfaChannel: data?.mfaChannel,
        ...additionalState,
      })

      navigate(navigatePath, { state: navigateState, replace: true })
    } catch (error) {
      if (error.data?.message?.includes('Waitlist Code')) {
        navigate(staticRoutes.signUpEmail.pathname, { replace: true })
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  const handleSubmit = async (values, actions) => {
    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */
    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }

    setRegistrationError()

    if (isForgotPassword || isResetPassword) {
      return await handleResetOrForgottenPassword(values.password)
    } else {
      trackEvent({
        event: SEGMENT_EVENTS.NEW_PASSWORD_BUTTON_CLICK,
      })

      const signUpResponse = await handleSignUp(values.password, actions)

      return signUpResponse
    }
  }

  const handlePasswordFocus = () => {
    if (!isForgotPassword && !isResetPassword) {
      setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.PASSWORD)
    }
  }

  const handlePasswordBlur = () => {
    // Track any time the user leaves the password field during registration
    if (!isForgotPassword && !isResetPassword) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: SEGMENT_SOURCE_DETAILS.PASSWORD,
        }),
      })

      setFocusedInputSourceDetail(null)
    }
  }

  const showDisclaimerText = useMemo(
    () => webBrazeSmsOptIn && !isForgotPassword && !isResetPassword,
    [webBrazeSmsOptIn, isForgotPassword, isResetPassword]
  )

  const headingText = useMemo(() => (
    isForgotPassword ? 'Update your password' : 'Create Your Login Password'
  ), [isForgotPassword])

  const getPasswordForm = () => (
    <div className='create-account-content-wrapper'>
      <Formik
        validate={fullValidatorForSchema(passwordValidationSchema)}
        initialValues={{
          password: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ errors, isSubmitting, touched, handleBlur, handleChange }) => (
          <Form data-cy='password-form'>
            <Field
              as={FormPasswordInput}
              name='password'
              label={isForgotPassword || isResetPassword ? 'Password' : ''}
              autoComplete='new-password'
              placeholder={isForgotPassword || isResetPassword ? '' : 'Password'}
              hintContent={
                <PasswordHintContent error={errors.password} touched={touched.password} />
              }
              invalid={(errors.password && touched.password) || isInvalid}
              disabled={isSubmitting}
              autoFocus
              onFocus={handlePasswordFocus}
              isNewPassword
              newPasswordMinLength={newPasswordMinLength}
              onBlur={e => {
                handleBlur(e)
                handlePasswordBlur()
              }}
              onChange={e => {
                handleChange(e)
                dispatch(removeAlertsAction())
                setRegistrationError('')
                setIsInvalid(false)
              }}
            />
            <div className='button-container'>
              {showDisclaimerText && <p className='disclaimer-text'>{ACCOUNT_OPENING_DISCLAIMER_TEXT}</p>}
              <Button type='submit' isLoading={isSubmitting}>
                NEXT
              </Button>
              {isResetPassword && (
                <Button
                  className='additional-button'
                  type='button'
                  outline
                  onClick={() => {
                    dispatch(setEmailAction(null))
                    navigate(staticRoutes.settingsPrivacySecurity.pathname, { replace: true })
                  }}
                >
                  Cancel
                </Button>
              )}
              {registrationError && <p className='error'>{registrationError}</p>}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )

  return (
    <>
      {isForgotPassword || isResetPassword ? (
        <div className='white-card-container'>
          <GreenwoodLogo className='logo' />
          <h1>{headingText}</h1>
          <p>Strong passwords keep your account safer.</p>
          {getPasswordForm()}
        </div>
      ) : (
        <>{getPasswordForm()}</>
      )}
      <AccountIsLockedModal
        isOpen={hasFraudError}
        isFraud
        closeModal={() => {
          setHasFraudError(false)
        }}
        errorDetails={fraudError}
      />
    </>
  )
}

export default ProvidePassword
