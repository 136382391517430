import React, { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'
import UpgradeMembershipCallout from '@common/components/callout/UpgradeMembershipCallout'

import { staticRoutes } from '@routing/routes'
import { gql, useQuery, useLazyQuery } from '@services/serviceUtils'
import { MEMBERSHIP_CARD_TYPES, PRODUCT_TYPES } from '@common/constants'
import {
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  trackPage,
  trackEvent,
  hasUpgradeDdaAccountCta,
} from '@common/utils'
import { removeAlertsAction } from '@redux/alerts/alertsActions'
import { setCustomerAction } from '@redux/customer/customerActions'

const accountSummaryQuery = gql`
  query AccountSummary {
    accountSummary {
      accountType
    }
  }
`

const ctasQuery = gql`
  query Ctas {
    ctas {
      callToActionType
    }
  }
`

const subscriptionsQuery = gql`
  query Subscriptions {
    subscriptions {
      id
    }
  }
`

const MembershipView = () => {
  const navigate = useNavigate()
  const { upgradeMembership } = useFlags()
  const dispatch = useDispatch()
  const hasPrimaryAccount = useSelector(state => state.customer?.hasPrimaryAccount)
  const hasSubscription = useSelector(state => state.customer?.hasSubscription)
  const hasElevate = useSelector(state => state.customer?.hasElevate)
  const hasPremium = useSelector(state => state.customer?.hasPremium)

  const isStandardAccount = useMemo(() => (
    !hasElevate && !hasPremium
  ), [hasElevate, hasPremium])

  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.MY_MEMBERSHIP })
  }, [])

  const [
    getAccountSummary,
    { data: accountSummaryData, loading: accountSummaryLoading },
  ] = useLazyQuery(accountSummaryQuery, {
    fetchPolicy: 'no-cache',
  })
  const { data: subscriptionsData } = useQuery(subscriptionsQuery, {
    fetchPolicy: 'no-cache',
    onError: () => dispatch(removeAlertsAction()),
  })

  const accountType = accountSummaryData?.accountSummary?.accountType
  const membershipType = hasPrimaryAccount
    ? MEMBERSHIP_CARD_TYPES[accountType]
    : MEMBERSHIP_CARD_TYPES[PRODUCT_TYPES.elevateCard]

  useEffect(() => {
    if (subscriptionsData?.subscriptions?.length) {
      dispatch(setCustomerAction({ hasSubscription: true }))
    } else {
      dispatch(setCustomerAction({ hasSubscription: false }))
    }
  }, [subscriptionsData, dispatch])

  useEffect(() => {
    if (hasPrimaryAccount) {
      getAccountSummary()
    }
  }, [hasPrimaryAccount, getAccountSummary])

  const { data: ctasData, loading: ctasLoading } = useQuery(ctasQuery, { fetchPolicy: 'no-cache' })
  const hasUpgradeCta = useMemo(() => hasUpgradeDdaAccountCta(ctasData?.ctas), [ctasData])

  const membershipOptionRows = useMemo(() => {
    const options = []

    if (hasPrimaryAccount) {
      options.push({
        label: 'About My Membership',
        dataCy: 'settings-membership-about-my-membership-row',
        onClick: () => {
          trackEvent({ event: SEGMENT_EVENTS.MY_MEMBERSHIP_ABOUT_MY_MEMBERSHIP_BUTTON_CLICK })
          navigate(staticRoutes.settingsMembershipAboutMyMembership.pathname)
        },
      })
    }

    options.push({
      label: 'Change My Membership',
      dataCy: 'settings-membership-change-my-membership-row',
      onClick: () => {
        trackEvent({ event: SEGMENT_EVENTS.MY_MEMBERSHIP_CHANGE_MY_MEMBERSHIP_BUTTON_CLICK })
        navigate(staticRoutes.settingsMembershipChangeMyMembership.pathname)
      },
    })

    if (hasSubscription && !accountSummaryLoading) {
      options.push({
        label: 'Payment Method',
        dataCy: 'settings-membership-payment-method-row',
        onClick: () => {
          trackEvent({ event: SEGMENT_EVENTS.MY_MEMBERSHIP_PAYMENT_METHOD_BUTTON_CLICK })
          navigate(staticRoutes.settingsMembershipPaymentMethod.pathname)
        },
      })
    }

    return options
  }, [
    navigate,
    hasPrimaryAccount,
    accountSummaryLoading,
    hasSubscription,
  ])

  const showUpgradeCta = hasUpgradeCta && !ctasLoading && upgradeMembership && isStandardAccount

  return (
    <div className={classNames('membership-view-content', 'membership-view-container')}>
      <h2 data-cy='membership-settings-header'>My Membership: {membershipType}</h2>
      <table className={classNames('simple-table', 'settings-link-table')}>
        <tbody>
          {membershipOptionRows.map((rowItem, index) => (
            <tr className='link-row' key={index} data-cy={rowItem.dataCy} onClick={rowItem.onClick}>
              <th>{rowItem.label}</th>
              <td className='row-chevron'>
                <Chevron />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showUpgradeCta && (
        <UpgradeMembershipCallout segmentSource={SEGMENT_PAGE_NAMES.MY_MEMBERSHIP} />
      )}
    </div>
  )
}

export default MembershipView
